/* eslint-disable react/jsx-no-constructed-context-values */

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// The Soft UI Dashboard PRO Material main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Otis Admin PRO React reducer
function reducer(state, action) {
  switch (action.type) {
    case "SET_PERSONA": {
      return { ...state, persona: action.value };
    }
    case "SET_BETA": {
      return { ...state, beta: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "SET_PROFILE":{
      return {...state,profile:action.value};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Otis Admin PRO React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    persona: "defaultPersona",
    beta: false,
    layout: "dashboard",
    profile: {details: {name:"", age:5},email:"", phone: null, relationships:{children:[]}, status:"active", profile_image_url:null}
  };

  const [controller, dispatch] = useReducer(reducer, initialState);
  const contextValue = [controller, dispatch];

  return <MaterialUI.Provider value={contextValue}>{children}</MaterialUI.Provider>;
}

// Otis Admin PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setPersona = (dispatch, value) => dispatch({ type: "SET_PERSONA", value });
const setBeta =  (dispatch, value) => dispatch({ type: "SET_BETA", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setProfile= (dispatch, value) => dispatch ({type:"SET_PROFILE", value});

export { MaterialUIControllerProvider, useMaterialUIController, setPersona, setBeta, setLayout, setProfile };
