import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import logo from "assets/images/nav-logo.png";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";

const Loader = ({ loaderText }) => {
  const {t} = useTranslation()
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      pt={3}
      pb={2}
      px={2}
    >
      <MDBox mb={2} style={{ position: "relative" }}>
        <CircularProgress color="info" thickness={1} size={250} />
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <MDBox component="img" src={logo} alt={t("imageAlts.maxuLogo")} width="100%" />
        </div>
      </MDBox>

      <MDBox mb={2} style={{ position: "relative" }}>
        <MDTypography textAlign="center" variant="h1" fontSize={25} mb={1}>
          {loaderText}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

export default Loader;
