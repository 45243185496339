import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import MDInput from "components/MDInput";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { call } from "services/axiosService/core";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";

const DeleteFilterButton = ({ id, DeleteFilterHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Delete Filter</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="error"
          onClick={() => {
            DeleteFilterHandler(id);
          }}
        >
          delete
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

export function FiltersTable(props) {
  const [selectedId, setSelectedId] = useState("");
  const [selectedFilterDetails, setSelectedFilterDetails] = useState("");
  const [openViewDialog, setOpenViewDialog] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteFilterId, setDeleteFilterId] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [sessionExpired, setSessionExpired] = useState(false);
  const CloseViewDialogHandler = () => {
    setOpenViewDialog(false);
    setSelectedId("");
  };

  const DeleteFilterHandler = (id) => {
    setDeleteFilterId(id);
    setOpenDeleteDialog(true);
  };

  const CloseDeleteDialogHandler = () => {
    setOpenDeleteDialog(false);
    setSelectedId("");
  };

  useEffect(() => {
    if (!openDeleteDialog) {
      if (selectedId) {
        const filter = props.filters.filter((row) => {
          return row.id == selectedId;
        });
        setSelectedFilterDetails(filter[0]);
        setOpenViewDialog(true);
      }
    }
  }, [selectedId]);

  const tableData = {
    columns: [
      { Header: "#", accessor: "num", width: "5%" },
      { Header: "PrimaryKey", accessor: "pk", width: "10%" },
      { Header: "SortKey", accessor: "sk", width: "30%" },
      { Header: "Name", accessor: "name", width: "10%" },
      { Header: "TeamSize", accessor: "size", width: "10%" },
      { Header: "Actions", accessor: "actions", width: "10%" },
    ],

    rows: props.filters,
  };

  tableData.rows = tableData.rows.map((row, index) => ({
    ...row,
    num: index + 1,
    size: row.members.length,
    actions: (
      <div style={{ display: "flex" }}>
        <DeleteFilterButton id={row.id} DeleteFilterHandler={DeleteFilterHandler} />
      </div>
    ),
  }));
  return (
    <>
      <DataTable
        table={tableData}
        setSelectedId={setSelectedId}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
      {openViewDialog && (
        <ViewUserDataDialog
          openViewDialog={openViewDialog}
          CloseViewDialogHandler={CloseViewDialogHandler}
          selectedFilterDetails={selectedFilterDetails}
          loadFilters={props.loadFilters}
          setSessionExpired={setSessionExpired}
        />
      )}
      {openDeleteDialog && (
        <DeleteFilterDialog
          openDeleteDialog={openDeleteDialog}
          CloseDeleteDialogHandler={CloseDeleteDialogHandler}
          deleteFilterId={deleteFilterId}
          loadFilters={props.loadFilters}
          setSessionExpired={setSessionExpired}
        />
      )}
      {sessionExpired && <Refresh />}
    </>
  );
}

const ViewUserDataDialog = ({
  openViewDialog,
  CloseViewDialogHandler,
  selectedFilterDetails,
  loadFilters,
  setSessionExpired,
}) => {
  const [details, setDetails] = useState(JSON.stringify(selectedFilterDetails, null, 3));
  const [validJson, setValidJson] = useState(true);

  const updateFilterHandler = () => {
    const data = JSON.parse(details);
    call(`filters/${data.id}`, "PUT", { body: data })
      .then((res) => {
        if (res.status == 200) {
          CloseViewDialogHandler();
          loadFilters();
        } else if (res.status == 440) {
          CloseViewDialogHandler();
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        CloseViewDialogHandler();
        Sentry.captureException(err);
      });
  };

  return (
    <Dialog open={openViewDialog} onClose={CloseViewDialogHandler} fullWidth={true} maxWidth={"md"}>
      <DialogTitle> Filter Details </DialogTitle>
      <DialogContent>
        <MDBox p={1} lineHeight={1}>
          <MDInput
            value={details}
            error={!validJson}
            multiline
            name="details"
            type="text"
            fullWidth
            required
            onChange={(e) => {
              setDetails(e.target.value);
              try {
                JSON.parse(e.target.value);
                setValidJson(true);
              } catch (error) {
                setValidJson(false);
              }
            }}
          />
        </MDBox>
        {!validJson && (
          <MDTypography
            textAlign="center"
            variant="h6"
            color="error"
            lineHeight={1}
            sx={{ cursor: "pointer", mx: 1 }}
          >
            Data is not a valid Json
          </MDTypography>
        )}
      </DialogContent>

      <DialogActions>
        {/* <Button  style={{ color: "white", background: "#0AB2D6" }}>
          Delete
        </Button> */}
        <Button
          disabled={!validJson}
          onClick={updateFilterHandler}
          style={{ color: "white", background: "#0AB2D6" }}
        >
          Save
        </Button>
        <Button onClick={CloseViewDialogHandler} style={{ color: "white", background: "#ED5F72" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteFilterDialog = ({
  openDeleteDialog,
  CloseDeleteDialogHandler,
  deleteFilterId,
  loadFilters,
  setSessionExpired,
}) => {
  const deleteFilter = () => {
    call(`filters/${deleteFilterId}`, "DELETE", {})
      .then((res) => {
        if (res.status == 200) {
          CloseDeleteDialogHandler();
          loadFilters();
        } else if (res.status == 440) {
          CloseDeleteDialogHandler();
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        CloseDeleteDialogHandler();
        Sentry.captureException(err);
      });
  };

  return (
    <Dialog open={openDeleteDialog} onClose={CloseDeleteDialogHandler} maxWidth={"md"}>
      <DialogTitle> Delete Filter </DialogTitle>
      <DialogContent>Are you Sure you want to Delete this Filter ?</DialogContent>

      <DialogActions>
        <Button onClick={deleteFilter} style={{ color: "white", background: "#0AB2D6" }}>
          Delete
        </Button>
        <Button
          onClick={CloseDeleteDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
