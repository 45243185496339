// SubscriptionModal.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './SubscriptionModal.css'; // Import your custom styles for the modal

const SubscriptionModal = ({ isOpen, onClose }) => {
  const [selectedOption, setSelectedOption] = useState('true_mindsets_yearly');
  const {t} = useTranslation()

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleSubscribe = () => {
    // You can perform subscription logic here
    const price = selectedOption === 'true_mindsets_monthly' ? 45 : 480;
    onClose(selectedOption);
  };

  return (
    <div className={`subscription-modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
      <h2>{t("subscriptionModal.title")}</h2>
        <div className="subscription-options">
        <div className={`option ${selectedOption === 'true_mindsets_yearly' ? 'selected' : ''}`} onClick={()=> {handleOptionChange('true_mindsets_yearly')}}>
        <label className="save">{t("subscriptionModal.save")}</label>
            <label>{t("subscriptionModal.yearly")}</label>
            <span>{t("subscriptionModal.yearlyPrice")}</span>
            <label>{t("subscriptionModal.perYear")}</label>
          </div>
          <div className={`option ${selectedOption === 'true_mindsets_monthly' ? 'selected' : ''}`} onClick={()=> {handleOptionChange('true_mindsets_monthly')}}>
            <label className={`month ${selectedOption === 'true_mindsets_monthly' ? 'selected' : ''}`}>{t("subscriptionModal.monthly")}</label>
            <span>{t("subscriptionModal.monthlyPrice")}</span>
            <label>{t("subscriptionModal.perMonth")}</label>
          </div>
        </div>
        <button onClick={handleSubscribe}>{t("buttonTitles.next")}</button>
      </div>
    </div>
  );
};

export default SubscriptionModal;
