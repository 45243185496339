import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import MDButton from "components/MDButton";
import { FormLabel, TextField } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { call } from "services/axiosService/core";
import { useMaterialUIController, setProfile, setPersona } from "context";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import MDInput from "components/MDInput";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";

const AthleteOnBoardingScreen = ({ role, setOpenLoader, setSessionExpired }) => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [personaName, setPersonaName] = useState([]);
  const [screen, setScreen] = useState(0);
  const [controller, dispatch] = useMaterialUIController();
  const [parentName, setParentName] = useState();

  const { profile } = controller;
  const navigate = useNavigate();
  const {t} = useTranslation()
  
  const RequiredLabel = ({ children }) => (
    <FormLabel
      sx={{ display: "flex", alignItems: "center", margin: "10px auto 5px", fontSize: "15px" }}
    >
      <span style={{ color: "red", fontSize: "small", marginRight: "5px" }}>*</span>
      {children}
      <span style={{ color: "red", fontSize: "small", marginLeft: "10px" }}>{t('formLabels.required')}</span>
    </FormLabel>
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const welcomeNextBtnHandler = () => {
    setScreen(screen + 1);
  };

  const onBoardingPreviousHandler = () => {
    setScreen(screen - 1);
  };

  const onBoardingNextHandler = (e) => {
    e.preventDefault();
    setScreen(screen + 1);
  };

  const logOutHandler = () => {
    localStorage.removeItem("session");
    signOut();
  };

  const completeHandler = () => {
    window.pendo.track("Learner Onboarding Completed");
    handleClose();
    let type = [];
    let payment_status = profile.payment_status;
    type = profile.type;
    const data = {
      pk: profile.pk ?? "user",
      sk: profile.sk ?? `user[${profile.id}]`,
      id: profile.id ?? null,
      cognito_user_id: profile.cognito_user_id ?? user.getUsername(),
      email: profile.email ?? null,
      status: "active",
      signup_at: new Date(),
      phone: phone != "" ? phone : null,
      relationships: profile.relationships,
      true_mindsets: profile.true_mindsets,
      subscription_type: profile.subscription_type ?? null,
      auto_renew: true,
      true_mindsets_id: null,
      payment_status: payment_status,
      type: type,
      details: {
        name: profile.details.name,
        dob: profile.details.dob,
        persona: profile.details.persona ?? personaName,
        gender: profile.details.gender,
        address: {
          street: profile.details.address.street,
          city: profile.details.address.city,
          state: profile.details.address.state,
          country: profile.details.address.country,
          zip: profile.details.address.zip,
        },
      },
      identities: [
        {
          google: { id: profile.identities[0].google.id ?? null },
          facebook: { id: profile.identities[0].facebook.id ?? null },
          apple: { id: profile.identities[0].apple.id ?? null },
          amazon: { id: profile.identities[0].amazon.id ?? null },
          email: { id: profile.cognito_user_id ?? user.getUsername() },
        },
      ],
    };

    call(`users/${profile.id}`, "PUT", { body: data })
      .then((res) => {
        if (res.status == 200) {
          let details = res.data.ModifiedAttributes;
          details.id = res.data.Id;
          setProfile(dispatch, details);
          const usertype = details.type;
          if (usertype.length == 1) {
            usertype[0].toLowerCase() === "learner" && setPersona(dispatch, "athlete");
            usertype[0].toLowerCase() === "adult" && setPersona(dispatch, "guardian");
          } else if (type.length == 2) {
            usertype[0].toLowerCase() === "adult" && setPersona(dispatch, "guardian");
            usertype[0].toLowerCase() === "learner" && setPersona(dispatch, "adultAthlete");
          } else {
            // type  will be ["Learner","Adult","Parent"]
            setPersona(dispatch, "guardian");
          }

          setOpenLoader(true);
        } else if (res.status == 400) {
          console.log("Input data is not valid");
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  useEffect(() => {
    if (profile.payment_status === "pending" || profile.payment_status === "fail") {
      if (profile.type.length === 1) {
        handleOpen();
        setScreen(3);
      } else {
        handleOpen();
        setScreen(4);
      }
    } else if (profile.status.toLowerCase() === "inactive") {
      call(`users/${profile.id}`, "GET", { params: { type: "guardian" } })
        .then((res) => {
          if (res.status === 200) {
            setFullName(profile.details.name);
            setParentName(res.data[0].details.name);
          } else if (res.status == 404) {
            console.log("Guardian details are not found");
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          Sentry.captureException(err);
        });

      handleOpen();
    }
  }, []);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent>
          {screen === 0 && (
            <Box>
              <Typography fontSize={18} textAlign="center" mb={2}>
              {t('messages.onboarding.welcome1')}
              </Typography>
              <Typography fontSize={18} textAlign="center">
              {t('messages.onboarding.welcome2')}
              </Typography>
              <MDButton
                onClick={welcomeNextBtnHandler}
                color="primary"
                sx={{ display: "block", marginLeft: "auto" }}
              >
                {t('buttonTitles.next')}
              </MDButton>
            </Box>
          )}

          {screen == 1 && (
            <>
              <form onSubmit={(e) => onBoardingNextHandler(e)}>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "center",
                    margin: "10px auto 2px",
                    fontSize: "20px",
                  }}
                >
                  <Typography variant="h5">{t('headings.personalInfo')}</Typography>
                </FormLabel>
                <RequiredLabel>
                  {" "}
                  {profile.relationships.parents ? t('formLabels.parent') : t('formLabels.guardian')}:{" "}
                </RequiredLabel>
                <TextField
                  name="parent"
                  type="text"
                  value={parentName}
                  fullWidth
                  required
                  disabled
                  sx={{ marginBottom: "10px" }}
                />
                <RequiredLabel>{t('formLabels.yourName')}:</RequiredLabel>

                <TextField name="name" type="text" value={fullName} fullWidth required disabled />
                <FormLabel sx={{ display: "block", margin: "10px auto 5px", fontSize: "15px" }}>
                {t('formLabels.phoneNumber')}:
                </FormLabel>
                <TextField
                  name="phone"
                  type="text"
                  size="medium"
                  sx={{ height: 50 }}
                  inputProps={{ maxLength: 10 }}
                  value={phone}
                  error={phone.length < 10 && phone.length > 0}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography fontSize={15}>+1</Typography>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setPhone(e.target.value);
                    }
                  }}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  marginTop="20px"
                >
                  <MDButton color="primary" onClick={onBoardingPreviousHandler}>
                    {t('buttonTitles.previous')}
                  </MDButton>
                  <MDButton
                    disabled={phone.length < 10 && phone.length > 0}
                    color="primary"
                    type="submit"
                  >
                     {t('buttonTitles.next')}
                  </MDButton>
                </Box>
              </form>
            </>
          )}

          {screen === 2 && (
            <Box>
              <Typography fontSize={18} mb={2}>
              {t('messages.onboarding.completedChild')}
              </Typography>
              <MDButton
                color="primary"
                sx={{ display: "block", marginLeft: "auto" }}
                onClick={completeHandler}
              >
                 {t('buttonTitles.finish')}
              </MDButton>
            </Box>
          )}

          {screen === 3 && (
            <Box>
              <Typography fontSize={18} mb={2}>
                {t('messages.onboarding.paymentIssueTitle')}
              </Typography>
              <Typography fontSize={18} mb={0}>
              {t('messages.onboarding.paymentIssueMessageChild')}
              </Typography>
              <Typography fontSize={18} mb={1}>
                {t('messages.onboarding.contactSupport')} <a href="mailto:support@maxu.co"> support@maxu.co </a>
              </Typography>
              <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <MDButton color="primary" onClick={logOutHandler}>
                  {t('buttonTitles.logOut')}
                </MDButton>
              </Box>
            </Box>
          )}

          {screen === 4 && (
            <Box>
              <Typography fontSize={18} mb={1}>
              {t('messages.onboarding.paymentIssueTitle')}
              </Typography>
              <Typography fontSize={18} mb={0}>
              {t('messages.onboarding.paymentIssueMessage')}
                <Button sx={{ padding: "0px 5px 0px 0px" }} onClick={() => navigate("/profile")}>
                  {t('buttonTitles.profile')}
                </Button>
                ,
              </Typography>
              <Typography fontSize={18} mb={1}>
              {t('messages.onboarding.contactSupport')} <a href="mailto:support@maxu.co"> support@maxu.co </a>
              </Typography>
              <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <MDButton color="primary" onClick={() => navigate("/profile")}>
                  {t('buttonTitles.profile')}
                </MDButton>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

AthleteOnBoardingScreen.defaultProps = {
  role: "athlete",
  setOpenLoader: () => {},
};

// Typechecking props for the DataTable
AthleteOnBoardingScreen.propTypes = {
  role: PropTypes.string,
  setOpenLoader: PropTypes.func,
};

export default AthleteOnBoardingScreen;
