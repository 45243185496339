import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDTypography from "components/MDTypography";
import { Button } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";

const Refresh = () => {
  const { t } = useTranslation();
  const { signOut } = useAuthenticator((context) => [context.user]);

  const logOutHandler = () => {
    localStorage.removeItem("session");
    localStorage.removeItem("referral");
    signOut();
  };

  return (
    <Dialog open={true} maxWidth={"xl"}>
      <DialogContent>
        <MDTypography>{t("session.expiredMessage")}</MDTypography>
        <MDTypography>
          {t("session.refreshOrLogin")}{" "}
          <Button sx={{ padding: "0px 5px 0px 0px", fontSize: 18 }} onClick={logOutHandler}>
            {t("session.login")}
          </Button>{" "}
          {t("session.toContinue")}
        </MDTypography>
      </DialogContent>
    </Dialog>
  );
};

export default Refresh;
