import { useTranslation } from "react-i18next";

const BestPracticesData = () => {
  const { t } = useTranslation();

  return [
    {
      persona: t("bestPractices.personaAthletes"),
      description: null,
      bestPractice: [
        t("bestPractices.athletesTip1"),
        t("bestPractices.athletesTip2"),
        t("bestPractices.athletesTip3"),
        t("bestPractices.athletesTip3Details.agreeableness"),
        t("bestPractices.athletesTip3Details.openness"),
        t("bestPractices.athletesTip3Details.extraversion"),
        t("bestPractices.athletesTip3Details.conscientiousness"),
        t("bestPractices.athletesTip3Details.temperament"),
        t("bestPractices.athletesTip4"),
        t("bestPractices.athletesTip5"),
        t("bestPractices.athletesTip6"),
      ],
    },
    {
      persona: t("bestPractices.personaParents"),
      description: t("bestPractices.parentsDescription"),
      bestPractice: [
        t("bestPractices.parentsTip1"),
        t("bestPractices.parentsTip2"),
        t("bestPractices.parentsTip3"),
        t("bestPractices.parentsTip3Details.agreeableness"),
        t("bestPractices.parentsTip3Details.openness"),
        t("bestPractices.parentsTip3Details.extraversion"),
        t("bestPractices.parentsTip3Details.conscientiousness"),
        t("bestPractices.parentsTip3Details.temperament"),
        t("bestPractices.parentsTip4"),
        t("bestPractices.parentsTip5"),
        t("bestPractices.parentsTip6"),
      ],
    },
    {
      persona: t("bestPractices.personaCoaches"),
      description: t("bestPractices.coachesDescription"),
      bestPractice: [
        t("bestPractices.coachesTip1"),
        t("bestPractices.coachesTip2"),
        t("bestPractices.coachesTip3"),
        t("bestPractices.coachesTip4"),
        t("bestPractices.coachesTip5"),
        t("bestPractices.coachesTip6"),
        t("bestPractices.coachesTip7"),
      ],
    },
  ];
};

export default BestPracticesData;
