/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": process.env.REACT_APP_COGNITO_POOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_CLIENT_ID,
    "oauth": {
        "domain": process.env.REACT_APP_COGNITO_DOMAIN,
        "scope": [
            "email",
            "openid",
        ],
        "redirectSignIn": process.env.REACT_APP_OAUTH_REDIRECT,
        "redirectSignOut": process.env.REACT_APP_OAUTH_REDIRECT,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "AMAZON",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
