import { useLocation, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import PropTypes from "prop-types";

function RequireAuth({ children }) {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== "authenticated")
    return <Navigate to="/auth/signin" state={{ from: location }} replace />;

  return children;
}
// Typechecking props for the BasicLayout
RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RequireAuth;