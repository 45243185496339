import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

export const deleteUser =  async () => {
    try {
        const result = await Auth.deleteUser();
      } catch (error) {
        console.log('Error deleting user', error);
      }
}

export const changePassword = async (oldpassword, newpassword) => {
  const user = await Auth.currentAuthenticatedUser();
  return new Promise(async (resolve, reject) => {
    Auth.changePassword(user, oldpassword, newpassword)
      .then((response) => {
        if (response) {
          resolve(response);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const SignOut = async() =>{
 await Auth.signOut() 
}