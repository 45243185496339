import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CircularProgress from "@mui/material/CircularProgress";

import CheckoutForm from "./CheckoutForm";
import { call } from "services/axiosService/core";
import MDBox from "components/MDBox";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import * as Sentry from "@sentry/browser";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

export default function Payment({
  childDetails,
  handleClosePaymentModal,
  amountToProcess,
  setSessionExpired,
  couponUsed,
  subscriptionType
}) {
  const [clientSecret, setClientSecret] = useState();
  const [stripePromise, setStripePromise] = useState();
  const [controller] = useMaterialUIController();
  const { profile } = controller;

  useEffect(() => {
    //getting the publish key
    call(`payments/config/${profile.id}`, "GET", {})
      .then((response) => {
        if (response.status == 200) {
          if (response.data.publish_key) {
            setStripePromise(loadStripe(response.data.publish_key));
          }
        } else if (response.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch(function (error) {
        Sentry.captureException(error);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    const data = { amount: amountToProcess };
    call(`payments/create-payment-intent/${profile.id}`, "POST", { body: data })
      .then((response) => {
        if (response.status == 200) {
          setClientSecret(response.data.clientSecret);
        } else if (response.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch(function (error) {
        Sentry.captureException(error);
        console.log(error);
      });
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {clientSecret && stripePromise ? (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            childDetails={childDetails}
            handleClosePaymentModal={handleClosePaymentModal}
            amountToProcess={amountToProcess}
            setSessionExpired={setSessionExpired}
            couponUsed = {couponUsed}
            subscriptionType = {subscriptionType}
          />
        </Elements>
      ) : (
        <MDBox display="flex" justifyContent="center">
          <CircularProgress />
        </MDBox>
      )}
    </div>
  );
}

Payment.defaultProps = {
  childDetails: {},
  handleClosePaymentModal: () => {},
  couponUsed: null

};

Payment.propTypes = {
  childDetails: PropTypes.object,
  handleClosePaymentModal: PropTypes.func,
  couponUsed: PropTypes.any
};
