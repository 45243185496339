import { Box, Button, Card, Grid, Icon, Modal, Tooltip, Typography } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect, useRef, useState } from "react";
import MDInput from "components/MDInput";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Otis Admin PRO React contexts
import { useMaterialUIController, setProfile } from "context";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import MDButton from "components/MDButton";
import ChangePassword from "./ChangePassword";
import { call } from "services/axiosService/core";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";
import { useTranslation } from 'react-i18next';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const AthleteProfile = () => {
  const [name, setName] = useState("");
  const [guardian, setGuardian] = useState();
  const [openProfilePictureModal, setOpenProfilePictureModal] = useState(false);
  // const [openEditModal, setOpenEditModal] = useState(false);
  // const [showEditButton, setShowEditButton] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { profile } = controller;
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [sessionExpired, setSessionExpired] = useState(false);
  const { t } = useTranslation();

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // const parentId = profile.relationships.parents[0];

  const openProfilePictureModalHandler = () => {
    setOpenProfilePictureModal(true);
  };

  const closeProfilePictureModal = () => {
    setOpenProfilePictureModal(false);
  };

  // const openEditModalHandler = () => {
  //   setOpenEditModal(true);
  // };

  // const closeEditModal = () => {
  //   setOpenEditModal(false);
  // };

  useEffect(() => {
    setName(profile.details.name);
  }, []);

  useEffect(() => {
    if (profile.relationships.parents  || profile.relationships.coaches) {
      const params = {
        type: "guardian",
      };
      call(`users/${profile.id}`, "GET", { params: params })
        .then((res) => {
          if (res.status == 200) {
            setGuardian(res.data[0].details.name);
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.log(err, "err");
        });
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar title={t("pageTitles.profile", { name: name.split(" ")[0] })} />
      <Grid item xs="8" mt={2}>
        <Card style={{ padding: "30px" }}>
          <Grid display="flex" gap={3} alignItems="center">
            <Grid item style={{ position: "relative", paddingRight: "10px" }}>
              {profile.profile_image_url == null ? (
                <Avatar
                  alt={t("imageAlts.athleteImage")}
                  shadow="sm"
                  sx={{ height: "90px", width: "90px" }}
                >
                  <PersonIcon sx={{ height: "85px", width: "85px" }} color="white" />
                </Avatar>
              ) : (
                <MDAvatar
                  src={profile.profile_image_url}
                  alt={t("imageAlts.athleteImage")}
                  size="xxl"
                  shadow="sm"
                />
              )}
              <EditIcon
                onClick={openProfilePictureModalHandler}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  color: "#0AB2D6",
                }}
              />
            </Grid>

            <Grid item xs="8">
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h4" fontWeight="medium">
                  {name}
                </MDTypography>
                <MDTypography variant="h5" color="text" fontWeight="regular">
                  {t('profileInfo.athlete')}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <MDTypography variant="h4" mt={1} mb={2}>
          {t('headings.profileInformation')}
          </MDTypography>

          <MDBox display="flex" justifyContent="" gap="10px">
            <MDTypography variant="p" fontSize={15} mb={1} ml={2}>
              <b>{t('profileInfo.name')}: </b>
              {name}
            </MDTypography>
            {/* {showEditButton && (
              <EditIcon
                onClick={openEditModalHandler}
                style={{ marginLeft: "10px", cursor: "pointer", color: "#0AB2D6" }}
              />
            )} */}
          </MDBox>
          <MDTypography variant="p" fontSize={15} mb={1} ml={2}>
            <b>{t('profileInfo.email')}: </b> {profile.email}
          </MDTypography>
          <MDTypography variant="p" fontSize={15} mb={1} ml={2}>
            <b>{t('profileInfo.guardian')}: </b>
            {guardian}
          </MDTypography>
          <MDButton
            variant="contained"
            color="primary"
            style={{ width: 220, marginLeft:15 }}
            onClick={() => {
              setOpenChangePasswordDialog(true);
            }}
          >
            {t('buttonTitles.changePassword')}
          </MDButton>
        </Card>
      </Grid>

      {openProfilePictureModal && (
        <ProfilePictureDialog
          openProfilePictureModal={openProfilePictureModal}
          closeProfilePictureModal={closeProfilePictureModal}
          profile={profile}
          setSessionExpired={setSessionExpired}
          t={t}
        />
      )}

      {openChangePasswordDialog && (
        <ChangePassword
          openChangePasswordDialog={openChangePasswordDialog}
          setOpenChangePasswordDialog={setOpenChangePasswordDialog}
          setOpenSnackbar={setOpenSnackbar}
          setSeverity={setSeverity}
          setAlertMessage={setAlertMessage}
        />
      )}

      <Snackbar
        autoHideDuration={5000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {sessionExpired && <Refresh />}

      {/* {openEditModal && (
        <ProfileEditDialog
          openEditModal={openEditModal}
          closeEditModal={closeEditModal}
          profile={profile}
          setName={setName}
        />
      )} */}
    </DashboardLayout>
  );
};

const ProfilePictureDialog = ({
  openProfilePictureModal,
  closeProfilePictureModal,
  profile,
  setSessionExpired,
  t
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [controller, dispatch] = useMaterialUIController();

  const removePhotoHanler = () => {
    setIsLoading(true);
    profile.profile_image_url = null;
    call(`users/${profile.id}`, "PUT", { body: profile })
      .then((res) => {
        if (res.status == 200) {
          setProfile(dispatch, profile);
          setIsLoading(false);
          closeProfilePictureModal(null);
        } else if ((res.status = 440)) {
          setIsLoading(false);
          closeProfilePictureModal(null);
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setIsLoading(false);
        closeProfilePictureModal();
      });
  };

  const uploadPhotoHandler = (e) => {
    setIsUploading(true);
    const data = new FormData();
    data.append("file", e.target.files[0]);
    const params = {
      upload: "image",
    };
    const headers = {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": "multipart/form-data",
    };

    call(`users/${profile.id}`, "PUT", { headers: headers, body: data, params: params })
      .then((res) => {
        if (res.status == 200) {
          profile.profile_image_url = res.data.profile_image_url;
          setProfile(dispatch, profile);
          setIsUploading(false);
          closeProfilePictureModal();
        } else if (res.status == 440) {
          setIsUploading(false);
          closeProfilePictureModal();
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsUploading(false);
        closeProfilePictureModal();
      });
  };

  return (
    <Dialog open={openProfilePictureModal} onClose={closeProfilePictureModal}>
      <CloseIcon
        fontSize="medium"
        onClick={closeProfilePictureModal}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />
      <DialogTitle>
        <MDTypography variant="h4" mb={3}>
          {t('headings.uploadProfilePicture')}
        </MDTypography>
      </DialogTitle>

      <DialogContent>
        <Grid container justify="flex-start">
          <Grid item style={{ position: "relative", paddingRight: "10px" }}>
            {profile.profile_image_url == null ? (
              <Avatar
              alt={t("imageAlts.athleteImage")}
                shadow="sm"
                sx={{ height: "90px", width: "90px" }}
              >
                <PersonIcon sx={{ height: "85px", width: "85px" }} color="white" />
              </Avatar>
            ) : (
              <MDAvatar
                src={profile.profile_image_url}
                alt={t("imageAlts.athleteImage")}
                size="xxl"
                shadow="sm"
              />
            )}
          </Grid>
          <Grid item>
            <button
              style={{
                display: "block",
                width: "120px",
                height: "30px",
                margin: "15px 10px 10px 10px",
                color: "white",
                background: "#F6821E",
              }}
              onClick={() => {
                document.getElementById("getFile").click();
              }}
            >
              {isUploading ?  t('buttonTitles.uploading'): t('buttonTitles.uploadPhoto')}
            </button>
            <input
              type="file"
              id="getFile"
              accept=".png, .jpg, .jpeg, .svg, .ico, .gif"
              onChange={uploadPhotoHandler}
              style={{ display: "none" }}
            ></input>
            {profile.profile_image_url != null && (
              <button
                style={{
                  display: "block",
                  width: "120px",
                  height: "30px",
                  margin: "10px",
                  color: "white",
                  background: "#d4242d",
                }}
                onClick={removePhotoHanler}
              >
                {isLoading ?  t('buttonTitles.removing'): t('buttonTitles.removePhoto')}
              </button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

// Learner Can't edit his own details - no need this modal
// const ProfileEditDialog = ({ openEditModal, closeEditModal, profile, setName }) => {
//   const nameRef = useRef();
//   const [controller, dispatch] = useMaterialUIController();

//   const editSubmitHandler = (e) => {
//     e.preventDefault();
//     const nameValue = nameRef.current.value;
//     const body = {
//       pk: profile.pk ?? "user",
//       sk: profile.sk ?? `user[${profile.id}]`,
//       details: {
//         address: {
//           city: profile.details.address.city,
//           country: profile.details.address.country,
//           state: profile.details.address.state,
//           street: profile.details.address.street,
//           zip: profile.details.address.zip,
//         },
//         dob: profile.details.dob,
//         name: nameValue,
//       },
//       email: profile.email,
//       id: profile.id,
//       cognito_user_id: profile.cognito_user_id,
//       identities: profile.identities,
//       profile_image_url: profile.profile_image_url,
//       relationships: profile.relationships,
//       status: profile.status,
//       payment_status: profile.payment_status,
//       type: profile.type,
//     };

//     call(`users/${profile.id}`, "PUT", { body: body })
//       .then((res) => {
//         closeEditModal();
//         setProfile(dispatch, body);
//         setName(res.ModifiedAttributes.details.name);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
//   return (
//     <Dialog open={openEditModal} onClose={closeEditModal}>
//       <CloseIcon
//         fontSize="medium"
//         onClick={closeEditModal}
//         style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
//       />
//       <DialogTitle>
//         <MDTypography variant="h4" mb={1}>
//           Edit Profile
//         </MDTypography>
//       </DialogTitle>

//       <form onSubmit={editSubmitHandler}>
//         <DialogContent>
//           <Typography variant="h6" textAlign="left" mb={2}>
//             Name:
//             <MDInput
//               rows="1"
//               name="name"
//               type="text"
//               defaultValue={profile.details.name}
//               inputRef={nameRef}
//               multiline
//               fullWidth
//             />
//           </Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             type="submit"
//             style={{
//               color: "white",
//               background: "#0AB2D6",
//               display: "block",
//             }}
//           >
//             Save Changes
//           </Button>
//         </DialogActions>
//       </form>
//     </Dialog>
//   );
// };

export default AthleteProfile;
