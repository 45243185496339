import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import ReactPlayer from "react-player";
import { call } from "services/axiosService/core";
import { useMaterialUIController } from "context";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";

const PlayContent = () => {
  const [controller] = useMaterialUIController();
  const { profile } = controller;
  const { contentTitle } = useParams();
  const [playContent, setPlayContent] = useState(false);
  const [metrics, setMetrics] = useState(false);
  const [content, setContent] = useState({});
  const [userMetricsData, setUserMetricsData] = useState({});
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    loadUserContentMetrics();
  }, [profile]);

  useEffect(() => {
    getContentLinkHandler();
  }, [profile]);

  const loadUserContentMetrics = () => {
    call(`users/${profile.id}/contentmetrics`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setUserMetricsData(data);
          setMetrics(true)
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const getContentLinkHandler = () => {
    call(`content/${contentTitle}`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          setPlayContent(true);
          setContent(res.data);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log("error is", err);
        Sentry.captureException(err);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title={contentTitle} />

      {playContent && metrics && (
        <ContentPlayerDialog
          playContent={playContent}
          content={content}
          userMetricsData={userMetricsData}
          setUserMetricData={setUserMetricsData}
          userId={profile.id}
          setSessionExpired={setSessionExpired}
        />
      )}
      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
};

const ContentPlayerDialog = ({
  playContent,
  content,
  userMetricsData,
  setUserMetricData,
  userId,
  setSessionExpired,
}) => {
  let src = content.content_url;
  let type = content.content_type.toLowerCase();
  let title = content.title;
  useEffect(() => {
    if (type == "video") {
      updateMetrics();
    }
  }, []);

  const updateMetrics = () => {
    let viewedcontent = userMetricsData["viewedcontent"];
    if (viewedcontent.includes(title)) {
      userMetricsData["eventkey"] = { event: "viewcontent", value: title };
    } else {
      userMetricsData["viewedcontent"] = [...viewedcontent, title];
      userMetricsData["eventkey"] = { event: "viewcontent", value: title };
    }
    call(`users/${userId}/contentmetrics`, "PUT", { body: userMetricsData })
      .then((res) => {
        if (res.status == 200) {
          setUserMetricData(userMetricsData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };
  return (
    <MDBox alignItems="center" color="white" margin={5}>
      {type == "video" ? (
        <ReactPlayer url={src} playing={true} controls={true} width="100%" />
      ) : (
        <MDBox display="flex" justifyContent="center" alignSelf="end" alignItems="center">
          <MDButton
            onClick={updateMetrics}
            component={"a"}
            href={src}
            target="_blank"
            rel="noreferrer"
            variant="outlined"
            size="large"
            color={"info"}
          >
            Open in new tab
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  );
};

export default PlayContent;
