import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { Checkbox, FormControlLabel, FormGroup, FormLabel, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useMaterialUIController } from "context";
import { call } from "services/axiosService/core";
import * as Sentry from "@sentry/browser";
import { useTranslation } from 'react-i18next';

export default function Coupon({
  setAmountToProcess,
  setProceedtoPayment,
  childDetails,
  handleClosePaymentModal,
  setSessionExpired,
  setCouponUsed,
  subscriptionType
}) {
  const { t } = useTranslation();
  const [controller] = useMaterialUIController();
  const { profile } = controller;
  const [referralCode, setReferralCode] = useState("");
  const [codeApplied, setCodeApplied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [showHelperText, setShowHelperText] = useState(false);
  const [amount, setAmount] = useState(null);
  const [paymentNotRequired, setPaymentNotRequired] = useState(false);

  useEffect(() => {
    const referral = localStorage.getItem("referral");
    if (referral) {
      setReferralCode(referral);
      applyCouponHandler(referral);
    }
  }, []);

  const applyCouponHandler = (code) => {
    // const data = { referral_code: referralCode };
    setIsLoading(true);
    let data = {"code": code, "subscription_type": subscriptionType }
    call(`payments/apply-coupon`, "POST", {body: data})
      .then((response) => {
        if (response.status == 200) {
          if (parseFloat(response.data.amount_to_process) > 0) {
            // setPaymentNotRequired(true);
            setHelperText(
              t("alerts.referrals.couponApplied", { amount: response.data.amount_to_process })
            );
          } else {
            setPaymentNotRequired(true);
            setHelperText(t('alerts.referrals.noPaymentRequired'));
          }
          setCodeApplied(true);
          setShowHelperText(true);
          setAmount(response.data.amount_to_process);
          setCouponUsed(code);
        } else if (response.status == 404 || response.status == 403) {
          setHelperText(t('alerts.referrals.notValid'));
          setShowHelperText(true);
          setAmount(null);
          setCouponUsed(null);
        } else if (response.status == 440) {
          setSessionExpired(true);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        Sentry.captureException(error);
        setIsLoading(false);
        setAmount(null);
        setCouponUsed(null);
        setHelperText(t('alerts.referrals.error'));
        setShowHelperText(true);
        console.log(error);
      });

    setIsLoading(true);
  };

  const RemoveCouponHandler = () => {
    setCodeApplied(false);
    setAmount(99);
    setPaymentNotRequired(false);
    setHelperText(t('alerts.referrals.removeCoupon'));
    setCouponUsed(null);
  };

  const proceedToPaymentHandler = () => {
    if (paymentNotRequired) {
      // Update the coupon - Descrease coupon remaing usage count and add user email adress to coupon uses object
      call(`payments/coupon/${referralCode}`, "PUT", { body: { email: profile.email } })
        .then((response) => {
          console.log("coupon used");
        })
        .catch(function (error) {
          Sentry.captureException(error);
          console.log(error);
        });

      // send Receipt for $0
      call(`users/${profile.id}/emails`, "POST", {
        body: { email_type: "payment_success_email", recipient_id: profile.id, amount_paid: 0 },
      })
        .then((response) => {
          if (response.status == 200) {
            console.log("Email success");
          }
        })
        .catch(function (error) {
          Sentry.captureException(error);
          console.log(error);
        });

      // update the user payment_status success
      const LearnerDetails = childDetails;
      LearnerDetails.payment_status = "success";
      LearnerDetails.paid_at = new Date();
      LearnerDetails.referral_code = referralCode;
      const source = LearnerDetails.source;
      LearnerDetails.source = source ? source : referralCode && referralCode.includes('_')  ? referralCode.split('_')[0] + "_code" : null ;

      call(`users/${LearnerDetails.id}`, "PUT", {
        body: LearnerDetails,
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.status == 200) {
            handleClosePaymentModal("PymentNotRequired");
          }
        })
        .catch(function (error) {
          Sentry.captureException(error);
          console.log(error);
        });
    } else {
      if (amount) {
        setAmountToProcess(amount);
      }
      setProceedtoPayment(true);
    }
  };

  return (
    <MDBox>
      <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
       {t('formLabels.haveReferral')}
      </FormLabel>
      <TextField
        name="coupon"
        placeholder={t('formPlaceHolders.haveReferral')}
        type="text"
        value={referralCode}
        onChange={(e) => {
          if (e.nativeEvent.data != " ") {
            setReferralCode(e.target.value);
          }
        }}
        fullWidth
        required
        sx={{ marginBottom: "10px" }}
      />
      {showHelperText && (
        <MDTypography variant="h6" mb={1}>
          {helperText}
        </MDTypography>
      )}

      <Grid container justifyContent="center" alignItems="center" direction={"column"}>
        <Grid Item marginTop={"30px"}>
          {codeApplied ? (
            <MDButton style={{ width: "200px" }} onClick={RemoveCouponHandler} color="error">
              {t('buttonTitles.removeReferralCode')}
            </MDButton>
          ) : (
            <MDButton
              style={{ width: "200px" }}
              onClick={() => applyCouponHandler(referralCode)}
              color="primary"
              disabled={referralCode.length < 1 || isLoading}
            >
              {isLoading ? <CircularProgress color="white" size={20} /> : t('buttonTitles.applyReferralCode')}
            </MDButton>
          )}
        </Grid>
        <Grid Item marginTop={"20px"} marginBottom={"20px"}>
          <MDButton
            style={{ width: "200px" }}
            onClick={proceedToPaymentHandler}
            color="primary"
            disabled={isLoading}
            fullWidth
          >
            {paymentNotRequired ? t('finish') : t('buttonTitles.proceedToPayment')}
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}
