import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LinearProgress from "@mui/material/LinearProgress";
import useMediaQuery from "@mui/material/useMediaQuery";

// Soft UI Dashboard PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import RadarChart from "examples/Charts/RadarChart";
import Breadcrumbs from "examples/Breadcrumbs";

import radarChartData from "layouts/pages/charts/data/radarChartData";
import data from "./Questions";
import { call } from "services/axiosService/core";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useMaterialUIController } from "context";

import Loader from "pages/loader/Loader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";

export function Assessment() {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { id: assessment_id } = useParams();
  // const assessment = data.questions;
  const [assessment, setAssessment] = useState([]);
  const [assessmentType, setAssessmentType] = useState();
  const [options, setOptions] = useState([
    t("assessmentInstructions.options.disagree"),
    t("assessmentInstructions.options.slightlyDisagree"),
    t("assessmentInstructions.options.neutral"),
    t("assessmentInstructions.options.slightlyAgree"),
    t("assessmentInstructions.options.agree"),
  ]);
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const [answerList, setAnswerList] = useState([]);
  const [activeAssessment, setActiveAssessment] = useState({});
  const [clicked, setClicked] = useState(false);
  const [showInsightsLoader, setShowInsightsLoader] = useState(false);
  const val = (answerList.length / assessment.length) * 100;
  // const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [controller] = useMaterialUIController();
  const { profile } = controller;
  const [sessionExpired, setSessionExpired] = useState(false);

  const answerbtnHandler = (ques, ans) => {
    setClicked(true);
    answerList[ques] = ans;
    setAnswerList(answerList);
    localStorage.setItem("answers" + assessment_id, JSON.stringify(answerList));
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion <= assessment.length) {
      setTimeout(() => {
        setCurrentQuestion(nextQuestion);
        setClicked(false);
      }, 250);
    }
  };

  const handlePreviousQuestion = () => {
    const previousQuestion = currentQuestion - 1;
    if (previousQuestion < assessment.length + 1) {
      setCurrentQuestion(previousQuestion);
    }
  };

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion <= assessment.length) {
      setCurrentQuestion(nextQuestion);
    }
  };

  const submitBtnHandler = () => {
    const answers = localStorage.getItem("answers" + assessment_id);

    const body = {
      answers: answers,
      assigned_date: activeAssessment.assigned_date,
      details: {
        name: activeAssessment.details.name,
      },
      external_id: activeAssessment.external_id,
      id: activeAssessment.id,
      shared_with: activeAssessment.shared_with,
      status: "scoring",
      user_id: activeAssessment.user_id,
    };

    call(`users/${profile.id}/assessments/${assessment_id}`, "PUT", { body: body })
      .then((res) => {
        if (res.status == 200) {
          localStorage.removeItem("answers" + assessment_id);
          // localStorage.removeItem("ActiveAssessment");
          setShowInsightsLoader(true);
          // navigate("/dashboard");
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
  };

  useEffect(() => {
    try {
      const ansValue = JSON.parse(localStorage.getItem("answers" + assessment_id));
      if (!ansValue) {
        console.log("no answers found");
      } else {
        setAnswerList(ansValue);
      }
    } catch (err) {
      console.log(err);
    }
    call(`users/${profile.id}/assessments/${assessment_id}`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          setActiveAssessment(res.data);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });

    // try {
    //   const newAssessment = JSON.parse(localStorage.getItem("ActiveAssessment"));
    //   if (!newAssessment) {
    //     console.log("no assessment found");
    //   } else {
    //     setActiveAssessment(newAssessment);
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  }, []);

  useEffect(() => {
    if (profile.id && assessment_id) {
      call(`users/${profile.id}/assessments/${assessment_id}/questions`, "GET", {})
        .then((res) => {
          if (res.status == 200) {
            setAssessment(res.data.questions);
            if (res.data.assessment_type == "SEARS") {
              setAssessmentType("SEARS");
              setOptions([
                t("assessmentInstructions.options.never"),
                t("assessmentInstructions.options.sometimes"),
                t("assessmentInstructions.options.often"),
                t("assessmentInstructions.options.always"),
              ]);
            } else {
              setAssessmentType("NEO");
            }
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.log(err, "err");
        });
    }
  }, []);

  // useEffect(() => {

  // }, [])

  const putHandler = () => {
    setCurrentQuestion(answerList.length);
    if (activeAssessment.status == "not_started") {
      const body = {
        assigned_date: activeAssessment.assigned_date,
        details: {
          name: activeAssessment.details.name,
        },
        external_id: activeAssessment.external_id,
        shared_with: activeAssessment.shared_with,
        status: "in_progress",
      };

      call(`/users/${profile.id}/assessments/${assessment_id}`, "PUT", { body })
        .then((res) => {
          if (res.status == 200) {
            console.log(res.data);
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          console.log(err);
        });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        breadcrumbs={
          <Breadcrumbs
            icon="home"
            title={t("pageTitles.assessment")}
            route={["Assessments"]}
            light={false}
          />
        }
      />
      {showInsightsLoader && (
        <PreparingInsightsDialog
          assessmentId={assessment_id}
          userId={profile.id}
          showInsightsLoader={showInsightsLoader}
          setShowInsightsLoader={setShowInsightsLoader}
          setSessionExpired={setSessionExpired}
          t={t}
        />
      )}

      <MDBox my={3}>
        <Card>
          <MDBox
            bgColor="white"
            variant="gradient"
            borderRadius="xl"
            sx={{ background: ({ palette: { background } }) => false && background.card }}
          >
            {currentQuestion < 0 && assessmentType == "NEO" && (
              <>
                <MDBox pt={3} px={3}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    {t("assessmentInstructions.title")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    {t("assessmentInstructions.neoInstructions.option1")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    {t("assessmentInstructions.neoInstructions.option2")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    {t("assessmentInstructions.neoInstructions.option3")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    {t("assessmentInstructions.neoInstructions.option4")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    {t("assessmentInstructions.neoInstructions.option5")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    {t("assessmentInstructions.note")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    {t("assessmentInstructions.timeEstimate")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    {t("assessmentInstructions.thankYou")}
                  </MDTypography>
                </MDBox>
                <MDBox textAlign={"center"} p={2}>
                  {/* <MDButton color="primary" onClick={() => setCurrentQuestion(answerList.length)}> */}
                  <MDButton color="primary" onClick={putHandler}>
                    {activeAssessment.status == "not_started"
                      ? t("buttonTitles.beginAssessment")
                      : t("buttonTitles.resumeAssessment")}
                  </MDButton>
                </MDBox>
              </>
            )}
            {currentQuestion < 0 && assessmentType == "SEARS" && (
              <>
                <MDBox pt={3} px={3}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    {t("assessmentInstructions.title")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    {t("assessmentInstructions.searsInstructions.option1")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    {t("assessmentInstructions.searsInstructions.option2")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    {t("assessmentInstructions.searsInstructions.option3")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={1}>
                    {t("assessmentInstructions.searsInstructions.option4")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    {t("assessmentInstructions.note")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    {t("assessmentInstructions.timeEstimate")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    {t("assessmentInstructions.thankYou")}
                  </MDTypography>
                </MDBox>
                <MDBox textAlign={"center"} p={2}>
                  {/* <MDButton color="primary" onClick={() => setCurrentQuestion(answerList.length)}> */}
                  <MDButton color="primary" onClick={putHandler}>
                    {activeAssessment.status == "not_started"
                      ? t("buttonTitles.beginAssessment")
                      : t("buttonTitles.resumeAssessment")}
                  </MDButton>
                </MDBox>
              </>
            )}

            {currentQuestion > -1 && currentQuestion <= assessment.length && (
              <>
                <MDBox pt={4} pb={2} px={3} textAlign="center">
                  <MDBox px={2} mb={5} sx={{ width: 1 }}>
                    <MDProgress value={Math.round(val)} label={Math.round(val)} />
                  </MDBox>

                  {currentQuestion < assessment.length && (
                    <>
                      <MDTypography variant="h4" fontWeight="medium" color="dark" mb={2}>
                        {assessment[currentQuestion]}
                      </MDTypography>
                    </>
                  )}
                </MDBox>

                {currentQuestion < assessment.length && (
                  <>
                    <MDBox textAlign="center" p={2}>
                      <ButtonGroup
                        variant="contained"
                        size="large"
                        orientation={`${matches ? `horizontal` : `vertical`}`}
                        style={{ boxShadow: "none" }}
                      >
                        {options.map((ans, index) => {
                          return (
                            <MDButton
                              key={index}
                              size="medium"
                              color={
                                (answerList[currentQuestion] === index + 1
                                  ? "success"
                                  : "greyMedium") || (clicked ? "success" : "greyMedium")
                              }
                              onClick={() => {
                                answerbtnHandler(currentQuestion, index + 1);
                              }}
                            >
                              <MDTypography variant="p" fontWeight="medium" color="dark">
                                {ans}
                              </MDTypography>
                            </MDButton>
                          );
                        })}
                      </ButtonGroup>
                    </MDBox>
                    <MDBox px={5} py={2} display="flex" justifyContent="space-between">
                      <MDButton color="primary" onClick={handlePreviousQuestion}>
                        {t("buttonTitles.previous")}
                      </MDButton>
                      <MDButton
                        color={answerList.length <= currentQuestion ? "secondary" : "primary"}
                        disabled={answerList.length <= currentQuestion}
                        onClick={() => handleNextQuestion()}
                      >
                        {t("buttonTitles.next")}
                      </MDButton>
                    </MDBox>
                  </>
                )}
              </>
            )}
            {currentQuestion === assessment.length && (
              <>
                <MDBox px={3} textAlign="center">
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    {t("assessmentInstructions.assessmentCompleted")}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium" color="dark" mb={2}>
                    {t("assessmentInstructions.finish")}
                  </MDTypography>
                  {/* <MDTypography variant="h6" fontWeight="medium" color="dark">
                    You will be notified once your results are available to view.
                  </MDTypography> */}
                </MDBox>
                <MDBox textAlign={"center"} p={3}>
                  <MDButton color="primary" onClick={submitBtnHandler}>
                    {t("buttonTitles.submitAssessment")}
                  </MDButton>
                </MDBox>
              </>
            )}
          </MDBox>
        </Card>
      </MDBox>
      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
}

const PreparingInsightsDialog = ({
  assessmentId,
  userId,
  setShowInsightsLoader,
  showInsightsLoader,
  setSessionExpired,
  t,
}) => {
  const navigate = useNavigate();
  const checkInsights = () => {
    call(`users/${userId}/assessments/${assessmentId}`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          const resData = res.data;
          // If Insights not present in assessment data again check after 10 seconds
          if (resData.status == "ready") {
            clearTimeout(myTimeout);
            clearInterval(myInterval);
            navigate(`/insight/${assessmentId}/${userId}`);
          }
        } else if (res.status == 440) {
          setShowInsightsLoader(false);
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.log(err);
      });
  };

  // Check Insights are ready or not for every 10 seconds
  let myInterval = setInterval(checkInsights, 10000);

  // After 60 seconds stop checking for insights
  let myTimeout = setTimeout(() => {
    setShowInsightsLoader(false);
    clearInterval(myInterval);
    navigate(`/insight/${assessmentId}/${userId}`);
  }, 60000);

  return (
    <Dialog open={showInsightsLoader} fullScreen>
      <DialogContent>
        <Loader loaderText={t("loaderText.scoringAssessment")} />
      </DialogContent>
    </Dialog>
  );
};
