import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { call } from "services/axiosService/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeleteUserButton = ({ id, DeleteUserHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Delete User</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="error"
          onClick={() => {
            DeleteUserHandler(id);
          }}
        >
          delete
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const BlockUserButton = ({ id, BlockUserHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Block User</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="error"
          onClick={() => {
            BlockUserHandler(id);
          }}
        >
          lock_person
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const ActivateUserButton = ({ id, ActivateUserHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Activate User</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="info"
          onClick={() => {
            ActivateUserHandler(id);
          }}
        >
          lock_open
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

const ResetPasswordButton = ({ id, ResetPasswordHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Reset Password</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="info"
          onClick={() => {
            ResetPasswordHandler(id);
          }}
        >
          lock_reset
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

export function UsersTable(props) {
  const [selectedId, setSelectedId] = useState("");
  const [selectedUserDetails, setSelectedUserDetails] = useState("");
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openBlockUserDialog, setOpenBlockUserDialog] = useState(false);
  const [openActivateUserDialog, setOpenActivateUserDialog] = useState(false);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [selectUserId, setSelectUserId] = useState("");
  const [pageNumber, setPageNumber] = useState(0)
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [sessionExpired ,setSessionExpired] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const CloseViewUserDialogHandler = () => {
    setOpenViewDialog(false);
    setSelectedId("");
  };

  const CloseDeleteUserDialogHandler = () => {
    setOpenDeleteDialog(false);
    setSelectedId("");
  };

  const DeleteUserHandler = (id) => {
    setSelectUserId(id);
    setOpenDeleteDialog(true);
  };

  const BlockUserHandler = (id) => {
    setOpenBlockUserDialog(true);
    const user = props.users.filter((row) => {
      return row.id == id;
    });
    setSelectedUserDetails(user[0]);
  };

  const CloseBlockUserDialogHandler = () => {
    setOpenBlockUserDialog(false);
    setSelectedId("");
  };

  const ActivateUserHandler = (id) => {
    setOpenActivateUserDialog(true);
    const user = props.users.filter((row) => {
      return row.id == id;
    });
    setSelectedUserDetails(user[0]);
  };

  const CloseActivateUserDialogHandler = () => {
    setOpenActivateUserDialog(false);
    setSelectedId("");
  };

  const CloseResetPasswordDialogHandler = () => {
    setOpenResetPasswordDialog(false);
    setSelectedId("");
  };

  const ResetPasswordHandler = (id) => {
    setSelectUserId(id);
    setOpenResetPasswordDialog(true);
  };

  useEffect(() => {
    if (
      !openDeleteDialog &&
      !openBlockUserDialog &&
      !openActivateUserDialog &&
      !openResetPasswordDialog
    ) {
      if (selectedId) {
        const user = props.users.filter((row) => {
          return row.id == selectedId;
        });
        setSelectedUserDetails(user[0]);

        setOpenViewDialog(true);
      }
    }
  }, [selectedId]);

  const tableData = {
    columns: [
      { Header: "#", accessor: "num", width: "10%" },
      { Header: "PrimaryKey", accessor: "pk", width: "10%" },
      { Header: "SortKey", accessor: "sk", width: "30%" },
      { Header: "Email", accessor: "email", width: "20%" },
      { Header: "Name", accessor: "name", width: "15%" },
      {
        Header: "Status",
        accessor: "status",
      },
      { Header: "Actions", accessor: "actions", width: "25%" },
    ],

    rows: props.users,
  };

  tableData.rows = tableData.rows.map((row, index) => ({
    ...row,
    num: index + 1,
    name: row.details.name,
    type: JSON.stringify(row.type),
    actions: (
      <div style={{ display: "flex" }}>
        <DeleteUserButton id={row.id} DeleteUserHandler={DeleteUserHandler} />
        {row.status != "block" && (
          <BlockUserButton id={row.id} BlockUserHandler={BlockUserHandler} />
        )}
        {row.status == "block" && (
          <ActivateUserButton id={row.id} ActivateUserHandler={ActivateUserHandler} />
        )}
        <ResetPasswordButton id={row.id} ResetPasswordHandler={ResetPasswordHandler} />
      </div>
    ),
  }));
  return (
    <>
      <DataTable table={tableData} setSelectedId={setSelectedId} pageNumber={pageNumber} setPageNumber={setPageNumber} />
      {openViewDialog && (
        <ViewUserDataDialog
          openViewDialog={openViewDialog}
          CloseViewUserDialogHandler={CloseViewUserDialogHandler}
          selectedUserDetails={selectedUserDetails}
          loadUsers={props.loadUsers}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openDeleteDialog && (
        <DeleteUserDialog
          openDeleteDialog={openDeleteDialog}
          CloseDeleteUserDialogHandler={CloseDeleteUserDialogHandler}
          selectUserId={selectUserId}
          loadUsers={props.loadUsers}
          loadAssessments={props.loadAssessments}
          loadFilters={props.loadFilters}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openBlockUserDialog && (
        <BlockUserDialog
          openBlockUserDialog={openBlockUserDialog}
          CloseBlockUserDialogHandler={CloseBlockUserDialogHandler}
          loadUsers={props.loadUsers}
          selectedUserDetails={selectedUserDetails}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openActivateUserDialog && (
        <ActivateUserDialog
          openActivateUserDialog={openActivateUserDialog}
          CloseActivateUserDialogHandler={CloseActivateUserDialogHandler}
          loadUsers={props.loadUsers}
          selectedUserDetails={selectedUserDetails}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openResetPasswordDialog && (
        <ResetPasswordDialog
          openResetPasswordDialog={openResetPasswordDialog}
          CloseResetPasswordDialogHandler={CloseResetPasswordDialogHandler}
          selectUserId={selectUserId}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSessionExpired={setSessionExpired}
        />
      )}

      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {sessionExpired && (
        <Refresh/>
      )}
    </>
  );
}

const ViewUserDataDialog = ({
  openViewDialog,
  CloseViewUserDialogHandler,
  selectedUserDetails,
  loadUsers,
  setSessionExpired,
}) => {
  const [details, setDetails] = useState(JSON.stringify(selectedUserDetails, null, 3));
  const [validJson, setValidJson] = useState(true);

  const updateUserHandler = () => {
    const data = JSON.parse(details);
    call(`users/${data.id}`, "PUT", { body: data })
      .then((res) => {
        if (res.status == 200) {
          CloseViewUserDialogHandler();
          loadUsers();
        }
        else if(res.status == 440){
          CloseViewUserDialogHandler();
          setSessionExpired(true)
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        CloseViewUserDialogHandler();
      });
  };

  return (
    <Dialog
      open={openViewDialog}
      onClose={CloseViewUserDialogHandler}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle> Edit User Details </DialogTitle>
      <DialogContent>
        <MDBox p={1} lineHeight={1}>
          <MDInput
            value={details}
            error={!validJson}
            multiline
            name="details"
            type="text"
            fullWidth
            required
            onChange={(e) => {
              setDetails(e.target.value);
              try {
                JSON.parse(e.target.value);
                setValidJson(true);
              } catch (error) {
                setValidJson(false);
              }
            }}
          />
        </MDBox>
        {!validJson && (
          <MDTypography textAlign="center" variant="h6" color="error" lineHeight={1} sx={{ cursor: "pointer", mx: 1 }}>
            Data is not a valid Json
          </MDTypography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!validJson}
          onClick={updateUserHandler}
          style={{ color: "white", background: "#0AB2D6" }}
        >
          Save
        </Button>
        <Button
          onClick={CloseViewUserDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteUserDialog = ({
  openDeleteDialog,
  CloseDeleteUserDialogHandler,
  selectUserId,
  loadUsers,
  loadAssessments,
  loadFilters,
  setSessionExpired
}) => {
  const deleteUser = () => {
    call(`users/${selectUserId}`, "DELETE", {})
      .then((res) => {
        if (res.status == 200) {
          CloseDeleteUserDialogHandler();
          loadUsers();
          loadAssessments();
          loadFilters();
        }
        else if(res.status == 440){
          CloseDeleteUserDialogHandler();
          setSessionExpired(true)
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        CloseDeleteUserDialogHandler();
      });
  };

  return (
    <Dialog open={openDeleteDialog} onClose={CloseDeleteUserDialogHandler} maxWidth={"md"}>
      <DialogTitle> Delete User </DialogTitle>
      <DialogContent>Are you sure you want to Delete this user ?</DialogContent>

      <DialogActions>
        <Button onClick={deleteUser} style={{ color: "white", background: "#0AB2D6" }}>
          Delete
        </Button>
        <Button
          onClick={CloseDeleteUserDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const BlockUserDialog = ({
  openBlockUserDialog,
  CloseBlockUserDialogHandler,
  loadUsers,
  selectedUserDetails,
  setSessionExpired
}) => {
  const updateUserHandler = () => {
    const data = selectedUserDetails;
    data.status = "block";
    // console.log("data is ", data);
    call(`users/${data.id}`, "PUT", { body: data })
      .then((res) => {
        if (res.status == 200) {
          CloseBlockUserDialogHandler();
          loadUsers();
        }
        else if(res.status == 440){
          CloseBlockUserDialogHandler();
          setSessionExpired(true)
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        CloseBlockUserDialogHandler();
      });
  };

  return (
    <Dialog open={openBlockUserDialog} onClose={CloseBlockUserDialogHandler} maxWidth={"md"}>
      <DialogTitle> Block the user </DialogTitle>

      <DialogContent>Are you sure you want to Block user ?</DialogContent>

      <DialogActions>
        <Button onClick={updateUserHandler} style={{ color: "white", background: "#0AB2D6" }}>
          Block
        </Button>
        <Button
          onClick={CloseBlockUserDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ActivateUserDialog = ({
  openActivateUserDialog,
  CloseActivateUserDialogHandler,
  loadUsers,
  selectedUserDetails,
  setSessionExpired
}) => {
  const updateUserHandler = () => {
    const data = selectedUserDetails;
    data.status = "inactive";
    call(`users/${data.id}`, "PUT", { body: data })
      .then((res) => {
        if (res.status == 200) {
          CloseActivateUserDialogHandler();
          loadUsers();
        }
        else if(res.status == 440){
          CloseActivateUserDialogHandler();
          setSessionExpired(true)
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        CloseActivateUserDialogHandler();
      });
  };

  return (
    <Dialog open={openActivateUserDialog} onClose={CloseActivateUserDialogHandler} maxWidth={"md"}>
      <DialogTitle> Block the user </DialogTitle>

      <DialogContent>Are you sure you want to Activate user ?</DialogContent>

      <DialogActions>
        <Button onClick={updateUserHandler} style={{ color: "white", background: "#0AB2D6" }}>
          Activate
        </Button>
        <Button
          onClick={CloseActivateUserDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ResetPasswordDialog = ({
  openResetPasswordDialog,
  CloseResetPasswordDialogHandler,
  selectUserId,
  setOpenSnackbar,
  setAlertMessage,
  setSessionExpired
}) => {
  const ResetHandler = () => {
    call(`users/${selectUserId}/resetpassword`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          setOpenSnackbar(true);
          setAlertMessage(
            `Reset Password is successfull- New Password is ${res.data.new_password}`
          );
        } 
        else if(res.status == 440){
          CloseResetPasswordDialogHandler();
          setSessionExpired(true)
        }
        else if (res.status == 404) {
          setOpenSnackbar(true);
          setAlertMessage(`Error while reseting the password`);
        }
        CloseResetPasswordDialogHandler();
      })
      .catch((err) => {
        Sentry.captureException(err);
        CloseResetPasswordDialogHandler();
      });
  };
  return (
    <Dialog
      open={openResetPasswordDialog}
      onClose={CloseResetPasswordDialogHandler}
      maxWidth={"md"}
    >
      <DialogTitle> Reset user password </DialogTitle>

      <DialogContent>Are you sure you want to reset user password ?</DialogContent>

      <DialogActions>
        <Button onClick={ResetHandler} style={{ color: "white", background: "#0AB2D6" }}>
          Reset
        </Button>
        <Button
          onClick={CloseResetPasswordDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
