import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import your translation files
import en from 'locales/en.json';
import es from 'locales/es.json';
import de from 'locales/de.json';
import pt from 'locales/pt.json';
import fr from 'locales/fr.json';
import it from 'locales/it.json';


i18n
  .use(LanguageDetector)// Add the language detector plugin
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources: {
      en: { translation: en },
      es: { translation: es },
      de: { translation: de },
      pt: { translation: pt },
      fr: { translation: fr },
      it: { translation: it },
    },
    fallbackLng: 'en', // Default language if no match is found
    debug: false, // Set to false in production
    detection: {
      order: ['querystring', 'cookie', 'localStorage','navigator', 'htmlTag'],
      caches: ['cookie', 'localStorage'], // Where to store the detected language
    },
    interpolation: {
      escapeValue: false, // React already escapes content
    },
  });

export default i18n;