import React, { useRef, useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Button, Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import YupPassword from "yup-password";
import { FormLabel, TextField, CircularProgress } from "@mui/material";
import { changePassword } from "services/awsService/awsService";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";

YupPassword(yup);

const RequiredLabel = ({ children }) => {
  const { t } = useTranslation();
  return (
    <FormLabel
      sx={{ display: "flex", alignItems: "center", margin: "10px auto 5px", fontSize: "20px" }}
    >
      <span style={{ color: "red", fontSize: "small", marginRight: "5px" }}>*</span>
      {children}
      <span style={{ color: "red", fontSize: "small", marginLeft: "10px" }}>
        {t("formLabels.required")}
      </span>
    </FormLabel>
  );
};

const ChangePassword = ({
  openChangePasswordDialog,
  setOpenChangePasswordDialog,
  setOpenSnackbar,
  setSeverity,
  setAlertMessage,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    password: yup
      .string()
      .password()
      .min(8, t("alerts.password.passwordTooShort"))
      .minUppercase(1, t("alerts.password.passwordNoUppercase"))
      .minLowercase(1, t("alerts.password.passwordNoLowercase"))
      .minNumbers(1, t("alerts.password.passwordNoNumber"))
      .minSymbols(1, t("alerts.password.passwordNoSymbol"))
      .required(),
    newPassword: yup
      .string()
      .password()
      .min(8, t("alerts.password.passwordTooShort"))
      .minUppercase(1, t("alerts.password.passwordNoUppercase"))
      .minLowercase(1, t("alerts.password.passwordNoLowercase"))
      .minNumbers(1, t("alerts.password.passwordNoNumber"))
      .minSymbols(1, t("alerts.password.passwordNoSymbol"))
      .required(),
  });
  const defaultValues = {
    password: "",
    newPassword: "",
  };
  const { handleSubmit, reset, control, formState, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const changePasswordHandler = (data) => {
    setLoading(true);
    // console.log("Change Password");
    // console.log("data is", data);
    changePassword(data.password, data.newPassword)
      .then((resp) => {
        setLoading(false);
        setOpenSnackbar(true);
        setSeverity("success");
        setAlertMessage(t("alerts.password.success"));
        setOpenChangePasswordDialog(false);
      })
      .catch((error) => {
        setLoading(false);
        Sentry.captureException(error);
        setOpenSnackbar(true);
        setSeverity("error");
        setAlertMessage(t("alerts.password.fail"));
        console.log(error.message);
      });
  };

  return (
    <Dialog
      open={openChangePasswordDialog}
      onClose={() => {
        setOpenChangePasswordDialog(false);
      }}
    >
      <CloseIcon
        fontSize="medium"
        onClick={() => {
          setOpenChangePasswordDialog(false);
        }}
        style={{ color: "red", display: "block", marginLeft: "auto", cursor: "pointer" }}
      />

      <DialogTitle>
        <MDTypography variant="h4"> {t("headings.changePassword")}</MDTypography>
        <MDTypography variant="subtitle2" sx={{ fontSize: 13 }}>
          {t('messages.profile.changePassword')}
        </MDTypography>
      </DialogTitle>

      <form onSubmit={handleSubmit((data) => changePasswordHandler(data))}>
        <DialogContent>
          <Grid container spacing={2} p={1}>
            <Grid item xs={12} xl={12}>
              <RequiredLabel>{t('formLabels.password')}</RequiredLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    sx={{ height: 50 }}
                    size="large"
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    {...field}
                    inputProps={{
                      autoComplete: "new-title",
                    }}
                    InputProps={{
                      className: "pr-2",
                      type: showPassword ? "text" : "password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} size="large">
                            <Icon className="text-20" color="action">
                              {showPassword ? "visibility" : "visibility_off"}
                            </Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                )}
                name="password"
                control={control}
              />
            </Grid>
            <Grid item xs={12} xl={12}>
              <RequiredLabel>{t('formLabels.newPassword')}</RequiredLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    sx={{ height: 50 }}
                    size="large"
                    error={!!errors.newPassword}
                    helperText={errors?.newPassword?.message}
                    {...field}
                    inputProps={{
                      autoComplete: "new-Password",
                    }}
                    InputProps={{
                      className: "pr-2",
                      type: showNewPassword ? "text" : "password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            size="large"
                          >
                            <Icon className="text-20" color="action">
                              {showNewPassword ? "visibility" : "visibility_off"}
                            </Icon>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                )}
                name="newPassword"
                control={control}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDBox display="flex" justifyContent="end">
            <Button
              type="submit"
              disabled={loading}
              style={{
                marginTop: "15px",
                color: "white",
                background: "#0AB2D6",
              }}
            >
              {loading ? (
                <CircularProgress color="white" size={20} />
              ) : (
                t("buttonTitles.changePassword")
              )}
            </Button>
          </MDBox>
          <MDBox display="flex" justifyContent="end">
            <Button
              type="button"
              style={{
                marginTop: "15px",
                color: "white",
                background: "#F6821E",
              }}
              onClick={() => {
                setOpenChangePasswordDialog(false);
              }}
            >
              {t("buttonTitles.close")}
            </Button>
          </MDBox>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangePassword;
