/**
=========================================================
* Otis Admin PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Otis Admin PRO React base styles
import colors from "assets/theme/base/colors";

// Otis Admin PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import boxShadow from "assets/theme/functions/boxShadow";

const { white, primary } = colors;

export default {
  styleOverrides: {
    root: {
      background: "#9fc9ff",
      fill: "#9fc9ff",
      stroke: "#9fc9ff",
      strokeWidth: pxToRem(10),
      width: pxToRem(13),
      height: pxToRem(13),
      borderRadius: "50%",
      zIndex: 99,
      transition: "all 200ms linear",

      "&.Mui-active": {
        background: primary.main,
        fill: primary.main,
        stroke: primary.main,
        borderColor: primary.main,
        boxShadow: boxShadow([0, 0], [0, 2], primary.main, 1),
      },

      "&.Mui-completed": {
        background: primary.main,
        fill: primary.main,
        stroke: primary.main,
        borderColor: primary.main,
        boxShadow: boxShadow([0, 0], [0, 2], primary.main, 1),
      },
    },
  },
};
