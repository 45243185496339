import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import '@react-pdf-viewer/core/lib/styles/index.css';
import PaymentTermsPDF from "../../assets/pdf/Payment_Terms.pdf";

export function PaymentTerms() {
  return (
    <div style={{ width: "100%", height: "100vh", overflow: "auto" }}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
        <Viewer fileUrl={PaymentTermsPDF} />
      </Worker>
    </div>
  );
}
