import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import MDInput from "components/MDInput";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { call } from "services/axiosService/core";
import { useMaterialUIController } from "context";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const RegenerateInsightsButton = ({ id, RegenerateInsightsHandler }) => {
//   return (
//     <Tooltip fontSize="large" title={<h2 style={{}}>Regenerate Insights</h2>}>
//       <MDTypography
//         variant="body1"
//         color="secondary"
//         lineHeight={1}
//         sx={{ cursor: "pointer", mx: 1 }}
//       >
//         <Icon
//           fontSize="medium"
//           color="info"
//           onClick={() => {
//             RegenerateInsightsHandler(id);
//           }}
//         >
//           insights
//         </Icon>
//       </MDTypography>
//     </Tooltip>
//   );
// };

const DeleteAssessmentButton = ({ id, DeleteAssessmentHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Delete Assessment</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="error"
          onClick={() => {
            DeleteAssessmentHandler(id);
          }}
        >
          delete
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

export function AssessmentsTable(props) {
  const [selectedId, setSelectedId] = useState("");
  const [selectedAssessmentDetails, setSelectedAssessmentDetails] = useState("");
  const [openViewDialog, setOpenViewDialog] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  // const [openRegenerateInsightsDialog, setOpenRegenerateInsightsDialog] = useState(false);
  // const [regenerateinsightsAssessmentId, setRegenerateinsightsAssessmentId] = useState("");
  const [deleteAssessmentId, setDeleteAssessmentId] = useState("");
  const [deleteAssessmentDetails, setDeleteAssessmentDetails] = useState("");
  // const [regenerateInsightDetails, setRegenerateInsightDetails] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [sessionExpired, setSessionExpired] = useState(false);
  const [controller] = useMaterialUIController();
  const { profile } = controller;

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const CloseViewDialogHandler = () => {
    setOpenViewDialog(false);
    setSelectedId("");
  };

  const DeleteAssessmentHandler = (id) => {
    const assessment = props.assessments.filter((row) => {
      return row.id == id;
    });
    setDeleteAssessmentDetails(assessment[0]);
    setDeleteAssessmentId(id);
    setOpenDeleteDialog(true);
  };

  // const RegenerateInsightsHandler = (id) => {
  //   const assessment = props.assessments.filter((row) => {
  //     return row.id == id;
  //   });
  //   setRegenerateInsightDetails(assessment[0]);
  //   setRegenerateinsightsAssessmentId(id);
  //   setOpenRegenerateInsightsDialog(true);
  // };

  const CloseDeleteDialogHandler = () => {
    setOpenDeleteDialog(false);
    setSelectedId("");
  };

  // const CloseRegenerateInsightsDialogHandler = () => {
  //   setOpenRegenerateInsightsDialog(false);
  //   setSelectedId("");
  // };

  useEffect(() => {
    if (!openDeleteDialog) {
      if (selectedId) {
        const assessment = props.assessments.filter((row) => {
          return row.id == selectedId;
        });
        setSelectedAssessmentDetails(assessment[0]);

        setOpenViewDialog(true);
      }
    }
  }, [selectedId]);

  const tableData = {
    columns: [
      { Header: "#", accessor: "num", width: "5%" },
      { Header: "PrimaryKey", accessor: "pk", width: "10%" },
      { Header: "SortKey", accessor: "sk", width: "40%" },
      { Header: "AssignedDate", accessor: "assigned_date", width: "20%" },
      { Header: "CompletionDate", accessor: "completion_date", width: "20%" },
      { Header: "status", accessor: "status" },
      { Header: "Actions", accessor: "actions", width: "10%" },
    ],

    rows: props.assessments,
  };

  tableData.rows = tableData.rows.map((row, index) => ({
    ...row,
    num: index + 1,
    actions: (
      <div style={{ display: "flex" }}>
        <DeleteAssessmentButton id={row.id} DeleteAssessmentHandler={DeleteAssessmentHandler} />
        {/* {row.status == "ready" && (
          <RegenerateInsightsButton
            id={row.id}
            RegenerateInsightsHandler={RegenerateInsightsHandler}
          />
        )} */}
      </div>
    ),
  }));
  return (
    <>
      <DataTable
        table={tableData}
        setSelectedId={setSelectedId}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
      {openViewDialog && (
        <ViewAssessmentDataDialog
          openViewDialog={openViewDialog}
          CloseViewDialogHandler={CloseViewDialogHandler}
          selectedAssessmentDetails={selectedAssessmentDetails}
          loadAssessments={props.loadAssessments}
          setSessionExpired={setSessionExpired}
        />
      )}
      {openDeleteDialog && (
        <DeleteAssessmentDialog
          openDeleteDialog={openDeleteDialog}
          CloseDeleteDialogHandler={CloseDeleteDialogHandler}
          deleteAssessmentDetails={deleteAssessmentDetails}
          loadAssessments={props.loadAssessments}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSessionExpired={setSessionExpired}
        />
      )}
      {/* {openRegenerateInsightsDialog && (
        <RegenerateInsightsDialog
          openRegenerateInsightsDialog={openRegenerateInsightsDialog}
          CloseRegenerateInsightsDialogHandler={CloseRegenerateInsightsDialogHandler}
          regenerateInsightDetails={regenerateInsightDetails}
          loadAssessments={props.loadAssessments}
          profile={profile}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
        />
      )} */}
      {sessionExpired && <Refresh />}
      <Snackbar
        autoHideDuration={2000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

const ViewAssessmentDataDialog = ({
  openViewDialog,
  CloseViewDialogHandler,
  selectedAssessmentDetails,
  loadAssessments,
  setSessionExpired,
}) => {
  const [details, setDetails] = useState(JSON.stringify(selectedAssessmentDetails, null, 3));
  const [validJson, setValidJson] = useState(true);

  const updateAssessmentHandler = () => {
    const data = JSON.parse(details);
    call(`users/${data.user_id}/assessments/${data.id}`, "PUT", { body: data })
      .then((res) => {
        if (res.status == 200) {
          CloseViewDialogHandler();
          loadAssessments();
        } else if (res.status == 440) {
          setSessionExpired(true);
          CloseViewDialogHandler();
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        CloseViewDialogHandler();
      });
  };

  return (
    <Dialog open={openViewDialog} onClose={CloseViewDialogHandler} fullWidth={true} maxWidth={"md"}>
      <DialogTitle> Assessment Details </DialogTitle>
      <DialogContent>
        <MDBox p={1} lineHeight={1}>
          <MDInput
            value={details}
            error={!validJson}
            multiline
            name="details"
            type="text"
            fullWidth
            required
            onChange={(e) => {
              setDetails(e.target.value);
              try {
                JSON.parse(e.target.value);
                setValidJson(true);
              } catch (error) {
                setValidJson(false);
              }
            }}
          />
        </MDBox>
        {!validJson && (
          <MDTypography
            textAlign="center"
            variant="h6"
            color="error"
            lineHeight={1}
            sx={{ cursor: "pointer", mx: 1 }}
          >
            Data is not a valid Json
          </MDTypography>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          disabled={!validJson}
          onClick={updateAssessmentHandler}
          style={{ color: "white", background: "#0AB2D6" }}
        >
          Save
        </Button>

        <Button onClick={CloseViewDialogHandler} style={{ color: "white", background: "#ED5F72" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteAssessmentDialog = ({
  openDeleteDialog,
  CloseDeleteDialogHandler,
  deleteAssessmentDetails,
  loadAssessments,
  setOpenSnackbar,
  setAlertMessage,
  setSessionExpired,
}) => {
  const deleteAssessment = () => {
    call(
      `users/${deleteAssessmentDetails.user_id}/assessments/${deleteAssessmentDetails.id}`,
      "DELETE",
      {}
    )
      .then((res) => {
        if (res.status == 200) {
          CloseDeleteDialogHandler();
          loadAssessments();
          setOpenSnackbar(true);
          setAlertMessage("Assessment deleted duccessfully");
        } else if (res.status == 440) {
          setSessionExpired(true);
          CloseDeleteDialogHandler();
        } else {
          CloseDeleteDialogHandler();
          loadAssessments();
          setOpenSnackbar(true);
          setAlertMessage("Error while deleting assessment");
        }
      })
      .catch((err) => {
        CloseDeleteDialogHandler();
        Sentry.captureException(err);
      });
  };

  return (
    <Dialog open={openDeleteDialog} onClose={CloseDeleteDialogHandler} maxWidth={"md"}>
      <DialogTitle> Delete Assessment </DialogTitle>
      <DialogContent>Are you Sure you want to Delete this Assessment ?</DialogContent>

      <DialogActions>
        <Button onClick={deleteAssessment} style={{ color: "white", background: "#0AB2D6" }}>
          Delete
        </Button>
        <Button
          onClick={CloseDeleteDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// const RegenerateInsightsDialog = ({
//   openRegenerateInsightsDialog,
//   CloseRegenerateInsightsDialogHandler,
//   regenerateInsightDetails,
//   loadAssessments,
//   profile,
//   setOpenSnackbar,
//   setAlertMessage,
// }) => {
//   const regenerateInsights = () => {
//     CloseRegenerateInsightsDialogHandler();
//     let data = {
//       user_id: regenerateInsightDetails.user_id,
//       assessment_id: regenerateInsightDetails.id,
//     };
//     call(`users/${profile.id}/insights`, "PUT", { body: data })
//       .then((res) => {
//         if (res.status == 200) {
//           CloseRegenerateInsightsDialogHandler();
//           loadAssessments();
//           setOpenSnackbar(true);
//           setAlertMessage("Insights regenerated successfully");
//         } else {
//           setOpenSnackbar(true);
//           setAlertMessage("Error while regenerating insights");
//         }
//       })
//       .catch((err) => {
//         CloseRegenerateInsightsDialogHandler();
//         setOpenSnackbar(true);
//         setAlertMessage("Error while regenerating insights");
//       });
//   };

//   return (
//     <Dialog
//       open={openRegenerateInsightsDialog}
//       onClose={CloseRegenerateInsightsDialogHandler}
//       maxWidth={"md"}
//     >
//       <DialogTitle> Regenerate Insights </DialogTitle>
//       <DialogContent>
//         Are you Sure you want to Regenerate insights for this Assessment?
//       </DialogContent>

//       <DialogActions>
//         <Button onClick={regenerateInsights} style={{ color: "white", background: "#0AB2D6" }}>
//           Regenrate
//         </Button>
//         <Button
//           onClick={CloseRegenerateInsightsDialogHandler}
//           style={{ color: "white", background: "#ED5F72" }}
//         >
//           Close
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };
