import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import MDInput from "components/MDInput";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { call } from "services/axiosService/core";
import Refresh from "pages/refresh/Refresh";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import MDButton from "components/MDButton";
import * as Sentry from "@sentry/browser";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeleteVendorButton = ({ id, DeleteVendorHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Delete Vendor</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="error"
          onClick={() => {
            DeleteVendorHandler(id);
          }}
        >
          delete
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

export function VendorsTable(props) {
  const [selectedId, setSelectedId] = useState("");
  const [selectedVendorDetails, setSelectedVendorDetails] = useState("");
  const [openViewDialog, setOpenViewDialog] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteVendorId, setDeleteVendorId] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [sessionExpired, setSessionExpired] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const CloseViewDialogHandler = () => {
    setOpenViewDialog(false);
    setSelectedId("");
  };

  const DeleteVendorHandler = (id) => {
    setDeleteVendorId(id);
    setOpenDeleteDialog(true);
  };

  const CloseDeleteDialogHandler = () => {
    setOpenDeleteDialog(false);
    setSelectedId("");
  };

  useEffect(() => {
    if (!openDeleteDialog) {
      if (selectedId) {
        const vendor = props.vendors.filter((row) => {
          return row.id == selectedId;
        });
        setSelectedVendorDetails(vendor[0]);
        setOpenViewDialog(true);
      }
    }
  }, [selectedId]);

  const tableData = {
    columns: [
      { Header: "#", accessor: "num", width: "10%" },
      { Header: "Name", accessor: "vendor_name", width: "25%" },
      { Header: "email", accessor: "vendor_email", width: "20%" },
      { Header: "APIKEY", accessor: "vendor_api_key", width: "35%" },
      { Header: "Actions", accessor: "actions", width: "10%" },
    ],
    rows: props.vendors,
  };

  tableData.rows = tableData.rows.map((row, index) => ({
    ...row,
    num: index + 1,
    actions: (
      <div style={{ display: "flex" }}>
        <DeleteVendorButton id={row.id} DeleteVendorHandler={DeleteVendorHandler} />
      </div>
    ),
  }));

  return(
    <>
  <DataTable table={tableData} setSelectedId={setSelectedId} pageNumber={pageNumber} setPageNumber={setPageNumber}/>
  {openViewDialog && (
    <ViewVendorDataDialog
      openViewDialog={openViewDialog}
      CloseViewDialogHandler={CloseViewDialogHandler}
      selectedVendorDetails={selectedVendorDetails}
      loadVendors={props.loadVendors}
      setOpenSnackbar={setOpenSnackbar}
      setAlertMessage={setAlertMessage}
      setSessionExpired={setSessionExpired}
    />
  )}
  {openDeleteDialog && (
    <DeleteVendorDialog
      openDeleteDialog={openDeleteDialog}
      CloseDeleteDialogHandler={CloseDeleteDialogHandler}
      deleteVendorId={deleteVendorId}
      loadVendors={props.loadVendors}
      setOpenSnackbar={setOpenSnackbar}
      setAlertMessage={setAlertMessage}
      setSessionExpired={setSessionExpired}
    />
  )}
   <Snackbar
    autoHideDuration={2000}
    open={openSnackbar}
    onClose={handleCloseSnackbar}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
  >
    <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: "100%" }}>
      {alertMessage}
    </Alert>
  </Snackbar>
  {sessionExpired && (
    <Refresh/>
  )}
</>

)

}

const ViewVendorDataDialog = ({
  openViewDialog,
  CloseViewDialogHandler,
  selectedVendorDetails,
  loadVendors,
  setOpenSnackbar,
  setAlertMessage,
  setSessionExpired
}) => {
  const [details, setDetails] = useState(JSON.stringify(selectedVendorDetails, null, 3));
  const [validJson, setValidJson] = useState(true);

  const updateVendorHandler = () => {
    const data = JSON.parse(details);
    call(`vendors/${data.id}`, "PUT", { body: data })
      .then((res) => {
        if (res.status == 200) {
          CloseViewDialogHandler();
          loadVendors();
          setOpenSnackbar(true)
          setAlertMessage("Updated successfully")
        }
        else if(res.status == 440){
          CloseViewDialogHandler();
          setSessionExpired(true)
        }
        else{
          setOpenSnackbar(true)
          setAlertMessage(res.data.message)
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        CloseViewDialogHandler();
      });
  };

  return (
    <Dialog open={openViewDialog} onClose={CloseViewDialogHandler} fullWidth={true} maxWidth={"md"}>
      <DialogTitle> Vendor Details </DialogTitle>
      <DialogContent>
        <MDBox p={1} lineHeight={1}>
          <MDInput
            value={details}
            error={!validJson}
            multiline
            name="details"
            type="text"
            fullWidth
            required
            onChange={(e) => {
              setDetails(e.target.value);
              try {
                JSON.parse(e.target.value);
                setValidJson(true);
              } catch (error) {
                setValidJson(false);
              }
            }}
          />
        </MDBox>
        {!validJson && (
          <MDTypography
            textAlign="center"
            variant="h6"
            color="error"
            lineHeight={1}
            sx={{ cursor: "pointer", mx: 1 }}
          >
            Data is not a valid Json
          </MDTypography>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          disabled={!validJson}
          onClick={updateVendorHandler}
          style={{ color: "white", background: "#0AB2D6" }}
        >
          Save
        </Button>
        <Button onClick={CloseViewDialogHandler} style={{ color: "white", background: "#ED5F72" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteVendorDialog = ({
  openDeleteDialog,
  CloseDeleteDialogHandler,
  deleteVendorId,
  loadVendors,
  setOpenSnackbar,
  setAlertMessage,
  setSessionExpired
}) => {
  const deleteVendor = () => {
    call(`vendors/${deleteVendorId}`, "DELETE", {})
      .then((res) => {
        if (res.status == 200) {
          CloseDeleteDialogHandler();
          loadVendors();
          setOpenSnackbar(true)
          setAlertMessage("Deleted successfully")
        }
        else if(res.status == 440){
          setSessionExpired(true)
          CloseDeleteDialogHandler();
        }
        else{
          setOpenSnackbar(true)
          setAlertMessage("Error while deleting the Vendor")
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        CloseDeleteDialogHandler();
      });
  };

  return (
    <Dialog open={openDeleteDialog} onClose={CloseDeleteDialogHandler} maxWidth={"md"}>
      <DialogTitle> Delete Vendor </DialogTitle>
      <DialogContent>Are you Sure you want to Delete this Vendor ?</DialogContent>

      <DialogActions>
        <Button onClick={deleteVendor} style={{ color: "white", background: "#0AB2D6" }}>
          Delete
        </Button>
        <Button
          onClick={CloseDeleteDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
