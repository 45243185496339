import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FaqData from "./faqData";
import BestPracticesData from "./bestPracticesData";
import { useTranslation } from "react-i18next";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Faq = () => {
  const {t} = useTranslation()
  const [expanded, setExpanded] = React.useState("");
  const [expandedPractices, setExpandedPractices] = React.useState("");
  const bestPracticesData = BestPracticesData();
  const faqData = FaqData();
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangePractices = (panel) => (event, newExpanded) => {
    setExpandedPractices(newExpanded ? panel : false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title={t("pageTitles.faqs")} />
      <MDBox
        sx={{
          padding: "24px 30px",
          backgroundColor: "#fff",
          borderRadius: "14px",
          marginBottom: "10px",
        }}
      >
        <MDTypography variant="h6" marginBottom={2.5}>
        {t("headings.bestPractices")}
        </MDTypography>
        <MDBox>
          {bestPracticesData.map((item, index) => {
            return (
              <Accordion
                sx={{ marginBottom: "8px" }}
                expandedPractices={expandedPractices === `panel${index}`}
                onChange={handleChangePractices(`panel${index}`)}
              >
                <AccordionSummary
                  style={{ height: "65px" }}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography sx={{ fontSize: "16px", color: "#344767" }}>
                    {item.persona}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MDBox paddingLeft={3} component="ul" style={{ listStyleType: "none" }}>
                    {item.description && (
                      <MDTypography component="h5" variant="caption" fontSize={15} marginBottom={1}>
                        {item.description}
                      </MDTypography>
                    )}
                    {item.bestPractice.map((ans) => {
                      return (
                        <MDTypography
                          component="h6"
                          variant="caption"
                          fontSize={15}
                          marginBottom={1}
                        >
                          {ans}
                        </MDTypography>
                      );
                    })}
                  </MDBox>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </MDBox>
      </MDBox>

      <MDBox sx={{ padding: "24px 30px", backgroundColor: "#fff", borderRadius: "14px" }}>
        <MDTypography variant="h6" marginBottom={2.5}>
          {t("headings.faqs")}
        </MDTypography>
        <MDBox>
          {faqData.map((item, index) => {
            return (
              <Accordion
                sx={{ marginBottom: "8px" }}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  style={{ height: "65px" }}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography sx={{ fontSize: "16px", color: "#344767" }}>
                    {item.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MDBox paddingLeft={3} component="ul" style={{ listStyleType: "circle" }}>
                    {item.answer.map((ans) => {
                      return (
                        <MDTypography
                          component="li"
                          variant="caption"
                          fontSize={15}
                          marginBottom={1}
                        >
                          {ans}
                        </MDTypography>
                      );
                    })}
                  </MDBox>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
};

export default Faq;
