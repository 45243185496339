// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
import { orange } from "@mui/material/colors";
import { StarBorderPurple500TwoTone } from "@mui/icons-material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useTranslation } from "react-i18next";

function AchievementCard({ logo, name, description, progress }) {
  const {t} = useTranslation()
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        // boxShadow: "1px 2px 9px #49a3f1",
        padding: "0.25em",
        overflow: "visible",
        height: "100%",
      }}
    >
      <MDBox position="relative" flex={1} width="100.25%" borderRadius="xl">
        <CardMedia
          src={logo}
          component="img"
          title={name}
          height="80%"
          sx={{
            maxWidth: "100%",
            margin: "auto",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </MDBox>

      <MDBox mt={1} mx={0.5}>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography textAlign="center" variant="h4" fontWeight="medium" color="text">
            {name}
          </MDTypography>
        </MDBox>

        <MDBox mb={2} lineHeight={0}>
          <MDTypography textAlign="center" variant="h6" fontWeight="bold" color="text">
            {description}
          </MDTypography>
        </MDBox>
      </MDBox>
      {progress ? (
        <MDBox pt={2} pb={1} px={3} textAlign="center">
          <MDBox px={2} mb={2} sx={{ width: 1, display: "flex", flexDirection: "row" }}>
            <MDBox sx={{ width: 1 }}>
              <MDProgress value={Math.round(progress)} label={true} direction={"column-reverse"} />
            </MDBox>
            <MDBox sx={{ mt: -2 }}>
              {progress == 100 && <EmojiEventsIcon fontSize="large" sx={{ color: "#FFD700" }} />}
            </MDBox>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox pt={2} pb={1} px={1} textAlign="center">
          <MDBox px={2} mb={2} sx={{ width: 1, display: "flex", flexDirection: "row" }}>
            <MDBox sx={{ width: 1 }}>
              <Card style={{ backgroundColor: "#49a3f1", padding: 2, opacity: 0.8 }}>
                <MDBox display="flex" flexDirection="column">
                  <MDTypography
                    textAlign="center"
                    justifyContent="center"
                    component="h3"
                    fontWeight="medium"
                    color="white"
                    flexWrap="wrap"
                  >
                    {t("achievements.locked")}
                  </MDTypography>
                </MDBox>
              </Card>
            </MDBox>
          </MDBox>
        </MDBox>
      )}
    </Card>
  );
}

AchievementCard.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  progress: PropTypes.number,
};

export default AchievementCard;
