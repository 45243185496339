import axios from "axios";
import { BASE_URL } from "./config";
import CryptoJS from "crypto-js";
import { Buffer } from "buffer";
import * as Sentry from "@sentry/browser";

// const authorizationInterceptor = axios.interceptors.request.use((config) => {
//   const session = JSON.parse(localStorage.getItem("session"))

//   if (session.accessToken && session.expire > Date.now()) {
//     config.headers.Authorization = `Token ${session.accessToken}`;
//   }
//   return config;
// });

// const errorInterceptor = axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response && error.response.data.message) {
//       return Promise.reject({
//         code: error.response.status,
//         message: error.response.data.message,
//       });
//     } else if (error.response) {
//       return Promise.reject({
//         code: error.response.status,
//         message: `An error has occured (code ${error.response.status})`,
//       });
//     } else if (error.request) {
//       let json = error.toJSON();
//       return Promise.reject({
//         code: json.code,
//         message: json.message,
//       });
//     } else {
//       return Promise.reject({
//         code: undefined,
//         message: error.message,
//       });
//     }
//   }
// );

const toBase64 = (obj) => {
  // converts the obj to a string
  const str = JSON.stringify(obj);
  // returns string converted to base64

  // return CryptoJS.enc.Base64.stringify(obj)
  return Buffer.from(str).toString("base64");
  //Buffer.from('your string here').toString('base64')
};

const replaceSpecialChars = (b64string) => {
  // create a regex to match any of the characters =,+ or / and replace them with their // substitutes
  return b64string.replace(/[=+/]/g, (charToBeReplaced) => {
    switch (charToBeReplaced) {
      case "=":
        return "";
      case "+":
        return "-";
      case "/":
        return "_";
    }
  });
};

const createSignature = (jwtB64Header, jwtB64Payload, secret) => {
  // create a HMAC(hash based message authentication code) using sha256 hashing alg

  let signature = CryptoJS.HmacSHA256(jwtB64Header + "." + jwtB64Payload, secret).toString(
    CryptoJS.enc.Base64
  );

  //  let signature =  CryptoJS.createHmac('sha256', secret);

  // let signature = CryptoJS
  //     .createHmac('sha256', secret)
  //     .update(jwtB64Header + '.' + jwtB64Payload)
  //     .digest('base64');

  // use the update method to hash a string formed from our jwtB64Header a period and
  //jwtB64Payload
  //   signature.update (jwtB64Header + '.' + jwtB64Payload);

  //signature needs to be converted to base64 to make it usable
  //   signature = signature.digest ('base64');

  //of course we need to clean the base64 string of URL special characters
  signature = replaceSpecialChars(signature);
  return signature;
};

const generateJWTToken = () => {
  let session = JSON.parse(localStorage.getItem("session"));
  const header = {
    alg: "HS256",
    typ: "JWT",
  };
  const b64Header = toBase64(header);
  const jwtB64Header = replaceSpecialChars(b64Header);

  const payload = {
    iss: session.iss, //information about the server that issued the token
    exp: session.expire, // tokens expiry date in milliseconds
    user_id: session.userName,
    // information about some random user
  };
  // converts payload to base64
  const b64Payload = toBase64(payload);
  const jwtB64Payload = replaceSpecialChars(b64Payload);

  const secret = "";
  const signature = createSignature(jwtB64Header, jwtB64Payload, secret);
  const jsonWebToken = jwtB64Header + "." + jwtB64Payload + "." + signature;
  return jsonWebToken;
};

export const call = (path: string, method: string, request: Request) => {
  return callRaw(path, method, request)
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      console.log("API ERROR RESPONSE IS", error);
      return error.response;
    });
};

export const callRaw = (path: string, method: string, request: Request) => {
  let session = JSON.parse(localStorage.getItem("session"));
  const localLang = localStorage.getItem("i18nextLng") || "en";
  let localLangCode = localLang.split("-")[0];
  const supportedLanguages = ["en", "es", "it", "pt", "fr", "de"];
  if (!supportedLanguages.includes(localLangCode)) {
    localLangCode = "en";
  }
  return axios({
    method,
    url: `${BASE_URL}${path}`,
    headers: {
      Accept: "application/json",
      "i18n-Language": localLangCode,
      AccessControlAllowOrigin: "*",
      AccessControlAllowMethods: "PUT,GET,POST,DELETE,OPTIONS",
      token: generateJWTToken(),
      Authorization: "Bearer " + session.jwtToken,
      ...request.headers,
    },
    data: request.body,
    params: request.params,
    responseType: request.responseType,
  });
};
