import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import TuneIcon from "@mui/icons-material/Tune";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Tooltip from "@mui/material/Tooltip";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Otis Admin PRO React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Button, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  hasFilters,
  filters,
  setOpenFilterModel,
  setSelectedId,
  loader,
  noData,
  pageNumber,
  setPageNumber,
  clickHandler,
}) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [filterValue, setFilterValue] = useState(null);
  const [filterTableData, setFilterTableData] = useState([]);

  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  // const [filterName, setFilterName] = useState([]);
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];

  const getFilteredRows = (table) => {
    if (filterValue == null) {
      return table.rows;
    } else {
      let filterdRows = [];
      filterValue.members.map((member) => {
        if (table.rows.filter((row) => row.learner_id == member) == undefined) {
          console.log("undefined");
        } else {
          filterdRows.push(...table.rows.filter((row) => row.learner_id == member));
        }
      });
      return filterdRows;
    }
  };

  const clearFilterHanlder = (table) => {
    setFilterValue(null);
  };

  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => getFilteredRows(table), [table, filterValue]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: pageNumber }, autoResetSortBy: false },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;
    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd =
      rows.length > pageSize * (pageIndex + 1) ? pageSize * (pageIndex + 1) : rows.length;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const navigate = useNavigate();
  const {t} = useTranslation()

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        {rows.length > 0 && (entriesPerPage || canSearch) ? (
          <>
            {entriesPerPage && (
              <MDBox display="flex" tabIndex={0} aria-label="Entries per page">
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={entries}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                  }}
                  size="small"
                  sx={{ width: "5rem" }}
                  renderInput={(params) => <MDInput {...params} />}
                />
                <MDTypography variant="caption" color="black" m={1}>
                 &nbsp;&nbsp;{t("pagination.entriesPerPage")}
                </MDTypography>
              </MDBox>
            )}

            {canSearch && (
              <MDBox width="12rem" ml="auto" tabIndex={0} aria-label="Search">
                <MDInput
                  placeholder="Search..."
                  value={search}
                  size="small"
                  fullWidth
                  onChange={({ currentTarget }) => {
                    setSearch(search);
                    onSearchChange(currentTarget.value);
                  }}
                />
              </MDBox>
            )}
          </>
        ) : null}
        {hasFilters && (
          <MDBox width="30rem" alignItems="center" m={3} tabIndex={0} aria-label="Select Filters">
            <Autocomplete
              id="filters"
              size="small"
              options={filters}
              value={filterValue}
              onChange={(event, newValue) => {
                setFilterValue(newValue);
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              style={{ width: 200 }}
              renderInput={(params) => (
                <TextField {...params} label={t("formLabels.filters")} placeholder={t("formPlaceHolders.filters")} />
              )}
            />
          </MDBox>
        )}

        {hasFilters && (
          <MDBox
            width="12rem"
            alignItems="center"
            m={3}
            tabIndex={0}
            aria-label={t("toolTips.clearFilters")}
            role="button"
            onKeyDown={(event) => {
              // Check if Enter key is pressed (key code 13)
              if (event.keyCode === 13) {
                clearFilterHanlder();
              }
            }}
          >
            <Tooltip title={t("toolTips.clearFilters")}>
              <FilterAltOffIcon
                color="red"
                cursor="pointer"
                style={{ height: 25, width: 25 }}
                onClick={() => {
                  clearFilterHanlder();
                }}
              />
            </Tooltip>
          </MDBox>
        )}

        {hasFilters && (
          <MDBox
            width="12rem"
            textAlign={"right"}
            m={3}
            tabIndex={0}
            aria-label={t("toolTips.showFilters")}
            onKeyDown={(event) => {
              // Check if Enter key is pressed (key code 13)
              if (event.keyCode === 13) {
                setOpenFilterModel(true);
              }
            }}
            role="button"
          >
            <Tooltip title={t("toolTips.showFilters")}>
              <TuneIcon
                cursor="pointer"
                style={{ height: 25, width: 25 }}
                onClick={() => {
                  setOpenFilterModel(true);
                }}
              />
            </Tooltip>
          </MDBox>
        )}
      </MDBox>

      <Table {...getTableProps()} aria-label="table" tabIndex={0}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} aria-label="table-header" tabIndex={0}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        {rows.length > 0 && (
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow
                  aria-label="table-Row"
                  tabIndex={0}
                  hover={true}
                  onClick={() => {
                    setSelectedId(row.original.id);
                    setPageNumber(pageIndex);
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>

      {rows.length == 0 && !loader && (
        <MDTypography
          variant="h6"
          padding={4}
          color="balck"
          fontWeight="regular"
          textAlign="center"
        >
          {!noData && (
            <MDTypography variant="h6" color="black" fontWeight="regular" textAlign="center">
              {t("noData.noAvailableData")}
            </MDTypography>
          )}

          {noData && (
            <MDTypography variant="h6" color="black" fontWeight="regular" textAlign="center">
              {t("noData.noLearners")}{" "}
              <Button sx={{ padding: "0" }} onClick={clickHandler}>
              {t("noData.clickHere")}
              </Button>{" "}
              {t("noData.addLearner")}
            </MDTypography>
          )}
        </MDTypography>
      )}

      {loader ? (
        <MDBox display="flex" justifyContent="center" padding={4}>
          <CircularProgress size={35} />
        </MDBox>
      ) : null}

      {rows.length > 0 && (
        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          position="sticky"
          left={20}
          right={20}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography variant="button" color="black" fontWeight="regular">
              {t("pagination.showingEntries", {
        entriesStart,
        entriesEnd,
        totalEntries: rows.length
      })}
              </MDTypography>
            </MDBox>
          )}
          {pageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {canPreviousPage && (
                <MDPagination item onClick={() => previousPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination.length > 6 ? (
                <MDBox width="5rem" mx={1}>
                  <MDInput
                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                    value={customizedPageOptions[pageIndex]}
                    onChange={(handleInputPagination, handleInputPaginationValue)}
                  />
                </MDBox>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <MDPagination item onClick={() => nextPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
        </MDBox>
      )}
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  hasFilters: false,
  filters: [],
  setSelectedId: () => {},
  noData: false,
  pageNumber: 0,
  setPageNumber: () => {},
  clickHandler: () => {},
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.instanceOf(Array),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.instanceOf(Object).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  hasFilters: PropTypes.bool,
  filters: PropTypes.array,
  setOpenFilterModel: PropTypes.func,
  setSelectedId: PropTypes.func,
  noData: PropTypes.bool,
  pageNumber: PropTypes.number,
  setPageNumber: PropTypes.func,
  clickHandler: PropTypes.func,
};

export default DataTable;
