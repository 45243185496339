import React, { useState, useEffect } from "react";
import SendIcon from "@mui/icons-material/Send";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import MarkChatUnreadOutlinedIcon from "@mui/icons-material/MarkChatUnreadOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "@mui/material";
import { call } from "services/axiosService/core";
import * as Sentry from "@sentry/browser";
import MaxuLogo from "assets/images/nav-logo.png";
import BotLogo from "assets/images/bot-logo.png";
import "./Bot.css"; // Import CSS for styling
import { useTranslation } from "react-i18next";

const Bot = ({
  showUnreadChat,
  setShowUnreadChat,
  showTodayContent,
  todayContent,
  chatQuestions,
  showIntro,
  userMetricsData,
  setUserMetricsData,
  setSessionExpired,
  profile,
}) => {
  const {t} = useTranslation()
  const [expanded, setExpanded] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showThankYou, setShowThankYou] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [showTyping, setShowTyping] = useState(true);
  const [showContent, setShowContent] = useState(showTodayContent);
  const [content, setContent] = useState(todayContent);
  const [questions, setQuestions] = useState([...chatQuestions]);
  const [showIntroductoryMessage, setShowIntroductoryMessage] = useState(showIntro);

  useEffect(() => {
    let timer;
    if (showTyping) {
      timer = setTimeout(() => {
        setShowTyping(false);
      }, 2500); // Adjust typing delay (milliseconds)
    }
    return () => clearTimeout(timer);
  }, [showTyping, currentQuestionIndex]);

  const toggleChatbot = () => {
    // first time user open the chatbot, we will stop showing the unread message for the rest of the day
    if (showUnreadChat) {
      localStorage.setItem("lastBotViewed", new Date().toLocaleDateString());
      setShowUnreadChat(false);
    }
    if (showIntroductoryMessage) {
      localStorage.setItem("IntroMessageViewed", new Date().toLocaleDateString());
      setShowIntroductoryMessage(false);
    }
    setExpanded(!expanded);
  };

  const updateChatData = (data, other) => {
    data.other_responses.push(other);
    delete data.userResponse;

    call(`chats/${data.id}`, "PUT", { body: data })
      .then((res) => {
        if (res.status == 200) {
          console.log("other response updated successfully");
        } else if (res.status == 440) {
          setSessionExpired(true);
        } else {
          console.log("error while updating other response");
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  const getDailyContent = () => {
    window.pendo.track("Getting Maxwell daily recommendations");
    let data = [];
    questions.map((item) => {
      data.push({ question: item.question, userResponse: item.userResponse });
    });

    call(`chats/${profile.id}/content`, "POST", { body: data })
      .then((res) => {
        if (res.status == 200) {
          localStorage.setItem("lastContentUpdatedDate", new Date().toLocaleDateString());
          localStorage.setItem("dailyContent", JSON.stringify(res.data));
          setShowContent(true);
          setContent([...res.data]);
        } else if (res.status == 440) {
          setSessionExpired(true);
        } else {
          console.log("error while getting daily content");
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  };

  const handleAnswerSelection = (answer) => {
    // If "Other" option is selected, show input field
    if (answer === "Other") {
      const updatedQuestions = [...questions];
      updatedQuestions[currentQuestionIndex].userResponse = "Other";
      setQuestions(updatedQuestions);
    } else {
      setShowTyping(true);
      // Update user response for current question
      const updatedQuestions = [...questions];
      updatedQuestions[currentQuestionIndex].userResponse = answer;
      setQuestions(updatedQuestions);
      // Move to the next question or show thank you message
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        getDailyContent();
        setShowThankYou(true);
      }
    }
  };

  // Function to handle user input change
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  // Function to handle user submission
  const handleSubmit = () => {
    // Update user response for current question
    if (userInput.trim()) {
      updateChatData(questions[currentQuestionIndex], userInput);
      setShowTyping(true);
      const updatedQuestions = [...questions];
      updatedQuestions[currentQuestionIndex].userResponse = userInput;
      setQuestions(updatedQuestions);

      // Move to the next question or show thank you message
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        getDailyContent();
        setShowThankYou(true);
      }
      // Clear input after submission
      setUserInput("");
    }
  };

  const getWelcomeMessage = () => {
    return (
      <div className="chat">
        <div className="chatbot-message">{t("bot.intro")}</div>
        <div className="chatbot-message">
        {t("bot.chatWithUs")}
        </div>
      </div>
    );
  };

  const renderThankYouMessage = () => {
    return (
      <div className="chat">
        <div className="chatbot-message"> {t("bot.thanks")}</div>
        <div className="chatbot-message">
        {t("bot.findingContent")}{" "}
        </div>
      </div>
    );
  };

  const renderContentMessage = () => {
    return (
      <div className="chat">
        <div className="chatbot-message">
        {t("bot.topContent")}
        </div>
      </div>
    );
  };

  const IntroductoryMessage = ({ onClose }) => {
    return (
      <div className="introductory-message">
        <IconButton onClick={onClose} className="close-icon" size="large">
          <CancelIcon />
        </IconButton>
        <p>
        {t("bot.welcome")}
        </p>
        <hr className="intro-hr" />
        <div className="intro-chat" onClick={toggleChatbot}>
        {t("bot.chatNow")}
        </div>
      </div>
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const contentViewedHandler = (title) => {
    let viewedcontent = [...userMetricsData.viewedcontent];
    if (viewedcontent.includes(title)) {
      userMetricsData["eventkey"] = { event: "viewcontent", value: title };
    } else {
      userMetricsData["viewedcontent"] = [...viewedcontent, title];
      userMetricsData["eventkey"] = { event: "viewcontent", value: title };
    }

    call(`users/${profile.id}/contentmetrics`, "PUT", { body: userMetricsData })
      .then((res) => {
        if (res.status == 200) {
          setUserMetricsData(userMetricsData);
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const closeIntroductionaryMessage = () => {
    localStorage.setItem("IntroMessageViewed", new Date().toLocaleDateString());
    setShowIntroductoryMessage(false);
  };

  return expanded ? (
    <div className="chatbot-container">
      <div className="chatbot-header" onClick={toggleChatbot}>
        <span>Maxwell</span>
        {/* <SmartToyOutlinedIcon fontSize="large" sx={{ paddingLeft: "10px" }} /> */}
        <img src={BotLogo} height={30} width={30} style={{ marginLeft: "10px" }} />
      </div>
      {!showContent && (
        <div className="chatbot-body">
          {getWelcomeMessage()}
          {questions.slice(0, currentQuestionIndex + 1).map((question, index) => (
            <div key={index} className={`chat ${question.userResponse ? "answered" : ""}`}>
              {!question.userResponse && (
                <div className="question-container">
                  {showTyping && <div className="chatbot-message">{t("bot.typing")}</div>}
                  {!showTyping && (
                    <div>
                      <div className="chatbot-message">{question.question}</div>
                      <div className="options">
                        {question.options.map((option, optionIndex) => (
                          <div
                            key={optionIndex}
                            className="option"
                            onClick={() => handleAnswerSelection(option)}
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {question.userResponse === "Other" && (
                <div>
                  <div className="answered-container">
                    <div className="chatbot-message">{question.question}</div>
                  </div>
                  <div className="user-input">
                    <input
                      type="text"
                      placeholder="Type your answer here"
                      value={userInput}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                    />
                    <button type="button" className="submit-button" onClick={handleSubmit}>
                      <SendIcon fontSize="medium" sx={{ paddingTop: "5px" }} />
                    </button>
                  </div>
                </div>
              )}

              {question.userResponse && question.userResponse !== "Other" && (
                <div className="answered-container">
                  <div className="chatbot-message">{question.question}</div>
                  <div className="user-message">{question.userResponse}</div>
                </div>
              )}
            </div>
          ))}
          {showThankYou && renderThankYouMessage()}{" "}
          {/* Render thank you message if all questions are answered */}
        </div>
      )}

      {showContent && (
        <div className="chatbot-body">
          {renderContentMessage()}
          {content.map((content, index) => (
            <div
              className="content-container"
              key={index}
              onClick={() => contentViewedHandler(content.title)}
            >
              <a href={content.content_url} target="_blank" rel="noopener noreferrer">
                <div className="content-message">
                  <div className="content-title">{content.title}</div>
                  <div className="image-container">
                    <img
                      src={content.thumbnail_url ? content.thumbnail_url : MaxuLogo}
                      width={250}
                      height={250}
                      alt={t("imageAlts.maxuLogo")}
                    />
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    <div>
      {showIntroductoryMessage && <IntroductoryMessage onClose={closeIntroductionaryMessage} />}
      <div className="icon" onClick={toggleChatbot}>
        {showUnreadChat && <div className="unread-indicator">1</div>}
        <ChatBubbleOutlineOutlinedIcon
          fontSize="large"
          color="primary"
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        />
      </div>
    </div>
  );
};

export default Bot;
