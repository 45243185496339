import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";
// import MDBadge from "components/MDBadge";

// Otis Admin PRO React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Otis Admin PRO React context
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";

function DashboardNavbar({ absolute, light, title, breadcrumbs }) {
  const [controller, dispatch] = useMaterialUIController();
  const { persona } = controller;
  // const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  // const handleCloseMenu = () => setOpenMenu(false);
  const { signOut } = useAuthenticator((context) => [context.user]);

  // Render the notifications menu
  // const renderMenu = () => (
  //   <Menu
  //     anchorEl={openMenu}
  //     anchorReference={null}
  //     anchorOrigin={{
  //       vertical: "bottom",
  //       horizontal: "left",
  //     }}
  //     open={Boolean(openMenu)}
  //     onClose={handleCloseMenu}
  //     sx={{ mt: 2 }}
  //   >
  //     <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
  //     <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
  //     <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
  //   </Menu>
  // );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = dark.main;

      // if (transparentNavbar && !light) {
      //   colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      // }

      return colorValue;
    },
  });

  const logOut = () => {
    localStorage.removeItem("session");
    // localStorage.removeItem("ActiveAssessment");
    localStorage.removeItem("referral");
    navigate("/");
    signOut();
  };

  const navigate = useNavigate();

  return (
    <AppBar
      position="static"
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar: false, absolute, light, darkMode: true })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini: false })}
        >
          {breadcrumbs ? (
            breadcrumbs
          ) : (
            <Breadcrumbs
              icon="home"
              title={title ? title : route[route.length - 1]}
              route={route}
              light={light}
            />
          )}
        </MDBox>
        {
          <MDBox
            display={{ xs: "none", md: "block" }}
            sx={(theme) => navbarRow(theme, { isMini: false })}
          >
            {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                onClick={logOut}
                aria-label="logOut"
                sx={navbarIconButton}
                size="small"
                disableRipple
              >
                <Icon sx={iconsStyle}>exit_to_app</Icon>
              </IconButton>
              <IconButton
                onClick={() => navigate("/profile")}
                aria-label="Goto Profile"
                sx={navbarIconButton}
                size="small"
                disableRipple
              >
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <MDBadge badgeContent={9} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton> */}
              {/* {renderMenu()} */}
            </MDBox>
          </MDBox>
        }
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  breadcrumbs: PropTypes.node,
};

export default DashboardNavbar;
