// badges Name and logo can be changes
import { useTranslation } from "react-i18next";

// Note : We can't change the key value. we are collecting progress based on key value 
const BadgesData = () => {
    const {t} = useTranslation()

    return [
        {
            "logo": "TheExplorer",
            "name": t("achievements.theExplorer.name"),
            "description":  t("achievements.theExplorer.description"),
            "key": "The Explorer"
        },
        {
            "logo": "TheWatcher",
            "name": t("achievements.theWatcher.name"),
            "description": t("achievements.theWatcher.description"),
            "key": "The Watcher",
        },
        {
            "logo": "TheBeliever",
            "name": t("achievements.theBeliever.name"),
            "description": t("achievements.theBeliever.description"),
            "key": "The Believer",
        },
        {
            "logo": "ThePhantom",
            "name": t("achievements.thePhantom.name"),
            "description": t("achievements.thePhantom.description"),
            "key": "The Phantom",
        },
        {
            "logo": "LikedUser",
            "name": t("achievements.likedUser.name"),
            "description": t("achievements.likedUser.description"),
            "key": "Liked User",
        },
        {
            "logo": "TheSuperWatcher",
            "name": t("achievements.theSuperWatcher.name"),
            "description": t("achievements.theSuperWatcher.description"),
            "key": "The Super Watcher"
        },
        {
            "logo": "SilverUser",
            "name": t("achievements.silverUser.name"),
            "description": t("achievements.silverUser.description"),
            "key": "Silver User",
        },
        {
            "logo": "GoldenUser",
            "name":  t("achievements.goldenUser.name"),
            "description": t("achievements.goldenUser.description"),
            "key": "Golden User",
        },
    ]
}

export default BadgesData;
