import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Autocomplete from "@mui/material/Autocomplete";

import { call } from "services/axiosService/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import * as Sentry from "@sentry/browser";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RequiredLabel = ({ children }) => (
  <FormLabel
    sx={{ display: "flex", alignItems: "center", margin: "5px auto 5px", fontSize: "20px" }}
  >
    <span style={{ color: "red", fontSize: "small", marginRight: "5px" }}>*</span>
    {children}
    <span style={{ color: "red", fontSize: "small", marginLeft: "10px" }}>Required</span>
  </FormLabel>
);

const DeleteInsightsButton = ({ sk, DeleteInsightsHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Delete Insights</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="error"
          onClick={() => {
            DeleteInsightsHandler(sk);
          }}
        >
          delete
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

export function InsightsTable({insights, loadInsights, setSessionExpired}) {
  const [selectedInsight, setSelectedInsight] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedInsightsDetails, setSelectedInsightsDetails] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [pageNumber, setPageNumber] = useState(0)
  const [severity, setSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const DeleteInsightsHandler = (sk) => {
    setSelectedInsight(sk);
    setOpenDeleteDialog(true);
  };

  const CloseDeleteInsightsDialogHandler = () => {
    setOpenDeleteDialog(false);
    setSelectedInsight("");
  };

  const CloseViewInsightsDialogHandler = () => {
    setOpenViewDialog(false);
    setSelectedId("");
  };

  const tableData = {
    columns: [
      { Header: "#", accessor: "num", width: "15%" },
      { Header: "Category", accessor: "category", width: "15%" },
      { Header: "Range", accessor: "range", width: "15%" },
      { Header: "Insights", accessor: "data", width: "35%" },
      { Header: "Actions", accessor: "actions", width: "15%" },
    ],
    rows: insights,
  };

  tableData.rows = tableData.rows.map((row, index) => ({
    ...row,
    id: row.sk,
    num: index + 1,
    data: row.insights[0].slice(0, 100),
    actions: (
      <div style={{ display: "flex" }}>
        <DeleteInsightsButton sk={row.sk} DeleteInsightsHandler={DeleteInsightsHandler} />
      </div>
    ),
  }));

  useEffect(() => {
    if (!openDeleteDialog) {
      if (selectedId) {
        const insight = insights.filter((row) => {
          return row.sk == selectedId;
        });
        setSelectedInsightsDetails(insight[0]);

        setOpenViewDialog(true);
      }
    }
  }, [selectedId]);


  return (
    <>
      <DataTable table={tableData} setSelectedId={setSelectedId} pageNumber={pageNumber} setPageNumber={setPageNumber}/>
      {openViewDialog && (
        <ViewInsightsDataDialog
          openViewDialog={openViewDialog}
          CloseViewInsightsDialogHandler={CloseViewInsightsDialogHandler}
          selectedInsightsDetails={selectedInsightsDetails}
          loadInsights={loadInsights}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openDeleteDialog && (
        <DeleteInsightsDialog
          openDeleteDialog={openDeleteDialog}
          CloseDeleteInsightsDialogHandler={CloseDeleteInsightsDialogHandler}
          selectedInsight={selectedInsight}
          loadInsights={loadInsights}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}

      <Snackbar
        autoHideDuration={5000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

const DeleteInsightsDialog = ({
  openDeleteDialog,
  CloseDeleteInsightsDialogHandler,
  selectedInsight,
  loadInsights,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired
}) => {
  const deleteInsights = () => {
    call(`insights/${selectedInsight}`, "DELETE", {})
      .then((res) => {
        if (res.status == 200) {
          setOpenSnackbar(true);
          setAlertMessage("Insights Data Deleted Sucessfully");
          setSeverity("success");
          CloseDeleteInsightsDialogHandler();
          loadInsights();
        } 
        else if(res.status == 440){
          CloseDeleteInsightsDialogHandler();
          setSessionExpired(true)


        }else {
          setOpenSnackbar(true);
          setAlertMessage("Error while Deleting Insights Data");
          setSeverity("error");
        }
      })
      .catch((err) => {
        CloseDeleteInsightsDialogHandler();
        Sentry.captureException(err);
      });
  };

  return (
    <Dialog open={openDeleteDialog} onClose={CloseDeleteInsightsDialogHandler} maxWidth={"md"}>
      <DialogTitle> Delete Insights </DialogTitle>
      <DialogContent>Are you sure you want to Delete this Insights Data ?</DialogContent>

      <DialogActions>
        <Button onClick={deleteInsights} style={{ color: "white", background: "#0AB2D6" }}>
          Delete
        </Button>
        <Button
          onClick={CloseDeleteInsightsDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ViewInsightsDataDialog = ({
  openViewDialog,
  CloseViewInsightsDialogHandler,
  selectedInsightsDetails,
  loadInsights,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired
}) => {
  const [details, setDetails] = useState(selectedInsightsDetails);
  const [insights, setInsights] = useState(details.insights);
  const [showCircularProgress, setShowCircularProgress] = useState(false);

  const defaultValues = {
    category: details.category,
    range: details.range,
  };
  const { handleSubmit, reset, control, formState, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const { isValid, dirtyFields, errors } = formState;

  const changeInsightsHandler = (index, event) => {
    let data = insights;
    data[index] = event.target.value;
    setInsights([...data]);
  };

  const addInsightsHandler = () => {
    let data = [...insights, ""];
    setInsights(data);
  };

  const removeInsightsHandler = (index) => {
    let data = [...insights];
    data.splice(index, 1);
    setInsights(data);
  };

  const ResetInsightsHandler = () => {
    setInsights(details.insights);
  };

  const updateInsightsHandler = (data) => {
    // console.log("data is", data);
    selectedInsightsDetails["insights"] = [...insights];
    data = selectedInsightsDetails;
    call(`insights/${data.sk}`, "PUT", { body: data })
      .then((res) => {
        setShowCircularProgress(false);
        if (res.status == 200) {
          // console.log("res", res);
          setOpenSnackbar(true);
          setAlertMessage("Insights Data Added Sucessfully");
          setSeverity("success");
          CloseViewInsightsDialogHandler();
        } 
        else if(res.status == 440){
           CloseViewInsightsDialogHandler();
           setSessionExpired(true)
        }
        else {
          setOpenSnackbar(true);
          setAlertMessage(res.data.message);
          setSeverity("error");
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setShowCircularProgress(false);
        setOpenSnackbar(true);
        setAlertMessage("Error while adding the Insights");
        setSeverity("error");
      });
  };

  return (
    <Dialog
      open={openViewDialog}
      onClose={CloseViewInsightsDialogHandler}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle> View / Edit Insights Details </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit((data) => updateInsightsHandler(data))}>
          <Grid container spacing={3} p={3}>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <RequiredLabel>  Category: </RequiredLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    sx={{ height: 50 }}
                    size="large"
                    disabled
                    error={!!errors.category}
                    helperText={errors?.catgory?.message}
                    {...field}
                    inputProps={{
                      autoComplete: "new-category",
                    }}
                    variant="outlined"
                    fullWidth
                  />
                )}
                name="category"
                control={control}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <RequiredLabel>Range:</RequiredLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    sx={{ height: 50 }}
                    size="large"
                    disabled
                    error={!!errors.range}
                    helperText={errors?.range?.message}
                    {...field}
                    inputProps={{
                      autoComplete: "new-range",
                    }}
                    variant="outlined"
                    fullWidth
                  />
                )}
                name="range"
                control={control}
              />
            </Grid>
            <Grid item xs={12} xl={12}>
              <Grid
                container
                spacing={1}
                border={1}
                boxShadow={"5"}
                mt={2}
                mb={2}
                borderColor={"#49a3f1"}
              >
                <Grid item xs={12} xl={12}>
                  <RequiredLabel>Insights:</RequiredLabel>
                </Grid>
                {insights.map((recom, index) => {
                  return (
                    <Grid
                      container
                      spacing={1}
                      border={1}
                      m={2}
                      pb={1}
                      boxShadow={"3"}
                      borderColor={"#0c85ed"}
                    >
                      <Grid item xs={12} sm={12} md={9} lg={10} xl={10} pr={1}>
                        <TextField
                          sx={{ height: 70 }}
                          required
                          name="test"
                          placeholder="Add Insights here"
                          multiline
                          rows={2}
                          value={recom}
                          error={!!errors.insight}
                          helperText={errors?.insight?.message}
                          size="large"
                          onChange={(event) => changeInsightsHandler(index, event)}
                          inputProps={{
                            autoComplete: "new-insights",
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                        {index != 0 && (
                          <MDBox
                            display="flex"
                            flex={1}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <MDButton
                              sx={{
                                marginTop: {
                                  xs: "0px",
                                  sm: "0px",
                                  md: "10px",
                                  lg: "10px",
                                  xl: "10px",
                                },
                              }}
                              style={{
                                color: "white",
                                background: "#f6821e",
                                justifyContent: "center",
                                width: "100%",
                                marginRight: "5px",
                              }}
                              onClick={() => {
                                removeInsightsHandler(index);
                              }}
                              type="button"
                            >
                              Remove
                            </MDButton>
                          </MDBox>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item xs={12} xl={12} mb={2}>
                  <MDBox display="flex" justifyContent="center">
                    <MDButton
                      onClick={addInsightsHandler}
                      style={{
                        color: "white",
                        background: "#0AB2D6",
                        marginTop: "5px",
                        display: "flex",
                        marginRight: "5px",
                        justifyContent: "center",
                      }}
                      type="button"
                    >
                      Add More Insights
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} xl={12}>
              {showCircularProgress ? (
                <MDBox display="flex" justifyContent="end">
                  <MDButton
                    style={{
                      color: "white",
                      background: "#0AB2D6",
                      marginTop: "15px",
                      display: "flex",
                      width: "100%",
                    }}
                    disabled
                    type="button"
                  >
                    <CircularProgress color="white" size={20} />
                  </MDButton>
                </MDBox>
              ) : (
                <MDBox display="flex" justifyContent="end">
                  <MDButton
                    style={{
                      color: "white",
                      background: "#0AB2D6",
                      marginTop: "15px",
                      display: "flex",
                      width: "100%",
                    }}
                    type="submit"
                  >
                    Update Insights Data
                  </MDButton>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={ResetInsightsHandler} style={{ color: "white", background: "#0AB2D6" }}>
          Reset
        </Button>
        <Button
          onClick={CloseViewInsightsDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
