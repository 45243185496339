import { useTranslation } from "react-i18next";

const FaqData = () => {
  const { t } = useTranslation();

  return [
    {
      question: t("faqs.question1"),
      answer: [t("faqs.answer1.1"), t("faqs.answer1.2")],
    },
    {
      question: t("faqs.question2"),
      answer: [
        t("faqs.answer2.1"),
        t("faqs.answer2.2"),
        t("faqs.answer2.3"),
        t("faqs.answer2.4"),
        t("faqs.answer2.5"),
        t("faqs.answer2.6"),
      ],
    },
    {
      question: t("faqs.question3"),
      answer: [t("faqs.answer3.1")],
    },
    {
      question: t("faqs.question4"),
      answer: [t("faqs.answer4.1"), t("faqs.answer4.2")],
    },
    {
      question: t("faqs.question5"),
      answer: [t("faqs.answer5.1"), t("faqs.answer5.2"), t("faqs.answer5.3")],
    },
    {
      question: t("faqs.question6"),
      answer: [t("faqs.answer6.1"), t("faqs.answer6.2")],
    },
    {
      question: t("faqs.question7"),
      answer: [t("faqs.answer7.1")],
    },
    {
      question: t("faqs.question8"),
      answer: [t("faqs.answer8.1")],
    },
    {
      question: t("faqs.question9"),
      answer: [t("faqs.answer9.1")],
    },
    {
      question: t("faqs.question10"),
      answer: [t("faqs.answer10.1")],
    },
    {
      question: t("faqs.question11"),
      answer: [t("faqs.answer11.1"), t("faqs.answer11.2")],
    },
    {
      question: t("faqs.question12"),
      answer: [t("faqs.answer12.1"), t("faqs.answer12.2")],
    },
    {
      question: t("faqs.question13"),
      answer: [t("faqs.answer13.1")],
    },
    {
      question: t("faqs.question14"),
      answer: [t("faqs.answer14.1")],
    },
    {
      question: t("faqs.question15"),
      answer: [t("faqs.answer15.1"), t("faqs.answer15.2"), t("faqs.answer15.3")],
    },
    {
      question: t("faqs.question16"),
      answer: [t("faqs.answer16.1"), t("faqs.answer16.2"), t("faqs.answer16.3")],
    },
    {
      question: t("faqs.question17"),
      answer: [t("faqs.answer17.1"), t("faqs.answer17.2")],
    },
    {
      question: t("faqs.question18"),
      answer: [t("faqs.answer18.1")],
    },
    {
      question: t("faqs.question19"),
      answer: [t("faqs.answer19.1")],
    },
    {
      question: t("faqs.question20"),
      answer: [t("faqs.answer20.1")],
    },
  ];
};

export default FaqData;
