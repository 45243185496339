import React, { useCallback, useEffect, useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import { CircularProgress, FormLabel, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { call } from "services/axiosService/core";
import Refresh from "pages/refresh/Refresh";

import { ReactMultiEmail, isEmail } from "react-multi-email";
// import MaxuLogo from "assets/images/nav-logo.png";
import { ContentTable } from "./ContentTable";
import { AnimalsTable } from "./AnimalsTable";
import { InsightsTable } from "./InsightsTable";
import { useForm, Controller } from "react-hook-form";
import * as Sentry from "@sentry/browser";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function ContentDashboard() {
  const [openFilterByType, setOpenFilterByType] = useState(false);
  const [openFilterByCategory, setOpenFilterByCategory] = useState(false);
  const [openFilterByInsightRange, setOpenFilterByInsightRange] = useState(false);
  const [openFilterByInsightCategory, setOpenFilterByInsightCategory] = useState(false);
  const [openFilterByAnimalCategory, setOpenFilterByAnimalCategory] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [sessionExpired, setSessionExpired] = useState(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const [selectedFilterTypes, setSelectedFilterTypes] = useState({
    Video: true,
    Article: true,
    Worksheet: true,
    Infographic: true,
  });

  const [selectedFilterCategories, setSelectedFilterCategories] = useState({
    SR: true,
    SC: true,
    E: true,
    R: true,
    "T+": true,
    "T-": true,
    "A+": true,
    "A-": true,
    "E+": true,
    "E-": true,
    "O+": true,
    "O-": true,
    "C+": true,
    "C-": true,
    "T+E+": true,
    "T-E+": true,
    "T+E-": true,
    "T-E-": true,
    "T+O+": true,
    "T-O+": true,
    "T+O-": true,
    "T-O-": true,
    "T+A+": true,
    "T-A+": true,
    "T+A-": true,
    "T-A-": true,
    "T+C+": true,
    "T-C+": true,
    "T+C-": true,
    "T-C-": true,
    "E+O+": true,
    "E-O+": true,
    "E+O-": true,
    "E-O-": true,
    "E+A+": true,
    "E-A+": true,
    "E+A-": true,
    "E-A-": true,
    "E+C+": true,
    "E-C+": true,
    "E+C-": true,
    "E-C-": true,
    "O+A+": true,
    "O-A+": true,
    "O+A-": true,
    "O-A-": true,
    "O+C+": true,
    "O-C+": true,
    "O+C-": true,
    "O-C-": true,
    "A+C+": true,
    "A-C+": true,
    "A+C-": true,
    "A-C-": true,
  });

  const [selectedFilterAnimalCategories, setSelectedFilterAnimalCategories] = useState({
    SR: true,
    SC: true,
    E: true,
    R: true,
    "T+E+": true,
    "T-E+": true,
    "T+E-": true,
    "T-E-": true,
    "T+O+": true,
    "T-O+": true,
    "T+O-": true,
    "T-O-": true,
    "T+A+": true,
    "T-A+": true,
    "T+A-": true,
    "T-A-": true,
    "T+C+": true,
    "T-C+": true,
    "T+C-": true,
    "T-C-": true,
    "E+O+": true,
    "E-O+": true,
    "E+O-": true,
    "E-O-": true,
    "E+A+": true,
    "E-A+": true,
    "E+A-": true,
    "E-A-": true,
    "E+C+": true,
    "E-C+": true,
    "E+C-": true,
    "E-C-": true,
    "O+A+": true,
    "O-A+": true,
    "O+A-": true,
    "O-A-": true,
    "O+C+": true,
    "O-C+": true,
    "O+C-": true,
    "O-C-": true,
    "A+C+": true,
    "A-C+": true,
    "A+C-": true,
    "A-C-": true,
  });

  const [selectedFilterInsightRanges, setSelectedFilterInsightRanges] = useState({
    High: true,
    Low: true,
  });

  const [selectedFilterInsightCategories, setSelectedFilterInsightCategories] = useState({
    Temperament: true,
    Openness: true,
    Conscientiousness: true,
    Extraversion: true,
    Agreeableness: true,
  });

  const [content, setContent] = useState([]);
  const [totalContent, setTotalContent] = useState(null);
  const [animalsData, setAnimalsData] = useState([]);
  const [totalAnimals, setTotalAnimals] = useState(null);
  const [insightsData, setInsightsData] = useState([]);

  useEffect(() => {
    loadContent();
    loadAnimals();
    loadInsights();
  }, []);

  // const updateAllContents = () => {
  //   call(`content/`, "PUT", {})
  //     .then((res) => {
  //       if (res.status == 200) {
  //         setOpenSnackbar(true);
  //         setSeverity("success");
  //         setAlertMessage("Update all contents successfully");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       Sentry.captureException(err);
  //     });
  // };

  const loadContent = () => {
    call(`content/`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          setTotalContent(res.data.Items.length);
          setContent(res.data.Items);
        } else if (res.status == 440) {
          setSessionExpired(true);
        } else {
          setContent([]);
          console.log("something wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const loadAnimals = () => {
    call(`animals/`, "GET", {})
      .then((res) => {
        if (res.status == 200) {
          setTotalAnimals(res.data.Items.length);
          setAnimalsData(res.data.Items);
        } else if (res.status == 440) {
          setSessionExpired(true);
        } else {
          setAnimalsData([]);
          console.log("something wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const loadInsights = () => {
    call(`insights/`, "GET", {})
      .then((res) => {
        // console.log("res is", res);
        if (res.status == 200) {
          setInsightsData(res.data.Items);
        } else if (res.status == 440) {
          setSessionExpired(true);
        } else {
          setContent([]);
          console.log("something wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const closeFilterByType = () => {
    setOpenFilterByType(false);
  };

  const closeFilterByCategory = () => {
    setOpenFilterByCategory(false);
  };

  const closeFilterByInsightCategory = () => {
    setOpenFilterByInsightCategory(false);
  };

  const closeFilterByAnimalCategory = () => {
    setOpenFilterByAnimalCategory(false);
  };

  const closeFilterByInsightRange = () => {
    setOpenFilterByInsightRange(false);
  };

  const openFilterByTypeHandler = () => {
    setOpenFilterByType(true);
  };

  const openFilterByCategoryHandler = () => {
    setOpenFilterByCategory(true);
  };

  const openFilterByAnimalCategoryHandler = () => {
    setOpenFilterByAnimalCategory(true);
  };

  const openFilterByInsightCategoryHandler = () => {
    setOpenFilterByInsightCategory(true);
  };

  const openFilterByInsightRangeHandler = () => {
    setOpenFilterByInsightRange(true);
  };

  // const editContentHandler = (item) => {
  //   console.log("editable");
  //   console.log("editing", item);
  // };

  const clearFiltersHandler = () => {
    const categories = [];
    const types = [];
    const selectedCategories = {
      SR: true,
      SC: true,
      E: true,
      R: true,
      "T+": true,
      "T-": true,
      "A+": true,
      "A-": true,
      "E+": true,
      "E-": true,
      "O+": true,
      "O-": true,
      "C+": true,
      "C-": true,
      "T+E+": true,
      "T-E+": true,
      "T+E-": true,
      "T-E-": true,
      "T+O+": true,
      "T-O+": true,
      "T+O-": true,
      "T-O-": true,
      "T+A+": true,
      "T-A+": true,
      "T+A-": true,
      "T-A-": true,
      "T+C+": true,
      "T-C+": true,
      "T+C-": true,
      "T-C-": true,
      "E+O+": true,
      "E-O+": true,
      "E+O-": true,
      "E-O-": true,
      "E+A+": true,
      "E-A+": true,
      "E+A-": true,
      "E-A-": true,
      "E+C+": true,
      "E-C+": true,
      "E+C-": true,
      "E-C-": true,
      "O+A+": true,
      "O-A+": true,
      "O+A-": true,
      "O-A-": true,
      "O+C+": true,
      "O-C+": true,
      "O+C-": true,
      "O-C-": true,
      "A+C+": true,
      "A-C+": true,
      "A+C-": true,
      "A-C-": true,
    };

    const selectedTypes = {
      Video: true,
      Article: true,
      Worksheet: true,
      Infographic: true,
    };

    for (const key in selectedTypes) {
      if (selectedTypes[key]) {
        types.push(key);
      }
    }

    for (const key in selectedCategories) {
      if (selectedCategories[key]) {
        categories.push(key);
      }
    }

    call(`content/`, "GET", {
      params: { type: JSON.stringify(types), category: JSON.stringify(categories) },
    })
      .then((res) => {
        if (res.status == 200) {
          setContent(res.data.Items);
          setSelectedFilterTypes(selectedTypes);
          setSelectedFilterCategories(selectedCategories);
          setOpenSnackbar(true);
          setSeverity("success");
          setAlertMessage("Clear all the filters");
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setSeverity("error");
        setOpenSnackbar(true);
        setAlertMessage("Error while clearing filters");
      });
  };

  const clearInsightFiltersHandler = () => {
    const categories = [];
    const ranges = [];
    const selectedInsightCategories = {
      Temperament: true,
      Openness: true,
      Conscientiousness: true,
      Extraversion: true,
      Agreeableness: true,
    };

    const selectedInsightRanges = {
      Low: "true",
      High: "true",
    };

    for (const key in selectedInsightRanges) {
      if (selectedInsightRanges[key]) {
        ranges.push(key);
      }
    }

    for (const key in selectedInsightCategories) {
      if (selectedInsightCategories[key]) {
        categories.push(key);
      }
    }

    call(`insights/`, "GET", {
      params: { range: JSON.stringify(ranges), category: JSON.stringify(categories) },
    })
      .then((res) => {
        if (res.status == 200) {
          setInsightsData(res.data.Items);
          setSelectedFilterInsightRanges(selectedInsightRanges);
          setSelectedFilterInsightCategories(selectedInsightCategories);
          setOpenSnackbar(true);
          setSeverity("success");
          setAlertMessage("Clear all the filters");
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setOpenSnackbar(true);
        setSeverity("error");
        setAlertMessage("Error while clearing filters");
      });
  };

  const clearAnimalFiltersHandler = () => {
    const categories = [];
    const selectedCategories = {
      SR: true,
      SC: true,
      E: true,
      R: true,
      "T+E+": true,
      "T-E+": true,
      "T+E-": true,
      "T-E-": true,
      "T+O+": true,
      "T-O+": true,
      "T+O-": true,
      "T-O-": true,
      "T+A+": true,
      "T-A+": true,
      "T+A-": true,
      "T-A-": true,
      "T+C+": true,
      "T-C+": true,
      "T+C-": true,
      "T-C-": true,
      "E+O+": true,
      "E-O+": true,
      "E+O-": true,
      "E-O-": true,
      "E+A+": true,
      "E-A+": true,
      "E+A-": true,
      "E-A-": true,
      "E+C+": true,
      "E-C+": true,
      "E+C-": true,
      "E-C-": true,
      "O+A+": true,
      "O-A+": true,
      "O+A-": true,
      "O-A-": true,
      "O+C+": true,
      "O-C+": true,
      "O+C-": true,
      "O-C-": true,
      "A+C+": true,
      "A-C+": true,
      "A+C-": true,
      "A-C-": true,
    };

    for (const key in selectedCategories) {
      if (selectedCategories[key]) {
        categories.push(key);
      }
    }

    call(`animals/`, "GET", {
      params: { category: JSON.stringify(categories) },
    })
      .then((res) => {
        if (res.status == 200) {
          setAnimalsData(res.data.Items);
          setSelectedFilterAnimalCategories(selectedCategories);
          setOpenSnackbar(true);
          setSeverity("success");
          setAlertMessage("Clear all filters");
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setOpenSnackbar(true);
        setSeverity("error");
        setAlertMessage("Error while clearing filters");
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title={`Content Dashboard`} />
      <MDBox mt={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="insights"
                title="Total Contents"
                count={totalContent}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="pets"
                title="Total Animals"
                count={totalAnimals}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox py={2}>
          <Card style={{ height: "100%" }}>
            <MDBox display="flex" justifyContent="space-between" m={2}>
              <MDButton
                onClick={openFilterByTypeHandler}
                sx={{ width: 300, marginRight: 2 }}
                variant="contained"
                color="primary"
              >
                Filter By Type
              </MDButton>
              <MDButton
                onClick={openFilterByCategoryHandler}
                sx={{ width: 300, marginRight: 2 }}
                variant="contained"
                color="primary"
              >
                Filter By Category
              </MDButton>
              <MDButton
                onClick={clearFiltersHandler}
                sx={{ width: 300, marginRight: 2 }}
                variant="contained"
                color="secondary"
              >
                Clear Filters
              </MDButton>
              {/* <MDButton
                onClick={updateAllContents}
                sx={{ width: 300, marginRight: 2 }}
                variant="contained"
                color="primary"
              >
                Add Hidden
              </MDButton> */}
            </MDBox>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Contents Data
              </MDTypography>
              <ContentTable
                content={content ?? []}
                loadContent={loadContent}
                setSessionExpired={setSessionExpired}
              />
            </MDBox>
          </Card>
        </MDBox>

        <MDBox py={4}>
          <Card style={{ height: "100%" }}>
            <MDBox display="flex" justifyContent="space-between" m={2}>
              <MDButton
                onClick={openFilterByAnimalCategoryHandler}
                sx={{ width: 300, marginRight: 2 }}
                variant="contained"
                color="primary"
              >
                Filter By Category
              </MDButton>
              <MDButton
                onClick={clearAnimalFiltersHandler}
                sx={{ width: 300, marginRight: 2 }}
                variant="contained"
                color="secondary"
              >
                Clear Filters
              </MDButton>
            </MDBox>

            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Animals Data
              </MDTypography>
              <AnimalsTable
                animals={animalsData ?? []}
                loadAnimals={loadAnimals}
                setSessionExpired={setSessionExpired}
              />
            </MDBox>
          </Card>
        </MDBox>

        <MDBox py={4}>
          <Card style={{ height: "100%" }}>
            <MDBox display="flex" justifyContent="space-between" m={2}>
              <MDButton
                onClick={openFilterByInsightRangeHandler}
                sx={{ width: 300, marginRight: 2 }}
                variant="contained"
                color="primary"
              >
                Filter By Range
              </MDButton>
              <MDButton
                onClick={openFilterByInsightCategoryHandler}
                sx={{ width: 300, marginRight: 2 }}
                variant="contained"
                color="primary"
              >
                Filter By Category
              </MDButton>
              <MDButton
                onClick={clearInsightFiltersHandler}
                sx={{ width: 300, marginRight: 2 }}
                variant="contained"
                color="secondary"
              >
                Clear Filters
              </MDButton>
            </MDBox>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Insights Data
              </MDTypography>
              <InsightsTable
                insights={insightsData ?? []}
                loadInsights={loadInsights}
                setSessionExpired={setSessionExpired}
              />
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>
      {openFilterByType && (
        <FilterByTypeDailog
          openFilterByType={openFilterByType}
          closeFilterByType={closeFilterByType}
          selectedFilterTypes={selectedFilterTypes}
          setSelectedFilterTypes={setSelectedFilterTypes}
          selectedFilterCategories={selectedFilterCategories}
          setContent={setContent}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openFilterByCategory && (
        <FilterByCategoryDialog
          openFilterByCategory={openFilterByCategory}
          closeFilterByCategory={closeFilterByCategory}
          selectedFilterCategories={selectedFilterCategories}
          setSelectedFilterCategories={setSelectedFilterCategories}
          selectedFilterTypes={selectedFilterTypes}
          setContent={setContent}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openFilterByInsightCategory && (
        <FilterByInsightCategoryDialog
          openFilterByInsightCategory={openFilterByInsightCategory}
          closeFilterByInsightCategory={closeFilterByInsightCategory}
          selectedFilterInsightCategories={selectedFilterInsightCategories}
          setSelectedFilterInsightCategories={setSelectedFilterInsightCategories}
          selectedFilterInsightRanges={selectedFilterInsightRanges}
          setInsightsData={setInsightsData}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openFilterByInsightRange && (
        <FilterByInsightRangeDialog
          openFilterByInsightRange={openFilterByInsightRange}
          closeFilterByInsightRange={closeFilterByInsightRange}
          selectedFilterInsightRanges={selectedFilterInsightRanges}
          setSelectedFilterInsightRanges={setSelectedFilterInsightRanges}
          selectedFilterInsightCategories={selectedFilterInsightCategories}
          setInsightsData={setInsightsData}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openFilterByAnimalCategory && (
        <FilterByAnimalCategoryDialog
          openFilterByAnimalCategory={openFilterByAnimalCategory}
          closeFilterByAnimalCategory={closeFilterByAnimalCategory}
          selectedFilterAnimalCategories={selectedFilterAnimalCategories}
          setSelectedFilterAnimalCategories={setSelectedFilterAnimalCategories}
          setAnimalsData={setAnimalsData}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}

      <Snackbar
        autoHideDuration={2000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
}

const FilterByTypeDailog = ({
  openFilterByType,
  closeFilterByType,
  selectedFilterTypes,
  setSelectedFilterTypes,
  selectedFilterCategories,
  setContent,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired,
}) => {
  const AllTypes = ["Video", "Article", "Worksheet", "Infographic"];
  const [selectedTypes, setSelectedTypes] = useState(selectedFilterTypes);

  const selectAllHandler = () => {
    setSelectedTypes({
      Video: true,
      Article: true,
      Worksheet: true,
      Infographic: true,
    });
  };

  const deSelectAllHandler = () => {
    setSelectedTypes({
      Video: false,
      Article: false,
      Worksheet: false,
      Infographic: false,
    });
  };

  const applyHandler = () => {
    const types = [];
    const categories = [];

    for (const key in selectedFilterCategories) {
      if (selectedFilterCategories[key]) {
        categories.push(key);
      }
    }

    for (const key in selectedTypes) {
      if (selectedTypes[key]) {
        types.push(key);
      }
    }
    if (types.length == 0 || categories.length == 0) {
      setSeverity("error");
      setOpenSnackbar(true);
      setAlertMessage("Please select atleast one option to apply filter");
    } else {
      call(`content/`, "GET", {
        params: { type: JSON.stringify(types), category: JSON.stringify(categories) },
      })
        .then((res) => {
          if (res.status == 200) {
            setContent(res.data.Items);
            setSelectedFilterTypes(selectedTypes);
            setSeverity("success");
            setOpenSnackbar(true);
            setAlertMessage("Filter applied successfully");
            closeFilterByType();
          } else if (res.status == 440) {
            setSessionExpired(true);
            closeFilterByType();
          } else {
            setSeverity("error");
            setOpenSnackbar(false);
            setAlertMessage("Error While applying filter");
            closeFilterByType();
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
          setSeverity("error");
          setOpenSnackbar(false);
          setAlertMessage("Error While applying filter");
        });
    }
  };

  const onChangeHandler = (type) => {
    let currentValue = selectedTypes[type];
    setSelectedTypes((prevState) => ({
      ...prevState,
      [type]: !currentValue,
    }));
  };

  return (
    <Dialog open={openFilterByType} onClose={closeFilterByType}>
      <DialogTitle>Filter By type</DialogTitle>
      <DialogContent>
        <MDBox display="flex" flexDirection={"row"} justifyContent="space-between">
          <MDBox mr={2}>
            <MDButton variant="contained" color="primary" onClick={selectAllHandler}>
              Select All
            </MDButton>
          </MDBox>
          <MDBox ml={2}>
            <MDButton variant="contained" color="secondary" onClick={deSelectAllHandler}>
              Deselect All
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection={"column"} mt={3} ml={3}>
          <FormGroup>
            {AllTypes.map((type, index) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedTypes[type]}
                      onChange={() => onChangeHandler(type)}
                    />
                  }
                  label={type}
                />
              );
            })}
          </FormGroup>
        </MDBox>
        <MDBox display="flex" flexDirection={"row"} mt={2} justifyContent="space-between">
          <MDBox mr={2}>
            <MDButton variant="contained" color="primary" onClick={applyHandler}>
              Apply
            </MDButton>
          </MDBox>
          <MDBox ml={2}>
            <MDButton variant="contained" color="secondary" onClick={closeFilterByType}>
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      </DialogContent>
    </Dialog>
  );
};

const FilterByCategoryDialog = ({
  openFilterByCategory,
  closeFilterByCategory,
  selectedFilterCategories,
  setSelectedFilterCategories,
  selectedFilterTypes,
  setContent,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired,
}) => {
  const AllCategories = [
    "SR",
    "SC",
    "E",
    "R",
    "T+",
    "T-",
    "E+",
    "E-",
    "O+",
    "O-",
    "C+",
    "C-",
    "A+",
    "A-",
    "T+E+",
    "T-E+",
    "T+E-",
    "T-E-",
    "T+O+",
    "T-O+",
    "T+O-",
    "T-O-",
    "T+A+",
    "T-A+",
    "T+A-",
    "T-A-",
    "T+C+",
    "T-C+",
    "T+C-",
    "T-C-",
    "E+O+",
    "E-O+",
    "E+O-",
    "E-O-",
    "E+A+",
    "E-A+",
    "E+A-",
    "E-A-",
    "E+C+",
    "E-C+",
    "E+C-",
    "E-C-",
    "O+A+",
    "O-A+",
    "O+A-",
    "O-A-",
    "O+C+",
    "O-C+",
    "O+C-",
    "O-C-",
    "A+C+",
    "A-C+",
    "A+C-",
    "A-C-",
  ];
  const [selectedCategories, setSelectedCategories] = useState(selectedFilterCategories);

  const selectAllHandler = () => {
    setSelectedCategories({
      SR: true,
      SC: true,
      E: true,
      R: true,
      "T+": true,
      "T-": true,
      "E+": true,
      "E-": true,
      "O+": true,
      "O-": true,
      "A+": true,
      "A-": true,
      "C+": true,
      "C-": true,
      "T+E+": true,
      "T-E+": true,
      "T+E-": true,
      "T-E-": true,
      "T+O+": true,
      "T-O+": true,
      "T+O-": true,
      "T-O-": true,
      "T+A+": true,
      "T-A+": true,
      "T+A-": true,
      "T-A-": true,
      "T+C+": true,
      "T-C+": true,
      "T+C-": true,
      "T-C-": true,
      "E+O+": true,
      "E-O+": true,
      "E+O-": true,
      "E-O-": true,
      "E+A+": true,
      "E-A+": true,
      "E+A-": true,
      "E-A-": true,
      "E+C+": true,
      "E-C+": true,
      "E+C-": true,
      "E-C-": true,
      "O+A+": true,
      "O-A+": true,
      "O+A-": true,
      "O-A-": true,
      "O+C+": true,
      "O-C+": true,
      "O+C-": true,
      "O-C-": true,
      "A+C+": true,
      "A-C+": true,
      "A+C-": true,
      "A-C-": true,
    });
  };

  const deSelectAllHandler = () => {
    setSelectedCategories({
      SR: false,
      SC: false,
      E: false,
      R: false,
      "T+": false,
      "T-": false,
      "E+": false,
      "E-": false,
      "O+": false,
      "O-": false,
      "A+": false,
      "A-": false,
      "C+": false,
      "C-": false,
      "T+E+": false,
      "T-E+": false,
      "T+E-": false,
      "T-E-": false,
      "T+O+": false,
      "T-O+": false,
      "T+O-": false,
      "T-O-": false,
      "T+A+": false,
      "T-A+": false,
      "T+A-": false,
      "T-A-": false,
      "T+C+": false,
      "T-C+": false,
      "T+C-": false,
      "T-C-": false,
      "E+O+": false,
      "E-O+": false,
      "E+O-": false,
      "E-O-": false,
      "E+A+": false,
      "E-A+": false,
      "E+A-": false,
      "E-A-": false,
      "E+C+": false,
      "E-C+": false,
      "E+C-": false,
      "E-C-": false,
      "O+A+": false,
      "O-A+": false,
      "O+A-": false,
      "O-A-": false,
      "O+C+": false,
      "O-C+": false,
      "O+C-": false,
      "O-C-": false,
      "A+C+": false,
      "A-C+": false,
      "A+C-": false,
      "A-C-": false,
    });
  };

  const applyHandler = () => {
    const categories = [];
    const types = [];

    for (const key in selectedFilterTypes) {
      if (selectedFilterTypes[key]) {
        types.push(key);
      }
    }

    for (const key in selectedCategories) {
      if (selectedCategories[key]) {
        categories.push(key);
      }
    }

    if (types.length == 0 || categories.length == 0) {
      setOpenSnackbar(true);
      setSeverity("error");
      setAlertMessage("Please select atleast one option to apply filter");
    } else {
      call(`content/`, "GET", {
        params: { type: JSON.stringify(types), category: JSON.stringify(categories) },
      })
        .then((res) => {
          if (res.status == 200) {
            setContent(res.data.Items);
            setSelectedFilterCategories(selectedCategories);
            setSeverity("success");
            setOpenSnackbar(true);
            setAlertMessage("Filter applied successfully");
            closeFilterByCategory();
          } else if (res.status == 440) {
            closeFilterByCategory();
            setSessionExpired(true);
          } else {
            setSeverity("error");
            setOpenSnackbar(true);
            setAlertMessage("Error while applying filter");
            closeFilterByCategory();
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
          setSeverity("error");
          setOpenSnackbar(true);
          setAlertMessage("Error while applying filter");
        });
    }
  };

  const onChangeHandler = (category) => {
    let currentValue = selectedCategories[category];
    setSelectedCategories((prevState) => ({
      ...prevState,
      [category]: !currentValue,
    }));
  };

  return (
    <Dialog open={openFilterByCategory} onClose={closeFilterByCategory}>
      <DialogTitle>Filter By Category</DialogTitle>
      <DialogContent>
        <MDBox display="flex" flexDirection={"row"} justifyContent="space-between">
          <MDBox mr={2}>
            <MDButton variant="contained" color="primary" onClick={selectAllHandler}>
              Select All
            </MDButton>
          </MDBox>
          <MDBox ml={2}>
            <MDButton variant="contained" color="secondary" onClick={deSelectAllHandler}>
              Deselect All
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection={"column"} mt={3} ml={3}>
          <FormGroup>
            {AllCategories.map((category, index) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedCategories[category]}
                      onChange={() => onChangeHandler(category)}
                    />
                  }
                  label={category}
                />
              );
            })}
          </FormGroup>
        </MDBox>
        <MDBox display="flex" flexDirection={"row"} mt={2} justifyContent="space-between">
          <MDBox mr={2}>
            <MDButton variant="contained" color="primary" onClick={applyHandler}>
              Apply
            </MDButton>
          </MDBox>
          <MDBox ml={2}>
            <MDButton variant="contained" color="secondary" onClick={closeFilterByCategory}>
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      </DialogContent>
    </Dialog>
  );
};

const FilterByInsightCategoryDialog = ({
  openFilterByInsightCategory,
  closeFilterByInsightCategory,
  selectedFilterInsightCategories,
  setSelectedFilterInsightCategories,
  selectedFilterInsightRanges,
  setInsightsData,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired,
}) => {
  const AllCategories = [
    "Temperament",
    "Openness",
    "Conscientiousness",
    "Extraversion",
    "Agreeableness",
  ];
  const [selectedCategories, setSelectedCategories] = useState(selectedFilterInsightCategories);

  const selectAllHandler = () => {
    setSelectedCategories({
      Temperament: true,
      Openness: true,
      Conscientiousness: true,
      Extraversion: true,
      Agreeableness: true,
    });
  };

  const deSelectAllHandler = () => {
    setSelectedCategories({
      Temperament: false,
      Openness: false,
      Conscientiousness: false,
      Extraversion: false,
      Agreeableness: false,
    });
  };

  const applyHandler = () => {
    const categories = [];
    const ranges = [];

    for (const key in selectedFilterInsightRanges) {
      if (selectedFilterInsightRanges[key]) {
        ranges.push(key);
      }
    }

    for (const key in selectedCategories) {
      if (selectedCategories[key]) {
        categories.push(key);
      }
    }
    if (ranges.length == 0 || categories.length == 0) {
      setOpenSnackbar(true);
      setAlertMessage("Please select atleast one option to apply filter");
      setSeverity("error");
    } else {
      call(`insights/`, "GET", {
        params: { range: JSON.stringify(ranges), category: JSON.stringify(categories) },
      })
        .then((res) => {
          if (res.status == 200) {
            setInsightsData(res.data.Items);
            setSelectedFilterInsightCategories(selectedCategories);
            setSeverity("success");
            setOpenSnackbar(true);
            setAlertMessage("Filter applied Successfully");
            closeFilterByInsightCategory();
          } else if (res.status == 440) {
            closeFilterByInsightCategory();
            setSessionExpired(true);
          } else {
            setSeverity("error");
            setOpenSnackbar(true);
            setAlertMessage("Error while applying filter");
            closeFilterByInsightCategory();
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
          setSeverity("error");
          setOpenSnackbar(true);
          setAlertMessage("Error while applying filter");
        });
    }
  };

  const onChangeHandler = (category) => {
    let currentValue = selectedCategories[category];
    setSelectedCategories((prevState) => ({
      ...prevState,
      [category]: !currentValue,
    }));
  };

  return (
    <Dialog open={openFilterByInsightCategory} onClose={closeFilterByInsightCategory}>
      <DialogTitle>Filter By Category</DialogTitle>
      <DialogContent>
        <MDBox display="flex" flexDirection={"row"} justifyContent="space-between">
          <MDBox mr={2}>
            <MDButton variant="contained" color="primary" onClick={selectAllHandler}>
              Select All
            </MDButton>
          </MDBox>
          <MDBox ml={2}>
            <MDButton variant="contained" color="secondary" onClick={deSelectAllHandler}>
              Deselect All
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection={"column"} mt={3} ml={3}>
          <FormGroup>
            {AllCategories.map((category, index) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedCategories[category]}
                      onChange={() => onChangeHandler(category)}
                    />
                  }
                  label={category}
                />
              );
            })}
          </FormGroup>
        </MDBox>
        <MDBox display="flex" flexDirection={"row"} mt={2} justifyContent="space-between">
          <MDBox mr={2}>
            <MDButton variant="contained" color="primary" onClick={applyHandler}>
              Apply
            </MDButton>
          </MDBox>
          <MDBox ml={2}>
            <MDButton variant="contained" color="secondary" onClick={closeFilterByInsightCategory}>
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      </DialogContent>
    </Dialog>
  );
};

const FilterByInsightRangeDialog = ({
  openFilterByInsightRange,
  closeFilterByInsightRange,
  selectedFilterInsightRanges,
  setSelectedFilterInsightRanges,
  selectedFilterInsightCategories,
  setInsightsData,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired,
}) => {
  const AllRanges = ["High", "Low"];
  const [selectedRanges, setSelectedRanges] = useState(selectedFilterInsightRanges);

  const selectAllHandler = () => {
    setSelectedRanges({
      High: true,
      Low: true,
    });
  };

  const deSelectAllHandler = () => {
    setSelectedRanges({
      High: false,
      Low: false,
    });
  };

  const applyHandler = () => {
    const ranges = [];
    const categories = [];

    for (const key in selectedFilterInsightCategories) {
      if (selectedFilterInsightCategories[key]) {
        categories.push(key);
      }
    }

    for (const key in selectedRanges) {
      if (selectedRanges[key]) {
        ranges.push(key);
      }
    }
    if (ranges.length == 0 || categories.lenght == 0) {
      setOpenSnackbar(true);

      setSeverity("error");
      setAlertMessage("Please select atleast one option to apply filters");
    } else {
      call(`insights/`, "GET", {
        params: { range: JSON.stringify(ranges), category: JSON.stringify(categories) },
      })
        .then((res) => {
          if (res.status == 200) {
            setInsightsData(res.data.Items);
            setSelectedFilterInsightRanges(selectedRanges);
            setSeverity("success");
            setOpenSnackbar(true);
            setAlertMessage("Filters applies successfully");
            closeFilterByInsightRange();
          } else if (res.status == 440) {
            closeFilterByInsightRange();
            setSessionExpired(true);
          } else {
            setOpenSnackbar(true);
            setSeverity("error");
            setAlertMessage("Error while applying filter");
            closeFilterByInsightRange();
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
          setSeverity("error");
          setOpenSnackbar(true);
          setAlertMessage("Error while applying filter");
        });
    }
  };

  const onChangeHandler = (range) => {
    let currentValue = selectedRanges[range];
    setSelectedRanges((prevState) => ({
      ...prevState,
      [range]: !currentValue,
    }));
  };

  return (
    <Dialog open={openFilterByInsightRange} onClose={closeFilterByInsightRange}>
      <DialogTitle>Filter By Range</DialogTitle>
      <DialogContent>
        <MDBox display="flex" flexDirection={"row"} justifyContent="space-between">
          <MDBox mr={2}>
            <MDButton variant="contained" color="primary" onClick={selectAllHandler}>
              Select All
            </MDButton>
          </MDBox>
          <MDBox ml={2}>
            <MDButton variant="contained" color="secondary" onClick={deSelectAllHandler}>
              Deselect All
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection={"column"} mt={3} ml={3}>
          <FormGroup>
            {AllRanges.map((range, index) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRanges[range]}
                      onChange={() => onChangeHandler(range)}
                    />
                  }
                  label={range}
                />
              );
            })}
          </FormGroup>
        </MDBox>
        <MDBox display="flex" flexDirection={"row"} mt={2} justifyContent="space-between">
          <MDBox mr={2}>
            <MDButton variant="contained" color="primary" onClick={applyHandler}>
              Apply
            </MDButton>
          </MDBox>
          <MDBox ml={2}>
            <MDButton variant="contained" color="secondary" onClick={closeFilterByInsightRange}>
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      </DialogContent>
    </Dialog>
  );
};

const FilterByAnimalCategoryDialog = ({
  openFilterByAnimalCategory,
  closeFilterByAnimalCategory,
  selectedFilterAnimalCategories,
  setSelectedFilterAnimalCategories,
  setAnimalsData,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired,
}) => {
  const AllCategories = [
    "SR",
    "SC",
    "E",
    "R",
    "T+E+",
    "T-E+",
    "T+E-",
    "T-E-",
    "T+O+",
    "T-O+",
    "T+O-",
    "T-O-",
    "T+A+",
    "T-A+",
    "T+A-",
    "T-A-",
    "T+C+",
    "T-C+",
    "T+C-",
    "T-C-",
    "E+O+",
    "E-O+",
    "E+O-",
    "E-O-",
    "E+A+",
    "E-A+",
    "E+A-",
    "E-A-",
    "E+C+",
    "E-C+",
    "E+C-",
    "E-C-",
    "O+A+",
    "O-A+",
    "O+A-",
    "O-A-",
    "O+C+",
    "O-C+",
    "O+C-",
    "O-C-",
    "A+C+",
    "A-C+",
    "A+C-",
    "A-C-",
  ];
  const [selectedCategories, setSelectedCategories] = useState(selectedFilterAnimalCategories);

  const selectAllHandler = () => {
    setSelectedCategories({
      SR: true,
      SC: true,
      E: true,
      R: true,
      "T+E+": true,
      "T-E+": true,
      "T+E-": true,
      "T-E-": true,
      "T+O+": true,
      "T-O+": true,
      "T+O-": true,
      "T-O-": true,
      "T+A+": true,
      "T-A+": true,
      "T+A-": true,
      "T-A-": true,
      "T+C+": true,
      "T-C+": true,
      "T+C-": true,
      "T-C-": true,
      "E+O+": true,
      "E-O+": true,
      "E+O-": true,
      "E-O-": true,
      "E+A+": true,
      "E-A+": true,
      "E+A-": true,
      "E-A-": true,
      "E+C+": true,
      "E-C+": true,
      "E+C-": true,
      "E-C-": true,
      "O+A+": true,
      "O-A+": true,
      "O+A-": true,
      "O-A-": true,
      "O+C+": true,
      "O-C+": true,
      "O+C-": true,
      "O-C-": true,
      "A+C+": true,
      "A-C+": true,
      "A+C-": true,
      "A-C-": true,
    });
  };

  const deSelectAllHandler = () => {
    setSelectedCategories({
      SR: false,
      SC: false,
      E: false,
      R: false,
      "T+E+": false,
      "T-E+": false,
      "T+E-": false,
      "T-E-": false,
      "T+O+": false,
      "T-O+": false,
      "T+O-": false,
      "T-O-": false,
      "T+A+": false,
      "T-A+": false,
      "T+A-": false,
      "T-A-": false,
      "T+C+": false,
      "T-C+": false,
      "T+C-": false,
      "T-C-": false,
      "E+O+": false,
      "E-O+": false,
      "E+O-": false,
      "E-O-": false,
      "E+A+": false,
      "E-A+": false,
      "E+A-": false,
      "E-A-": false,
      "E+C+": false,
      "E-C+": false,
      "E+C-": false,
      "E-C-": false,
      "O+A+": false,
      "O-A+": false,
      "O+A-": false,
      "O-A-": false,
      "O+C+": false,
      "O-C+": false,
      "O+C-": false,
      "O-C-": false,
      "A+C+": false,
      "A-C+": false,
      "A+C-": false,
      "A-C-": false,
    });
  };

  const applyHandler = () => {
    const categories = [];

    for (const key in selectedCategories) {
      if (selectedCategories[key]) {
        categories.push(key);
      }
    }

    if (categories.length == 0) {
      setSeverity("error");
      setOpenSnackbar(true);
      setAlertMessage("Please select atleast one option to apply filter");
    } else {
      call(`animals/`, "GET", {
        params: { category: JSON.stringify(categories) },
      })
        .then((res) => {
          if (res.status == 200) {
            setAnimalsData(res.data.Items);
            setSelectedFilterAnimalCategories(selectedCategories);
            setOpenSnackbar(true);
            setAlertMessage("Filter applied successfully");
            closeFilterByAnimalCategory();
            setSeverity("success");
          } else if (res.status == 440) {
            closeFilterByAnimalCategory();
            setSessionExpired(true);
          } else {
            setOpenSnackbar(true);
            setAlertMessage("Error while applying filter");
            closeFilterByAnimalCategory();
            setSeverity("error");
          }
        })
        .catch((err) => {
          console.log(err);
          Sentry.captureException(err);
          setSeverity("error");
          setAlertMessage("Error while applying filter");
          closeFilterByAnimalCategory();
        });
    }
  };

  const onChangeHandler = (category) => {
    let currentValue = selectedCategories[category];
    setSelectedCategories((prevState) => ({
      ...prevState,
      [category]: !currentValue,
    }));
  };

  return (
    <Dialog open={openFilterByAnimalCategory} onClose={closeFilterByAnimalCategory}>
      <DialogTitle>Filter By Category</DialogTitle>
      <DialogContent>
        <MDBox display="flex" flexDirection={"row"} justifyContent="space-between">
          <MDBox mr={2}>
            <MDButton variant="contained" color="primary" onClick={selectAllHandler}>
              Select All
            </MDButton>
          </MDBox>
          <MDBox ml={2}>
            <MDButton variant="contained" color="secondary" onClick={deSelectAllHandler}>
              Deselect All
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection={"column"} mt={3} ml={3}>
          <FormGroup>
            {AllCategories.map((category, index) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedCategories[category]}
                      onChange={() => onChangeHandler(category)}
                    />
                  }
                  label={category}
                />
              );
            })}
          </FormGroup>
        </MDBox>
        <MDBox display="flex" flexDirection={"row"} mt={2} justifyContent="space-between">
          <MDBox mr={2}>
            <MDButton variant="contained" color="primary" onClick={applyHandler}>
              Apply
            </MDButton>
          </MDBox>
          <MDBox ml={2}>
            <MDButton variant="contained" color="secondary" onClick={closeFilterByAnimalCategory}>
              Close
            </MDButton>
          </MDBox>
        </MDBox>
      </DialogContent>
    </Dialog>
  );
};
