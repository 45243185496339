import { useState, useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAuthenticator } from "@aws-amplify/ui-react";

// Otis Admin PRO React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

import { useMaterialUIController } from "context";

import logo from "assets/images/nav-logo.png";
import { useTranslation } from "react-i18next";

function Sidenav({ color, brand, brandName, routes, setOpenSidenav, ...rest }) {
  const {t} = useTranslation();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { persona, beta } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const { signOut } = useAuthenticator((context) => [context.user]);

  let textColor = "dark";

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem color={color} name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = (r) =>
    r.map(
      ({ type, name, icon, title, collapse, noCollapse, key, href, route, beta_route = false }) => {
        let returnValue;

        if (type === "collapse") {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          ) : (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        } else if (type === "title") {
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } else if (type === "divider") {
          returnValue = <Divider key={key} light={false} />;
        } else if (type === "route") {
          if (!beta_route || beta) {
            returnValue = (
              <SidenavList key={key}>
                <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
                  <SidenavItem color={color} name={name} active={key === itemName} />
                </NavLink>
              </SidenavList>
            );
          }
        }

        return returnValue;
      }
    );

  const logOut = () => {
    localStorage.removeItem("session");
    // localStorage.removeItem("ActiveAssessment");
    localStorage.removeItem("referral");
    signOut();
  };

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{
        transparentSidenav: false,
        whiteSidenav: false,
        miniSidenav: false,
        darkMode: false,
      }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon
              onClick={() => {
                setOpenSidenav(false);
              }}
              sx={{ fontWeight: "bold" }}
            >
              close
            </Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          <MDBox width={"100%"} sx={(theme) => sidenavLogoLabel(theme, { miniSidenav: false })}>
            <MDBox component="img" src={logo} alt={t("imageAlts.maxuLogo")} width="100%" mr={0} />
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider light={false} />
      <List>
        {/* {persona === "athlete" ? renderRoutes(routes.routes) : renderRoutes(routes.athleteRoutes)} */}
        {(() => {
          switch (persona) {
            case "athlete":
              return renderRoutes(routes.athleteRoutes);
              break;
            case "guardian":
              return renderRoutes(routes.adultRoutes);
              break;
            case "adultAthlete":
              return renderRoutes(routes.adultAthleteRoutes);
              break;
            case "admin":
              return renderRoutes(routes.adminRoutes);
              break;
            case "contentManager":
              return renderRoutes(routes.contentManagerRoutes);
              break;
            default:
              return renderRoutes(routes.adultRoutes);
          }
        })()}
        {/* <SidenavList key="Content">
          <NavLink to="/" key="Content" sx={{ textDecoration: "none" }}>
            <SidenavItem color={color} name="Content" active={"content" === itemName} />
          </NavLink>
        </SidenavList> */}
        <Divider variant="inset" component="li" />
        <SidenavList key="Logout">
          <NavLink to="/" key="Logout" onClick={logOut} sx={{ textDecoration: "none" }}>
            <SidenavItem
              color={color}
              name={t('routes.logout')}
              active={"logout" === itemName}
              alignItems="flex-end"
            />
          </NavLink>
        </SidenavList>
      </List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.object.isRequired,
  setOpenSidenav: PropTypes.func,
};

export default Sidenav;
