import React from "react";
import PrivacyAndPolicyPDF from "../../assets/pdf/Privacy_Policy.pdf";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import '@react-pdf-viewer/core/lib/styles/index.css';

export function PrivacyPolicy() {
  return (
    <div style={{ width: "100%", height: "100vh", overflow: "auto" }}>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
      <Viewer fileUrl={PrivacyAndPolicyPDF} />
    </Worker>
  </div>
  );
}
