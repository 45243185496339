import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import MDInput from "components/MDInput";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { call } from "services/axiosService/core";
import Refresh from "pages/refresh/Refresh";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import MDButton from "components/MDButton";
import * as Sentry from "@sentry/browser";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const DeleteReferralButton = ({ id, DeleteReferralHandler }) => {
    return (
      <Tooltip fontSize="large" title={<h2 style={{}}>Delete Referral</h2>}>
        <MDTypography
          variant="body1"
          color="secondary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 1 }}
        >
          <Icon
            fontSize="medium"
            color="error"
            onClick={() => {
              DeleteReferralHandler(id);
            }}
          >
            delete
          </Icon>
        </MDTypography>
      </Tooltip>
    );
  };

export function ReferralsTable(props) {

  const [selectedId, setSelectedId] = useState("");
  const [selectedReferralDetails, setSelectedReferralDetails] = useState("");
  const [openViewDialog, setOpenViewDialog] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteReferralId, setDeleteReferralId] = useState("");
  const [pageNumber, setPageNumber] = useState(0)
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [sessionExpired, setSessionExpired] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };



  const CloseViewDialogHandler = () => {
    setOpenViewDialog(false);
    setSelectedId("");
  };

  const DeleteReferralHandler = (id) => {
    setDeleteReferralId(id);
    setOpenDeleteDialog(true);
  };

  const CloseDeleteDialogHandler = () => {
    setOpenDeleteDialog(false);
    setSelectedId("");
  };

  useEffect(() => {
    if (!openDeleteDialog) {
      if (selectedId) {
        const referral = props.referrals.filter((row) => {
          return row.id == selectedId;
        });
        setSelectedReferralDetails(referral[0]);
        setOpenViewDialog(true);
      }
    }
  }, [selectedId]);

  const tableData = {
    columns: [
      { Header: "#", accessor: "num", width: "5%" },
      { Header: "Name", accessor: "name", width: "20%" },
      { Header: "TargetPrice", accessor: "target_price", width: "10%" },
      { Header: "RemaingUsageCount", accessor: "remaining_usage_count", width: "10%" },
      { Header: "Actions", accessor: "actions", width: "20%" },
    ],

    rows: props.referrals,
  };

  tableData.rows = tableData.rows.map((row, index) => ({
    ...row,
    num: index + 1,
    actions: (
      <div style={{ display: "flex" }}>
        <DeleteReferralButton id={row.id} DeleteReferralHandler={DeleteReferralHandler} />
      </div>
    ),
  }));

    return(
        <>
      <DataTable table={tableData} setSelectedId={setSelectedId} pageNumber={pageNumber} setPageNumber={setPageNumber}/>
      {openViewDialog && (
        <ViewReferralDataDialog
          openViewDialog={openViewDialog}
          CloseViewDialogHandler={CloseViewDialogHandler}
          selectedReferralDetails={selectedReferralDetails}
          loadReferrals={props.loadReferrals}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSessionExpired={setSessionExpired}
        />
      )}
      {openDeleteDialog && (
        <DeleteReferralDialog
          openDeleteDialog={openDeleteDialog}
          CloseDeleteDialogHandler={CloseDeleteDialogHandler}
          deleteReferralId={deleteReferralId}
          loadReferrals={props.loadReferrals}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSessionExpired={setSessionExpired}
        />
      )}
       <Snackbar
        autoHideDuration={2000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {sessionExpired && (
        <Refresh/>
      )}
    </>

    )
}


const ViewReferralDataDialog = ({
    openViewDialog,
    CloseViewDialogHandler,
    selectedReferralDetails,
    loadReferrals,
    setOpenSnackbar,
    setAlertMessage,
    setSessionExpired
  }) => {
    const [details, setDetails] = useState(JSON.stringify(selectedReferralDetails, null, 3));
    const [validJson, setValidJson] = useState(true);
  
    const updateReferralHandler = () => {
      const data = JSON.parse(details);
      call(`referrals/${data.id}`, "PUT", { body: data })
        .then((res) => {
          if (res.status == 200) {
            CloseViewDialogHandler();
            loadReferrals();
            setOpenSnackbar(true)
            setAlertMessage("Updated successfully")
          }
          else if(res.status == 440){
            CloseViewDialogHandler();
            setSessionExpired(true)
          }
          else{
            setOpenSnackbar(true)
            setAlertMessage(res.data.message)
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          CloseViewDialogHandler();
        });
    };
  
    return (
      <Dialog open={openViewDialog} onClose={CloseViewDialogHandler} fullWidth={true} maxWidth={"md"}>
        <DialogTitle> Referral Details </DialogTitle>
        <DialogContent>
          <MDBox p={1} lineHeight={1}>
            <MDInput
              value={details}
              error={!validJson}
              multiline
              name="details"
              type="text"
              fullWidth
              required
              onChange={(e) => {
                setDetails(e.target.value);
                try {
                  JSON.parse(e.target.value);
                  setValidJson(true);
                } catch (error) {
                  setValidJson(false);
                }
              }}
            />
          </MDBox>
          {!validJson && (
            <MDTypography
              textAlign="center"
              variant="h6"
              color="error"
              lineHeight={1}
              sx={{ cursor: "pointer", mx: 1 }}
            >
              Data is not a valid Json
            </MDTypography>
          )}
        </DialogContent>
  
        <DialogActions>
          {/* <Button  style={{ color: "white", background: "#0AB2D6" }}>
            Delete
          </Button> */}
          <Button
            disabled={!validJson}
            onClick={updateReferralHandler}
            style={{ color: "white", background: "#0AB2D6" }}
          >
            Save
          </Button>
          <Button onClick={CloseViewDialogHandler} style={{ color: "white", background: "#ED5F72" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  const DeleteReferralDialog = ({
    openDeleteDialog,
    CloseDeleteDialogHandler,
    deleteReferralId,
    loadReferrals,
    setOpenSnackbar,
    setAlertMessage,
    setSessionExpired
  }) => {
    const deleteReferral = () => {
      call(`referrals/${deleteReferralId}`, "DELETE", {})
        .then((res) => {
          if (res.status == 200) {
            CloseDeleteDialogHandler();
            loadReferrals();
            setOpenSnackbar(true)
            setAlertMessage("Deleted successfully")
          }
          else if(res.status == 440){
            setSessionExpired(true)
            CloseDeleteDialogHandler();
          }
          else{
            setOpenSnackbar(true)
            setAlertMessage("Error while deleting the Referral")
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          CloseDeleteDialogHandler();
        });
    };
  
    return (
      <Dialog open={openDeleteDialog} onClose={CloseDeleteDialogHandler} maxWidth={"md"}>
        <DialogTitle> Delete Referral </DialogTitle>
        <DialogContent>Are you Sure you want to Delete this Referral ?</DialogContent>
  
        <DialogActions>
          <Button onClick={deleteReferral} style={{ color: "white", background: "#0AB2D6" }}>
            Delete
          </Button>
          <Button
            onClick={CloseDeleteDialogHandler}
            style={{ color: "white", background: "#ED5F72" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  