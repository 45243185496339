import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import MDInput from "components/MDInput";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { call } from "services/axiosService/core";
import { useMaterialUIController } from "context";
import Refresh from "pages/refresh/Refresh";
import * as Sentry from "@sentry/browser";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DeleteChatButton = ({ id, DeleteChatHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Delete Chat</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="error"
          onClick={() => {
            DeleteChatHandler(id);
          }}
        >
          delete
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

export function ChatsTable(props) {
  const [selectedId, setSelectedId] = useState("");
  const [selectedChatDetails, setSelectedChatDetails] = useState("");
  const [openViewDialog, setOpenViewDialog] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteChatId, setDeleteChatId] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [controller] = useMaterialUIController();
  const { profile } = controller;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };


  const CloseViewDialogHandler = () => {
    setOpenViewDialog(false);
    setSelectedId("");
  };

  const DeleteChatHandler = (id) => {
    setDeleteChatId(id);
    setOpenDeleteDialog(true);
  };

  const CloseDeleteDialogHandler = () => {
    setOpenDeleteDialog(false);
    setSelectedId("");
  };

  useEffect(() => {
    if (!openDeleteDialog) {
      if (selectedId) {
        const chat = props.chats.filter((row) => {
          return row.id == selectedId;
        });
        setSelectedChatDetails(chat[0]);
        setOpenViewDialog(true);
      }
    }
  }, [selectedId]);

  const tableData = {
    columns: [
      { Header: "#", accessor: "num", width: "10%" },
      { Header: "PrimaryKey", accessor: "pk", width: "20%" },
      { Header: "Question", accessor: "question", width: "50%" },
      { Header: "Actions", accessor: "actions", width: "20%" },
    ],

    rows: props.chats,
  };

  tableData.rows = tableData.rows.map((row, index) => ({
    ...row,
    num: index + 1,
    actions: (
      <div style={{ display: "flex" }}>
        <DeleteChatButton id={row.id} DeleteChatHandler={DeleteChatHandler} />
      </div>
    ),
  }));
  return (
    <>
      <DataTable
        table={tableData}
        setSelectedId={setSelectedId}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
      {openViewDialog && (
        <ViewChatDataDialog
          openViewDialog={openViewDialog}
          CloseViewDialogHandler={CloseViewDialogHandler}
          selectedChatDetails={selectedChatDetails}
          loadChats={props.loadChats}
          setSessionExpired={setSessionExpired}
          profile = {profile}
          setOpenSnackbar = {setOpenSnackbar}
          setAlertMessage = {setAlertMessage}
          setSeverity = {setSeverity}
        />
      )}
      {openDeleteDialog && (
        <DeleteChatDialog
          openDeleteDialog={openDeleteDialog}
          CloseDeleteDialogHandler={CloseDeleteDialogHandler}
          deleteChatId={deleteChatId}
          loadChats={props.loadChats}
          setSessionExpired={setSessionExpired}
          profile ={profile}
          setOpenSnackbar = {setOpenSnackbar}
          setAlertMessage = {setAlertMessage}
          setSeverity = {setSeverity}
        />
      )}
        <Snackbar
        autoHideDuration={4000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {sessionExpired && <Refresh />}
    </>
  );
}

const ViewChatDataDialog = ({
  openViewDialog,
  CloseViewDialogHandler,
  selectedChatDetails,
  loadChats,
  setSessionExpired,
  profile,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity
}) => {

  // const [details, setDetails] = useState(JSON.stringify(selectedChatDetails, null, 3));
  const [details, setDetails] = useState(JSON.stringify({question: selectedChatDetails.question, options:selectedChatDetails.options, other_responses:selectedChatDetails.other_responses}, null, 3));
  const [validJson, setValidJson] = useState(true);

  const updateChatHandler = () => {
    const data = JSON.parse(details);
    data.id = selectedChatDetails.id
    data.pk = selectedChatDetails.pk
    data.sk = selectedChatDetails.sk
    call(`chats/${data.id}`, "PUT", { body: data })
      .then((res) => {
        if (res.status == 200) {
          CloseViewDialogHandler();
          loadChats();
          setOpenSnackbar(true)
          setAlertMessage("Chat data upated succesfully")
          setSeverity("success")
        } else if (res.status == 440) {
          CloseViewDialogHandler();
          setSessionExpired(true);
        }
        else{
          setOpenSnackbar(true);
          setAlertMessage(res.data.message);
          setSeverity("error");
        }
      })
      .catch((err) => {
        CloseViewDialogHandler();
        Sentry.captureException(err);
      });
  };

  return (
    <Dialog open={openViewDialog} onClose={CloseViewDialogHandler} fullWidth={true} maxWidth={"md"}>
      <DialogTitle> Chat Details </DialogTitle>
      <DialogContent>
        <MDBox p={1} lineHeight={1}>
          <MDInput
            value={details}
            error={!validJson}
            multiline
            name="details"
            type="text"
            fullWidth
            required
            onChange={(e) => {
              setDetails(e.target.value);
              try {
                JSON.parse(e.target.value);
                setValidJson(true);
              } catch (error) {
                setValidJson(false);
              }
            }}
          />
        </MDBox>
        {!validJson && (
          <MDTypography
            textAlign="center"
            variant="h6"
            color="error"
            lineHeight={1}
            sx={{ cursor: "pointer", mx: 1 }}
          >
            Data is not a valid Json
          </MDTypography>
        )}
      </DialogContent>

      <DialogActions>
        {/* <Button  style={{ color: "white", background: "#0AB2D6" }}>
          Delete
        </Button> */}
        <Button
          disabled={!validJson}
          onClick={updateChatHandler}
          style={{ color: "white", background: "#0AB2D6" }}
        >
          Save
        </Button>
        <Button onClick={CloseViewDialogHandler} style={{ color: "white", background: "#ED5F72" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteChatDialog = ({
  openDeleteDialog,
  CloseDeleteDialogHandler,
  deleteChatId,
  loadChats,
  setSessionExpired,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity
}) => {
  const deleteChat = () => {
    call(`chats/${deleteChatId}`, "DELETE", {})
      .then((res) => {
        if (res.status == 200) {
          CloseDeleteDialogHandler();
          loadChats();
          setOpenSnackbar(true)
          setAlertMessage("Chat data deleted succesfully")
          setSeverity("success")
        } else if (res.status == 440) {
          CloseDeleteDialogHandler();
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        CloseDeleteDialogHandler();
        Sentry.captureException(err);
      });
  };

  return (
    <Dialog open={openDeleteDialog} onClose={CloseDeleteDialogHandler} maxWidth={"md"}>
      <DialogTitle> Delete Chat data </DialogTitle>
      <DialogContent>Are you Sure you want to Delete this Chatdata ?</DialogContent>

      <DialogActions>
        <Button onClick={deleteChat} style={{ color: "white", background: "#0AB2D6" }}>
          Delete
        </Button>
        <Button
          onClick={CloseDeleteDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
