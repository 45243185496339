import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Autocomplete from "@mui/material/Autocomplete";
import { call } from "services/axiosService/core";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import * as Sentry from "@sentry/browser";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Refresh from "pages/refresh/Refresh";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RequiredLabel = ({ children }) => (
  <FormLabel
    sx={{ display: "flex", alignItems: "center", margin: "5px auto 5px", fontSize: "20px" }}
  >
    <span style={{ color: "red", fontSize: "small", marginRight: "5px" }}>*</span>
    {children}
    <span style={{ color: "red", fontSize: "small", marginLeft: "10px" }}>Required</span>
  </FormLabel>
);

const AddInsightsSummary = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [insights, setInsights] = useState([""]);
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const defaultValues = {
    category: "Temperament",
    range: "High",
  };
  const { handleSubmit, reset, control, formState, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const { isValid, dirtyFields, errors } = formState;

  const changeInsightsHandler = (index, event) => {
    let data = insights;
    data[index] = event.target.value;
    setInsights([...data]);
  };

  const addInsightsHandler = () => {
    let data = [...insights, ""];
    setInsights(data);
  };

  const removeInsightsHandler = (index) => {
    let data = [...insights];
    data.splice(index, 1);
    setInsights(data);
  };

  const postInsightsHandler = (data) => {
    data["insights"] = [...insights];

    call(`insights/`, "POST", { body: data })
      .then((res) => {
        setShowCircularProgress(false);
        if (res.status == 200) {
          reset(defaultValues);
          setInsights([""]);
          setOpenSnackbar(true);
          setAlertMessage("Insights Data Added Sucessfully");
          setSeverity("success");
        } else if (res.status == 440) {
          setSessionExpired(true);
        } else {
          setOpenSnackbar(true);
          setAlertMessage(res.data.message);
          setSeverity("error");
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setShowCircularProgress(false);
        setOpenSnackbar(true);
        setAlertMessage("Error while adding the Insights");
        setSeverity("error");
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title={`Add Insights Data`} />
      <form onSubmit={handleSubmit((data) => postInsightsHandler(data))}>
        <Card style={{ height: "100%", width: "100%" }}>
          <Grid container spacing={3} p={3}>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <RequiredLabel>Select Category:</RequiredLabel>
              <Controller
                name="category"
                control={control}
                defaultValue={""}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="large"
                    disableClearable={true}
                    multiple={false}
                    options={[
                      "Temperament",
                      "Openness",
                      "Conscientiousness",
                      "Extraversion",
                      "Agreeableness",
                    ]}
                    value={value}
                    onChange={(event, newValue) => {
                      // typeChangeHandler(newValue);
                      onChange(newValue);
                    }}
                    limitTags={3}
                    renderInput={(params) => (
                      <TextField
                        sx={{ height: 50 }}
                        size="large"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-type",
                        }}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <RequiredLabel> Select Range:</RequiredLabel>
              <Controller
                name="range"
                control={control}
                defaultValue={""}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    size="large"
                    disableClearable={true}
                    multiple={false}
                    options={["High", "Low"]}
                    value={value}
                    onChange={(event, newValue) => {
                      // typeChangeHandler(newValue);
                      onChange(newValue);
                    }}
                    limitTags={3}
                    renderInput={(params) => (
                      <TextField
                        sx={{ height: 50 }}
                        size="large"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-type",
                        }}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} xl={12}>
              <Grid
                container
                spacing={1}
                border={1}
                boxShadow={"5"}
                mt={2}
                mb={2}
                borderColor={"#49a3f1"}
              >
                <Grid item xs={12} xl={12}>
                  <RequiredLabel>Insights:</RequiredLabel>
                </Grid>
                {insights.map((recom, index) => {
                  return (
                    <Grid
                      container
                      spacing={1}
                      border={1}
                      m={2}
                      pb={1}
                      boxShadow={"3"}
                      borderColor={"#0c85ed"}
                    >
                      <Grid item xs={12} sm={12} md={9} lg={10} xl={10} pr={1}>
                        <TextField
                          sx={{ height: 70 }}
                          required
                          name="test"
                          placeholder="Add Insights here"
                          multiline
                          rows={2}
                          value={recom}
                          error={!!errors.insight}
                          helperText={errors?.insight?.message}
                          size="large"
                          onChange={(event) => changeInsightsHandler(index, event)}
                          inputProps={{
                            autoComplete: "new-insights",
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                        {index != 0 && (
                          <MDBox
                            display="flex"
                            flex={1}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <MDButton
                              sx={{
                                marginTop: {
                                  xs: "0px",
                                  sm: "0px",
                                  md: "10px",
                                  lg: "10px",
                                  xl: "10px",
                                },
                              }}
                              style={{
                                color: "white",
                                background: "#f6821e",
                                justifyContent: "center",
                                width: "100%",
                                marginRight: "5px",
                              }}
                              onClick={() => {
                                removeInsightsHandler(index);
                              }}
                              type="button"
                            >
                              Remove
                            </MDButton>
                          </MDBox>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item xs={12} xl={12} mb={2}>
                  <MDBox display="flex" justifyContent="center">
                    <MDButton
                      onClick={addInsightsHandler}
                      style={{
                        color: "white",
                        background: "#0AB2D6",
                        marginTop: "5px",
                        display: "flex",
                        marginRight: "5px",
                        justifyContent: "center",
                      }}
                      type="button"
                    >
                      Add More Insights
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} xl={12}>
              {showCircularProgress ? (
                <MDBox display="flex" justifyContent="end">
                  <MDButton
                    style={{
                      color: "white",
                      background: "#0AB2D6",
                      marginTop: "15px",
                      display: "flex",
                      width: "100%",
                    }}
                    disabled
                    type="button"
                  >
                    <CircularProgress color="white" size={20} />
                  </MDButton>
                </MDBox>
              ) : (
                <MDBox display="flex" justifyContent="end">
                  <MDButton
                    style={{
                      color: "white",
                      background: "#0AB2D6",
                      marginTop: "15px",
                      display: "flex",
                      width: "100%",
                    }}
                    type="submit"
                  >
                    Add Insights Data
                  </MDButton>
                </MDBox>
              )}
            </Grid>
          </Grid>
        </Card>
      </form>
      <Snackbar
        autoHideDuration={5000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {sessionExpired && <Refresh />}
    </DashboardLayout>
  );
};

export default AddInsightsSummary;
