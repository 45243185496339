import DataTable from "examples/Tables/DataTable";
import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Button from "@mui/material/Button";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import {
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
  Radio,
  RadioGroup,
} from "@mui/material";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Autocomplete from "@mui/material/Autocomplete";

import { call } from "services/axiosService/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import * as Sentry from "@sentry/browser";
import CardMedia from "@mui/material/CardMedia";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RequiredLabel = ({ children }) => (
  <FormLabel
    sx={{ display: "flex", alignItems: "center", margin: "5px auto 5px", fontSize: "20px" }}
  >
    <span style={{ color: "red", fontSize: "small", marginRight: "5px" }}>*</span>
    {children}
    <span style={{ color: "red", fontSize: "small", marginLeft: "10px" }}>Required</span>
  </FormLabel>
);

const DeleteContentButton = ({ title, DeleteContentHandler }) => {
  return (
    <Tooltip fontSize="large" title={<h2 style={{}}>Delete Content</h2>}>
      <MDTypography
        variant="body1"
        color="secondary"
        lineHeight={1}
        sx={{ cursor: "pointer", mx: 1 }}
      >
        <Icon
          fontSize="medium"
          color="error"
          onClick={() => {
            DeleteContentHandler(title);
          }}
        >
          delete
        </Icon>
      </MDTypography>
    </Tooltip>
  );
};

export function ContentTable({ content, loadContent, setSessionExpired }) {
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedContentDetails, setSelectedContentDetails] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [severity, setSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const DeleteContentHandler = (title) => {
    setSelectedTitle(title);
    setOpenDeleteDialog(true);
  };

  const CloseDeleteContentDialogHandler = () => {
    setOpenDeleteDialog(false);
    setSelectedTitle("");
  };

  const CloseViewContentDialogHandler = () => {
    setOpenViewDialog(false);
    setSelectedId("");
  };

  const tableData = {
    columns: [
      { Header: "#", accessor: "num", width: "15%" },
      { Header: "Type", accessor: "content_type", width: "15%" },
      { Header: "Title", accessor: "title", width: "20%" },
      { Header: "Hidden", accessor: "hidden", width: "20%" },
      { Header: "ContentURL", accessor: "url", width: "30%" },
      { Header: "ThumbnailURL", accessor: "thumbnail_url", width: "20%" },
      { Header: "Actions", accessor: "actions", width: "25%" },
    ],
    rows: content,
  };

  tableData.rows = tableData.rows.map((row, index) => ({
    ...row,
    id: row.title,
    hidden: row.hidden ? "True" : "False",
    url: row.content_url ? row.content_url.slice(0, 100) : null,
    num: index + 1,
    actions: (
      <div style={{ display: "flex" }}>
        <DeleteContentButton title={row.title} DeleteContentHandler={DeleteContentHandler} />
      </div>
    ),
  }));

  useEffect(() => {
    if (!openDeleteDialog) {
      if (selectedId) {
        const contents = content.filter((row) => {
          return row.title == selectedId;
        });
        setSelectedContentDetails(contents[0]);

        setOpenViewDialog(true);
      }
    }
  }, [selectedId]);

  return (
    <>
      <DataTable
        table={tableData}
        setSelectedId={setSelectedId}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
      {openViewDialog && (
        <ViewContentDataDialog
          openViewDialog={openViewDialog}
          CloseViewContentDialogHandler={CloseViewContentDialogHandler}
          selectedContentDetails={selectedContentDetails}
          loadContent={loadContent}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}

      {openDeleteDialog && (
        <DeleteContentDialog
          openDeleteDialog={openDeleteDialog}
          CloseDeleteContentDialogHandler={CloseDeleteContentDialogHandler}
          selectedTitle={selectedTitle}
          loadContent={loadContent}
          setOpenSnackbar={setOpenSnackbar}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setSessionExpired={setSessionExpired}
        />
      )}
      <Snackbar
        autoHideDuration={5000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

const DeleteContentDialog = ({
  openDeleteDialog,
  CloseDeleteContentDialogHandler,
  selectedTitle,
  loadContent,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired,
}) => {
  const deleteContent = () => {
    call(`content/${selectedTitle}`, "DELETE", {})
      .then((res) => {
        if (res.status == 200) {
          setOpenSnackbar(true);
          setAlertMessage("Content Deleted Sucessfully");
          setSeverity("success");
          CloseDeleteContentDialogHandler();
          loadContent();
        } else if (res.status == 440) {
          CloseDeleteContentDialogHandler();
          setSessionExpired(true);
        } else {
          setOpenSnackbar(true);
          setAlertMessage("Error while Deleting Content");
          setSeverity("error");
        }
      })
      .catch((err) => {
        CloseDeleteContentDialogHandler();
        Sentry.captureException(err);
      });
  };

  return (
    <Dialog open={openDeleteDialog} onClose={CloseDeleteContentDialogHandler} maxWidth={"md"}>
      <DialogTitle> Delete Content </DialogTitle>
      <DialogContent>Are you sure you want to Delete this Content ?</DialogContent>

      <DialogActions>
        <Button onClick={deleteContent} style={{ color: "white", background: "#0AB2D6" }}>
          Delete
        </Button>
        <Button
          onClick={CloseDeleteContentDialogHandler}
          style={{ color: "white", background: "#ED5F72" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ViewContentDataDialog = ({
  openViewDialog,
  CloseViewContentDialogHandler,
  selectedContentDetails,
  loadContent,
  setOpenSnackbar,
  setAlertMessage,
  setSeverity,
  setSessionExpired,
}) => {
  const [details, setDetails] = useState(selectedContentDetails);
  const [upload, setUpload] = useState(false);
  const [showUploadContent, setShowUploadContent] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailURL, setThumbnailURL] = useState(details.thumbnail_url);
  const [newThumbnailURL, setnewThumbnailURL] = useState(null);
  const [contentURLList, setCotentURLList] = useState(["Upload", "ExternalLink"]);
  const [contentFile, setContentFile] = useState(null);
  const [contentFileType, setContentFileType] = useState("");
  const [preSignedURL, setPreSignedURL] = useState(null);
  const [item, setItem] = useState({});
  const [tagFields, setTagFields] = useState([{ tag_name: "category", tag_value: "" }]);
  const [showCircularProgress, setShowCircularProgress] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [disableuploadButton, setdisableUploadButton] = useState(true);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [step, setStep] = useState(1);
  const [thumbnailOption, setThumbnailOption] = useState("upload");
  const [contentDetails, setContentDetails] = useState({});
  const [showGenerateProgress, setShowGenerateProgress] = useState(false);
  const [thumbnailGalary, setThumbnailGalary] = useState(
    details.thumbnail_url ? [{ id: 1, url: details.thumbnail_url }] : []
  );
  const handleImageClick = (url) => {
    setnewThumbnailURL(url);
  };

  useEffect(() => {
    tagsHandler();
    if (details.content) {
      setContentFileType("InlineText");
    } else {
      setContentFileType("ExternalLink");
    }
    setValue("thumbnail_url", details.thumbnail_url);
  }, []);

  const tagsHandler = () => {
    const tags = [];
    for (const [key, value] of Object.entries(details.tags)) {
      if (key == "category") {
        tags.splice(0, 0, { tag_name: key, tag_value: value.join(",") });
      } else {
        tags.push({ tag_name: key, tag_value: value.join(",") });
      }

      // console.log(`${key}: ${value}`);
    }
    setTagFields(tags);
  };

  const contentTypeList = ["Video", "Worksheet", "Infographic", "Article"];

  const urlRegExp = "^(http|https)://";
  const schema = yup.object().shape({
    title: yup.string().trim().required("You must enter the title of content"),
    type: yup.string().trim().required("You must enter the type of content"),
    url_type: yup.string().trim().required("You must enter the URL type of content"),
    weight: yup.number().min(0).required("You must enter Weight of content"),
  });

  const defaultValues = {
    title: details.title,
    type: details.content_type,
    url_type: "ExternalLink",
    url: details.content_url,
    weight: details.weight,
    file: undefined,
    hidden: details.hidden ? "True" : "False",
  };

  const { handleSubmit, reset, control, formState, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const ResetContentHandler = () => {
    reset();
    setValue("thumbnail_url", details.thumbnail_url);
    tagsHandler();
    setThumbnailURL(details.thumbnail_url);
    let s = document.getElementById("getFile");
    s.value = null;
  };

  const contentURLChangeHandler = (val) => {
    setContentFileType(val);
  };

  const changeTagHandler = (index, event) => {
    let data = [...tagFields];
    data[index][event.target.name] = event.target.value;
    setTagFields(data);
  };

  const addTagHandler = () => {
    let data = [...tagFields, { tag_name: "", tag_value: "" }];
    setTagFields(data);
  };

  const removeTagHandler = (index) => {
    let data = [...tagFields];
    data.splice(index, 1);
    setTagFields(data);
  };

  const uploadThumbnailHandler = async (e) => {
    setThumbnailURL(null);
    if (e.target.files[0]) {
      // let img = new Image();
      // img.src = URL.createObjectURL(e.target.files[0]);
      // await img.decode();
      // let width = img.width;
      // let height = img.height;
      // if (width != 1706 || height != 1229) {
      //   setOpenSnackbar(true);
      //   setAlertMessage("Please select the image with dimentions 1706 x 1229");
      //   setSeverity("error");
      //   let s = document.getElementById("getFile");
      //   s.value = null;
      // }
      if (e.target.files[0].size > 1e7) {
        setOpenSnackbar(true);
        setAlertMessage("Please upload a file smaller than 10 MB");
        setSeverity("error");
        let s = document.getElementById("getFile");
        s.value = null;
      } else {
        // setImageSrc(URL.createObjectURL(e.target.files[0]))
        // const reader = new FileReader();
        // reader.onloadend = () => {
        //   // Set the image source once the file is read
        //   setImageSrc(reader.result);
        // };
        // reader.readAsDataURL(e.target.files[0]);
        setThumbnail(e.target.files[0]);
      }
    }
  };

  const contentDataHandler = (data) => {
    const contentdata = {};
    contentdata["title"] = data["title"];
    contentdata["hidden"] = data["hidden"] == "True" ? true : false;
    contentdata["weight"] = data["weight"].toString();
    contentdata["thumbnail_url"] = data["thumbnail_url"] ? data["thumbnail_url"] : null;
    contentdata["content_type"] = data["type"];
    if (data["url_type"] == "Upload") {
      contentdata["upload_content"] = true;
      contentdata["content_url"] = null;
    } else {
      contentdata["upload_content"] = false;
      contentdata["content_url"] = data["url"];
    }
    const content_tags = {};
    tagFields.map((tag) => {
      content_tags[tag["tag_name"]] = tag["tag_value"].split(",");
    });
    contentdata["tags"] = content_tags;
    setContentDetails(contentdata);
    setStep(2);
  };

  const updateContentHandler = () => {
    setShowCircularProgress(true);
    const formdata = new FormData();
    const contentdata = {};
    contentdata["thumbnailOption"] = thumbnailOption;
    contentdata["thumbnail_url"] = null;
    if (newThumbnailURL) {
      contentdata["thumbnail_url"] = newThumbnailURL;
    } else {
      contentdata["thumbnail_url"] = thumbnailURL;
    }
    contentdata["title"] = contentDetails.title;
    contentdata["weight"] = contentDetails.weight;
    contentdata["content_type"] = contentDetails.content_type;
    contentdata["upload_content"] = contentDetails.upload_content;
    contentdata["content_url"] = contentDetails.content_url;
    contentdata["tags"] = contentDetails.tags;
    formdata.append("file", thumbnail);
    formdata.append("data", JSON.stringify(contentdata));
    const headers = {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": "multipart/form-data",
    };

    call(`content/${details.title}/`, "PUT", { headers: headers, body: formdata })
      .then((res) => {
        setShowCircularProgress(false);
        if (res.status == 200) {
          // console.log("res", res);
          loadContent();
          setOpenSnackbar(true);
          setAlertMessage("Content Updated Sucessfully");
          setSeverity("success");
          CloseViewContentDialogHandler();
        } else if (res.status == 201) {
          // console.log("res", res);
          setTagFields([{ tag_name: "category", tag_value: "" }]);
          let s = document.getElementById("getFile");
          if (s) {
            s.value = null;
          }
          reset(defaultValues);
          setPreSignedURL(res.data.presigned_url);
          setItem(res.data.item);
          setThumbnailOption("upload");
          setThumbnailURL(null);
          setStep(3);
        } else if (res.status == 440) {
          CloseViewContentDialogHandler();
          setSessionExpired(true);
        } else {
          setOpenSnackbar(true);
          setAlertMessage(res.data.message);
          setSeverity("error");
          CloseViewContentDialogHandler();
        }
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        setShowCircularProgress(false);
        setOpenSnackbar(true);
        setAlertMessage("Error while updating the content");
        setSeverity("error");
        CloseViewContentDialogHandler();
      });
  };

  const uploadContentHandler = (e) => {
    if (e.target.files[0]) {
      setdisableUploadButton(false);
    } else {
      setdisableUploadButton(true);
    }
    setContentFile(e.target.files[0]);
  };

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    setProgressValue(percent);
  };

  const pushcontentHandler = () => {
    setdisableUploadButton(true);
    const formdata = new FormData();
    setShowProgress(true);
    formdata.append("file", contentFile);
    axios
      .put(preSignedURL, contentFile, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": contentFile.type,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "PUT,GET,POST,DELETE,OPTIONS",
        },

        onUploadProgress,
      })
      .then(function (response) {
        const headers = {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": "multipart/form-data",
        };
        const formdata = new FormData();
        const contentdata = item;

        let url = preSignedURL.split("?")[0];
        contentdata["content_url"] = url;
        contentdata["push_content"] = true;
        formdata.append("data", JSON.stringify(contentdata));
        call(`content/${item.title}/`, "PUT", { headers: headers, body: formdata })
          .then((res) => {
            if (res.status == 200) {
              setUploadCompleted(true);
              setOpenSnackbar(true);
              setAlertMessage("Content updated Sucessfully");
              setSeverity("success");
              loadContent();
              // console.log("res", res);
            } else if (res.status == 440) {
              setOpenSnackbar(true);
              setSessionExpired(true);
            }
          })
          .catch((err) => {
            setUploadCompleted(true);
            Sentry.captureException(err);
            console.log("Error while updating contenr");
            setOpenSnackbar(true);
            setAlertMessage("Error while uploading the content");
            setSeverity("error");
          });

        // console.log(response);
      })
      .catch(function (err) {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  const backtocontentHandler = () => {
    setUploadCompleted(false);
    setShowProgress(false);
    setProgressValue(false);
    setPreSignedURL(null);
    CloseViewContentDialogHandler();
  };

  const handleThumbnailOptionChange = (event) => {
    setThumbnailOption(event.target.value);
    if (event.target.value == "generate") {
      setThumbnail(null);
      if (thumbnailGalary.length) {
        setnewThumbnailURL(thumbnailGalary[0].url);
      }
    } else {
      setnewThumbnailURL(null);
    }
  };

  const getAIGeneratedImageHandler = (jobId) => {
    const GetAIGeneratedThumbanil = () => {
      call(`content/thumbnail/${jobId}`, "GET", {})
        .then((res) => {
          if (res.status == 200) {
            setShowGenerateProgress(false);
            clearTimeout(myTimeout);
            clearInterval(myInterval);
            let options = res.data.thumbnailUrl;
            const images = options.map((url, index) => ({
              id: index + 1,
              url: url,
            }));
            setThumbnailGalary(images);
            setThumbnailURL(null);
            setnewThumbnailURL(options[0]);
          } else if (res.status == 440) {
            setSessionExpired(true);
          }
        })
        .catch((err) => {
          setShowGenerateProgress(false);
          setOpenSnackbar(true);
          setAlertMessage("Error while creating thumbnail");
          setSeverity("error");
        });
    };

    let myInterval = setInterval(GetAIGeneratedThumbanil, 20000);
    let myTimeout = setTimeout(() => {
      setShowGenerateProgress(false);
      clearInterval(myInterval);
    }, 150000);
  };

  const handleGenerateThumbnail = () => {
    call(`content/thumbnail`, "POST", { body: { title: contentDetails.title } })
      .then((res) => {
        console.log("res is", res);
        if (res.status == 200) {
          setShowGenerateProgress(true);
          getAIGeneratedImageHandler(res.data.job_id);
          setOpenSnackbar(true);
          setAlertMessage("thumbnail Generation is started, it will take under a minute");
          setSeverity("info");
        } else if (res.status == 440) {
          setSessionExpired(true);
        }
      })
      .catch((err) => {
        setOpenSnackbar(true);
        setAlertMessage("Error while creating thumbnail");
        setSeverity("error");
      });
  };

  return (
    <Dialog
      open={openViewDialog}
      onClose={CloseViewContentDialogHandler}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle> View / Edit Content Details </DialogTitle>
      {step == 1 && (
        <>
          <DialogContent>
            <form onSubmit={handleSubmit((data) => contentDataHandler(data))}>
              <Grid container spacing={1} p={3}>
                <Grid item xs={12} xl={12}>
                  <RequiredLabel>Title:</RequiredLabel>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        sx={{ height: 50 }}
                        size="large"
                        disabled
                        error={!!errors.title}
                        helperText={errors?.title?.message}
                        {...field}
                        inputProps={{
                          autoComplete: "new-title",
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    name="title"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6}>
                  <RequiredLabel> Content Type:</RequiredLabel>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue={""}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        size="large"
                        multiple={false}
                        options={contentTypeList}
                        value={value}
                        onChange={(event, newValue) => {
                          // typeChangeHandler(newValue);
                          onChange(newValue);
                        }}
                        limitTags={3}
                        renderInput={(params) => (
                          <TextField
                            sx={{ height: 50 }}
                            size="large"
                            error={!!errors.type}
                            helperText={errors?.type?.message}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-type",
                            }}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6}>
                  <RequiredLabel>Content URL:</RequiredLabel>
                  <Controller
                    name="url_type"
                    control={control}
                    defaultValue={""}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        size="large"
                        multiple={false}
                        options={contentURLList}
                        value={value}
                        onChange={(event, newValue) => {
                          onChange(newValue);
                          contentURLChangeHandler(newValue);
                        }}
                        limitTags={3}
                        renderInput={(params) => (
                          <TextField
                            sx={{ height: 50 }}
                            size="large"
                            error={!!errors.url_type}
                            helperText={errors?.url_type?.message}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-url-type",
                            }}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                {contentFileType == "ExternalLink" && (
                  <MDBox pt={3} width="100%" pl={1}>
                    <Grid item xs={12} xl={12}>
                      <RequiredLabel>External URL:</RequiredLabel>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            sx={{ height: 50 }}
                            placeholder="Add ExternalURL Here"
                            size="large"
                            required
                            {...field}
                            inputProps={{
                              autoComplete: "new-url",
                            }}
                            required
                            error={
                              getValues("url") &&
                              !getValues("url").match(urlRegExp) &&
                              getValues("url").length > 0
                            }
                            helperText={
                              getValues("url") &&
                              !getValues("url").match(urlRegExp) &&
                              getValues("url").length > 0
                                ? "Not a Valid URL"
                                : ""
                            }
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        name="url"
                        control={control}
                      />
                    </Grid>
                  </MDBox>
                )}
                {/* {contentFileType == "InlineText" && (
                  <MDBox pt={3} width="100%" pl={1}>
                    <Grid item xs={12} xl={12}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            sx={{ height: 100 }}
                            placeholder="Add Content Text Here"
                            multiline
                            rows={2}
                            size="large"
                            required
                            {...field}
                            inputProps={{
                              autoComplete: "new-content-text",
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        name="content_text"
                        control={control}
                      />
                    </Grid>
                  </MDBox>
                )} */}
                <Grid item xs={12} xl={12}>
                  <Grid
                    container
                    spacing={1}
                    border={1}
                    boxShadow={"5"}
                    mt={2}
                    mb={2}
                    borderColor={"#49a3f1"}
                  >
                    <Grid item xs={12} xl={12}>
                      <RequiredLabel>Add Tags:</RequiredLabel>
                    </Grid>
                    {tagFields.map((tag, index) => {
                      return (
                        <Grid
                          container
                          spacing={1}
                          border={1}
                          m={2}
                          pb={1}
                          boxShadow={"3"}
                          borderColor={"#0c85ed"}
                        >
                          <Grid item xs={12} sm={12} md={4} lg={5} xl={5} pr={1}>
                            <TextField
                              sx={{ height: 50 }}
                              required
                              name="tag_name"
                              placeholder="Tag Name"
                              value={tag.tag_name}
                              size="large"
                              onChange={(event) => changeTagHandler(index, event)}
                              inputProps={{
                                autoComplete: "new-tage-name",
                              }}
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={5} xl={5} pr={1}>
                            <TextField
                              sx={{ height: 50 }}
                              required
                              placeholder="Tag Value"
                              name="tag_value"
                              value={tag.tag_value}
                              size="large"
                              onChange={(event) => changeTagHandler(index, event)}
                              inputProps={{
                                autoComplete: "new-tage-value",
                              }}
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                            {index != 0 && (
                              <MDBox display="flex" flex={1} justifyContent="center">
                                <MDButton
                                  sx={{
                                    marginTop: {
                                      xs: "0px",
                                      sm: "0px",
                                      md: "10px",
                                      lg: "10px",
                                      xl: "10px",
                                    },
                                  }}
                                  style={{
                                    color: "white",
                                    background: "#f6821e",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    marginRight: "5px",
                                  }}
                                  onClick={() => {
                                    removeTagHandler(index);
                                  }}
                                  type="button"
                                >
                                  Remove
                                </MDButton>
                              </MDBox>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid item xs={12} xl={12} mb={2}>
                      <MDBox display="flex" justifyContent="center">
                        <MDButton
                          onClick={addTagHandler}
                          style={{
                            color: "white",
                            background: "#0AB2D6",
                            marginTop: "5px",
                            display: "flex",
                            marginRight: "5px",
                            justifyContent: "center",
                          }}
                          type="button"
                        >
                          Add More Tags
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
                {thumbnailURL && (
                  <Grid item xs={12} md={12} xl={12}>
                    <RequiredLabel>Thumbnail URL:</RequiredLabel>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          sx={{ height: 50 }}
                          type={"text"}
                          disabled
                          defaultValue={thumbnailURL}
                          size="large"
                          {...field}
                          inputProps={{
                            autoComplete: "new-url",
                            step: "0.1",
                          }}
                          error={
                            getValues("thumbnail_url") &&
                            !getValues("thumbnail_url").match(urlRegExp) &&
                            getValues("thumbnail_url").length > 0
                          }
                          helperText={
                            getValues("thumbnail_url") &&
                            !getValues("thumbnail_url").match(urlRegExp) &&
                            getValues("thumbnail_url").length > 0
                              ? "Not a Valid URL"
                              : ""
                          }
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      name="thumbnail_url"
                      control={control}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={6} xl={6}>
                  <RequiredLabel>Weight:</RequiredLabel>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        sx={{ height: 50 }}
                        type="number"
                        size="large"
                        error={!!errors.weight}
                        helperText={errors?.weight?.message}
                        {...field}
                        inputProps={{
                          autoComplete: "new-weight",
                          step: "0.1",
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    name="weight"
                    control={control}
                  />
                </Grid>
                {/* <Grid item xs={12} md={6} xl={6}>
                  <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
                    Thumbnail Image(1706x1229):
                  </FormLabel>
                  <MDBox display="flex" alignItems="flex-start" justifyContent="center">
                    <Controller
                      render={({ field }) => (
                        <TextField
                          type="file"
                          id="getFile"
                          size="large"
                          style={{
                            display: "block",
                            borderRadius: "5px",
                            width: "100%",
                            height: "100%",
                            marginTop: "3px",
                            color: "white",
                            background: "#0AB2D6",
                          }}
                          {...field}
                          onChange={uploadThumbnailHandler}
                          inputProps={{
                            accept: ".png",
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      name="file"
                      control={control}
                    />
                  </MDBox>
                </Grid> */}
                <Grid item xs={12} md={6} xl={6}>
                  <RequiredLabel>Hidden Content:</RequiredLabel>
                  <Controller
                    name="hidden"
                    control={control}
                    defaultValue={""}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        size="large"
                        disableClearable
                        multiple={false}
                        options={["True", "False"]}
                        value={value}
                        onChange={(event, newValue) => {
                          // typeChangeHandler(newValue);
                          onChange(newValue);
                        }}
                        limitTags={3}
                        renderInput={(params) => (
                          <TextField
                            sx={{ height: 50 }}
                            size="large"
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-type",
                            }}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} xl={12}>
                  <MDBox display="flex" justifyContent="end">
                    <MDButton
                      style={{
                        color: "white",
                        background: "#0AB2D6",
                        marginTop: "15px",
                        display: "flex",
                        width: "100%",
                      }}
                      type="submit"
                    >
                      Next Step
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={ResetContentHandler} style={{ color: "white", background: "#0AB2D6" }}>
              Reset
            </Button>
            <Button
              onClick={CloseViewContentDialogHandler}
              style={{ color: "white", background: "#ED5F72" }}
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}

      {step == 2 && (
        <>
          <DialogContent>
            <Grid container spacing={1} p={3}>
              <Grid item xs={12} xl={12}>
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    style={{ marginBottom: "20px", fontSize: "25px" }}
                  >
                    Add New Thumbnail for "{details.title}"
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={thumbnailOption}
                    onChange={handleThumbnailOptionChange}
                  >
                    <FormControlLabel
                      value="upload"
                      control={<Radio />}
                      style={{ marginRight: "40px" }}
                      label={<MDTypography variant="body1">Upload</MDTypography>}
                    />
                    <FormControlLabel
                      value="generate"
                      control={<Radio />}
                      label={<MDTypography variant="body1">Generate</MDTypography>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} xl={12}>
                {thumbnailOption === "upload" && (
                  <>
                    {/* {newThumbnailURL && (
                      <MDBox display="flex" alignItems="flex-start" justifyContent="center">
                        <img
                          src={newThumbnailURL}
                          style={{
                            marginTop: "25px",
                          }}
                          width="250"
                          height="250"
                          alt="Generated Thumbnail"
                        />
                      </MDBox>
                    )} */}

                    {thumbnailURL && (
                      <Grid container spacing={6}>
                        <Grid item xs={12} md={12}>
                          <MDBox
                            position="relative"
                            flex={1}
                            width="100.25%"
                            shadow="xl"
                            borderRadius="xl"
                          >
                            <CardMedia
                              src={thumbnailURL}
                              component="img"
                              height="100%"
                              sx={{
                                maxWidth: "100%",
                                margin: 0,
                                boxShadow: ({ boxShadows: { md } }) => md,
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                            />
                          </MDBox>
                        </Grid>
                      </Grid>
                    )}
                    <MDBox display="flex" alignItems="flex-start" justifyContent="start">
                      <Controller
                        render={({ field }) => (
                          <TextField
                            type="file"
                            id="getFile"
                            size="large"
                            required
                            style={{
                              display: "block",
                              borderRadius: "5px",
                              height: "100%",
                              marginTop: "3px",
                              color: "white",
                              background: "#0AB2D6",
                            }}
                            {...field}
                            onChange={uploadThumbnailHandler}
                            inputProps={{
                              accept: ".png",
                            }}
                            variant="outlined"
                          />
                        )}
                        name="file"
                        control={control}
                      />
                    </MDBox>
                    {showCircularProgress ? (
                      <MDBox display="flex" justifyContent="end">
                        <MDButton
                          style={{
                            color: "white",
                            background: "#0AB2D6",
                            marginTop: "15px",
                            display: "flex",
                            width: "100%",
                          }}
                          disabled
                          type="button"
                        >
                          <CircularProgress color="white" size={20} />
                        </MDButton>
                      </MDBox>
                    ) : (
                      <MDBox display="flex" justifyContent="end">
                        <MDButton
                          type="button"
                          style={{
                            color: "white",
                            background: "#0AB2D6",
                            marginTop: "25px",
                            display: "flex",
                            width: "100%",
                          }}
                          onClick={updateContentHandler}
                        >
                          {contentFileType == "Upload" ? "Next Step" : "Update Content"}
                        </MDButton>
                      </MDBox>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={12} xl={12}>
                {thumbnailOption === "generate" && (
                  <div>
                    {!thumbnailURL && !newThumbnailURL && (
                      <div>
                        {showGenerateProgress ? (
                          <MDButton
                            style={{
                              color: "white",
                              background: "#0AB2D6",
                              marginTop: "5px",
                              display: "flex",
                              width: "100%",
                            }}
                            disabled
                            type="button"
                          >
                            <CircularProgress color="white" size={20} />
                          </MDButton>
                        ) : (
                          <MDButton
                            type="button"
                            style={{
                              color: "white",
                              background: "#0AB2D6",
                              marginTop: "5px",
                              display: "flex",
                              width: "100%",
                            }}
                            onClick={handleGenerateThumbnail}
                          >
                            Generate Thumbnail
                          </MDButton>
                        )}
                      </div>
                    )}

                    {(thumbnailURL || newThumbnailURL) && (
                      <div>
                        <Grid container spacing={6}>
                          {thumbnailGalary.map((image) => (
                            <Grid item xs={12} md={thumbnailGalary.length == 1 ? 12 : 3}>
                              <div
                                key={image.id}
                                style={{ margin: "5px", cursor: "pointer" }}
                                onClick={() => handleImageClick(image.url)}
                              >
                                <MDBox
                                  position="relative"
                                  flex={1}
                                  width="100.25%"
                                  shadow="xl"
                                  borderRadius="xl"
                                >
                                  <CardMedia
                                    src={image.url}
                                    component="img"
                                    height="100%"
                                    sx={{
                                      border:
                                        newThumbnailURL && newThumbnailURL === image.url
                                          ? "8px solid #0AB2D6"
                                          : "none",
                                      maxWidth: "100%",
                                      margin: 0,
                                      boxShadow: ({ boxShadows: { md } }) => md,
                                      objectFit: "cover",
                                      objectPosition: "center",
                                    }}
                                  />
                                </MDBox>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                        {showGenerateProgress ? (
                          <MDButton
                            style={{
                              color: "white",
                              background: "#0AB2D6",
                              marginTop: "5px",
                              display: "flex",
                              width: "100%",
                            }}
                            disabled
                            type="button"
                          >
                            <CircularProgress color="white" size={20} />
                          </MDButton>
                        ) : (
                          <MDButton
                            type="button"
                            style={{
                              color: "white",
                              background: "#0AB2D6",
                              marginTop: "5px",
                              display: "flex",
                              width: "100%",
                            }}
                            disabled={showCircularProgress}
                            onClick={handleGenerateThumbnail}
                          >
                            ReGenerate Thumbnail
                          </MDButton>
                        )}

                        {showCircularProgress ? (
                          <MDBox display="flex" justifyContent="end">
                            <MDButton
                              style={{
                                color: "white",
                                background: "#0AB2D6",
                                marginTop: "15px",
                                display: "flex",
                                width: "100%",
                              }}
                              disabled
                              type="button"
                            >
                              <CircularProgress color="white" size={20} />
                            </MDButton>
                          </MDBox>
                        ) : (
                          <MDButton
                            style={{
                              color: "white",
                              background: "#0AB2D6",
                              marginTop: "15px",
                              display: "flex",
                              width: "100%",
                            }}
                            disabled={showGenerateProgress}
                            onClick={updateContentHandler}
                          >
                            {contentFileType == "Upload" ? "Next Step" : "Update Content"}
                          </MDButton>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={CloseViewContentDialogHandler}
              style={{ color: "white", background: "#ED5F72" }}
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}

      {step == 3 && (
        <>
          <DialogContent>
            <MDBox mt={3} display="flex" flex={1} justifyContent="center">
              <Grid container spacing={1} p={3}>
                {showProgress && (
                  <Grid item xs={12} xl={12}>
                    <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <MDBox
                        sx={{
                          width: { xs: "80%", sm: "80%", md: "90%", lg: "95%", xl: "95%" },
                          mr: 1,
                        }}
                      >
                        <ProgressBar bgcolor={"#f6831e"} completed={progressValue} />
                      </MDBox>
                      <MDBox sx={{ minWidth: 35 }}>
                        <MDTypography variant="body2" color="text.secondary">{`${Math.round(
                          progressValue
                        )}%`}</MDTypography>
                      </MDBox>
                    </MDBox>
                  </Grid>
                )}
                <Grid item xs={12} xl={12}>
                  <FormLabel sx={{ display: "block", marginBottom: "10px", fontSize: "20px" }}>
                    Upload Content Here:
                  </FormLabel>
                </Grid>
                <Grid item xs={12} xl={12}>
                  <TextField
                    type="file"
                    disabled={showProgress}
                    id="contentFile"
                    size="large"
                    style={{
                      display: "block",
                      borderRadius: "5px",
                      marginTop: "3px",
                      color: "white",
                      background: "#0AB2D6",
                    }}
                    onChange={uploadContentHandler}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} xl={12}>
                  <MDBox display="flex" justifyContent="end">
                    <MDButton
                      disabled={disableuploadButton}
                      style={{
                        color: "white",
                        background: "#0AB2D6",
                        marginTop: "15px",
                        display: "flex",
                        width: "100%",
                      }}
                      type="button"
                      onClick={pushcontentHandler}
                    >
                      Upload
                    </MDButton>
                  </MDBox>
                </Grid>
                {uploadCompleted && (
                  <Grid item xs={12} xl={12}>
                    <MDBox display="flex" justifyContent="end">
                      <MDButton
                        style={{
                          color: "white",
                          background: "#0AB2D6",
                          marginTop: "15px",
                          display: "flex",
                          width: "100%",
                        }}
                        type="button"
                        onClick={backtocontentHandler}
                      >
                        Close
                      </MDButton>
                    </MDBox>
                  </Grid>
                )}
              </Grid>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={CloseViewContentDialogHandler}
              style={{ color: "white", background: "#ED5F72" }}
            >
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const ProgressBar = (props) => {
  const { bgcolor, completed } = props;
  const containerStyles = {
    height: 10,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}></span>
      </div>
    </div>
  );
};
