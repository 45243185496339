import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import RequireAuth from "pages/authentication/RequiredAuth";
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import {
  getAllRoutes,
  getAdultRoutes,
  getAthleteRoutes,
  getAdultAthleteRoutes,
  getAdminRoutes,
  getContentManagerRoutes,
} from "routes";
import { Assessment } from "pages/assessments";
import { Insight } from "pages/insights";
import { Auth } from "pages/authentication";
import { PrivacyPolicy } from "pages/conditions/PrivacyPolicy";
import { TermsAndConditions } from "pages/conditions/TermsANDConditions";
import { PaymentTerms } from "pages/conditions/PaymentTerms";
import PlayContent from "pages/playList/PlayContent";
import { useMaterialUIController } from "context";
import { useTranslation } from "react-i18next";

export default function App() {
  const [controller] = useMaterialUIController();
  const { persona, beta, profile } = controller;
  const { pathname } = useLocation();
  const {t} = useTranslation()
  const allRoutes = getAllRoutes(t);
  const adultRoutes = getAdultRoutes(t);
  const athleteRoutes = getAthleteRoutes(t);
  const adultAthleteRoutes = getAdultAthleteRoutes(t);
  const adminRoutes = getAdminRoutes(t);
  const contentManagerRoutes = getContentManagerRoutes(t);


  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Initialize Pendo
  useEffect(() => {
    if (window.pendo && profile?.id) {
      window.pendo.initialize({
        apiKey: process.env.REACT_APP_PENDO_API_KEY,
      });
      window.pendo.identify({
          visitor: {
            id: profile.id,
            email: profile.email,
            name: profile.details.name,
            is_active: profile.status == "active",
            is_not_adult_learner: !profile.type.includes("Learner") && profile.type.includes("Adult") ,
            has_children:  (profile.relationships?.children?.length || 0) + (profile.relationships?.athletes?.length || 0) > 0
          },
      });
    }
  }, [profile]);

  const getRoutes = (routesList) => {
    // console.log('routesList:', routesList);  // Check the structure of routesList
    if (Array.isArray(routesList)) {
      return routesList.map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }
  
        if (route.route) {
          const isBetaRoute = route.beta_route || false;
          if (!isBetaRoute || beta) {
            return (
              <Route
                path={route.route}
                element={<RequireAuth>{route.component}</RequireAuth>}
                key={route.key}
              />
            );
          }
        }
  
        return null;
      });
    } else {
      console.error("Expected an array, but got:", typeof routesList);  // Log error if routesList is not an array
    }
  };
  

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {(() => {
          switch (persona) {
            case "defaultPersona":
              return getRoutes(allRoutes);
            case "athlete":
              return getRoutes(athleteRoutes);
            case "guardian":
              return getRoutes(adultRoutes);
            case "adultAthlete":
              return getRoutes(adultAthleteRoutes);
            case "admin":
              return getRoutes(adminRoutes);
            case "contentManager":
              return getRoutes(contentManagerRoutes);
            default:
              return getRoutes(adultRoutes);
          }
        })()}
        <Route path="assessment">
          <Route
            path=":id"
            element={
              <RequireAuth>
                <Assessment />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="insight">
          <Route
            path=":id/:user_id"
            element={
              <RequireAuth>
                <Insight />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/watch/:contentTitle" element={<RequireAuth><PlayContent /></RequireAuth>} />
        <Route path="/terms_conditions" element={<TermsAndConditions />} />
        <Route path="/payment_terms" element={<PaymentTerms />} />
        <Route path="auth/:initialState" element={<Auth />} />
        <Route path="*" element={<Auth />} />
      </Routes>
    </ThemeProvider>
  );
}
